.modal-v2-container {
  display: flex;
  position: fixed;
  top: 60px; /*nav height*/
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  z-index: 999;
  padding: 15px;
}

.modal-v2-container.cover-nav {
  z-index: 1001;
  top: 0;
}

.modal-v2-container.hidden {
  display: none;
}

.modal-v2-container .modal-content {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.modal-content > .remove-icon {
  float: right;
  margin-top: 15px;
  margin-right: 15px;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
