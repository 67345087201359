.container {
  width: 100%;
}

@media (min-width: 350px) {
  .container {
    max-width: 350px;
  }
}

@media (min-width: 475px) {
  .container {
    max-width: 475px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.main-container {
  margin: auto;
  max-width: 80rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn {
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Graphik", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  text-decoration: none;
}

.btn.primary {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.btn.primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 72, 167, var(--tw-bg-opacity));
}

.btn.primary:active {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.btn.primary {
  height: 2.5rem;
  line-height: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn.secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.btn.secondary:hover {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.btn.secondary:active {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.btn.secondary {
  border-style: solid;
  border-width: 2px;
  height: 2.25rem;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-transparent {
  background-color: transparent;
}

.bg-current {
  background-color: currentColor;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 199, 132, var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 160, 71, var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 235, 238, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 205, 210, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 115, 115, var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 57, 53, var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity));
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity));
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 79, var(--tw-bg-opacity));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 179, 0, var(--tw-bg-opacity));
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity));
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity));
}

.bg-brown-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity));
}

.bg-brown-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity));
}

.bg-brown-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity));
}

.bg-brown-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 136, 127, var(--tw-bg-opacity));
}

.bg-brown-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity));
}

.bg-brown-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity));
}

.bg-brown-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 76, 65, var(--tw-bg-opacity));
}

.bg-brown-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity));
}

.bg-brown-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity));
}

.bg-brown-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity));
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 255, 255, var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 255, 255, var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 229, 255, var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 184, 212, var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 158, 255, var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 109, 254, var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 90, 254, var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 79, 254, var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 128, 252, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 64, 251, var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 249, var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 0, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 247, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 238, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 220, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 202, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 185, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 165, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 144, 227, var(--tw-bg-opacity));
}

.bg-juni-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 123, 198, var(--tw-bg-opacity));
}

.bg-juni-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 101, 169, var(--tw-bg-opacity));
}

.bg-juni-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 79, 140, var(--tw-bg-opacity));
}

.bg-juni-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 221, 239, var(--tw-bg-opacity));
}

.bg-juni-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 188, 224, var(--tw-bg-opacity));
}

.bg-juni-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 153, 208, var(--tw-bg-opacity));
}

.bg-juni-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 192, var(--tw-bg-opacity));
}

.bg-juni-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.bg-juni-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 237, 219, var(--tw-bg-opacity));
}

.bg-juni-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 219, 183, var(--tw-bg-opacity));
}

.bg-juni-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(114, 201, 147, var(--tw-bg-opacity));
}

.bg-juni-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 183, 111, var(--tw-bg-opacity));
}

.bg-juni-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 165, 74, var(--tw-bg-opacity));
}

.bg-juni-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 239, 241, var(--tw-bg-opacity));
}

.bg-juni-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 223, 226, var(--tw-bg-opacity));
}

.bg-juni-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 192, 198, var(--tw-bg-opacity));
}

.bg-juni-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 160, 169, var(--tw-bg-opacity));
}

.bg-juni-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 128, 140, var(--tw-bg-opacity));
}

.bg-juni-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 96, 111, var(--tw-bg-opacity));
}

.bg-juni-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 77, 97, var(--tw-bg-opacity));
}

.bg-juni-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.bg-juni-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 39, 69, var(--tw-bg-opacity));
}

.bg-juni-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(152, 20, 54, var(--tw-bg-opacity));
}

.bg-juni-pink-gradient {
  background-color: linear-gradient(180deg, #EB7480 0%, #E75564 100%);
}

.bg-juni-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 233, 214, var(--tw-bg-opacity));
}

.bg-juni-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 211, 173, var(--tw-bg-opacity));
}

.bg-juni-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 189, 132, var(--tw-bg-opacity));
}

.bg-juni-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 167, 91, var(--tw-bg-opacity));
}

.bg-juni-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 145, 50, var(--tw-bg-opacity));
}

.bg-juni-dark-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 228, 236, var(--tw-bg-opacity));
}

.bg-juni-dark-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 201, 216, var(--tw-bg-opacity));
}

.bg-juni-dark-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 146, 177, var(--tw-bg-opacity));
}

.bg-juni-dark-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(95, 92, 138, var(--tw-bg-opacity));
}

.bg-juni-dark-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(65, 62, 117, var(--tw-bg-opacity));
}

.bg-juni-dark-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 99, var(--tw-bg-opacity));
}

.bg-juni-light-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 244, 251, var(--tw-bg-opacity));
}

.bg-blue-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity));
}

.bg-blue-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity));
}

.bg-blue-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity));
}

.bg-blue-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 164, 174, var(--tw-bg-opacity));
}

.bg-blue-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity));
}

.bg-blue-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity));
}

.bg-blue-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 110, 122, var(--tw-bg-opacity));
}

.bg-blue-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity));
}

.bg-blue-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity));
}

.bg-blue-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity));
}

.bg-light-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 255, 144, var(--tw-bg-opacity));
}

.bg-light-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 255, 89, var(--tw-bg-opacity));
}

.bg-light-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 255, 3, var(--tw-bg-opacity));
}

.bg-light-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 221, 23, var(--tw-bg-opacity));
}

.bg-deep-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity));
}

.bg-deep-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity));
}

.bg-deep-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity));
}

.bg-eggshell-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 253, 255, var(--tw-bg-opacity));
}

.bg-j-dark-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.bg-j-dark-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 201, 216, var(--tw-bg-opacity));
}

.bg-j-dark-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 146, 177, var(--tw-bg-opacity));
}

.bg-j-dark-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(95, 92, 138, var(--tw-bg-opacity));
}

.bg-j-dark-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(65, 62, 117, var(--tw-bg-opacity));
}

.bg-j-dark-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 99, var(--tw-bg-opacity));
}

.bg-j-dark-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 35, 80, var(--tw-bg-opacity));
}

.bg-j-dark-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 24, 62, var(--tw-bg-opacity));
}

.bg-j-dark-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 11, 51, var(--tw-bg-opacity));
}

.bg-j-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.bg-j-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
}

.bg-j-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 237, 244, var(--tw-bg-opacity));
}

.bg-j-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(218, 229, 238, var(--tw-bg-opacity));
}

.bg-j-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 218, 231, var(--tw-bg-opacity));
}

.bg-j-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 238, 247, var(--tw-bg-opacity));
}

.bg-j-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.bg-j-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 188, 224, var(--tw-bg-opacity));
}

.bg-j-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 153, 208, var(--tw-bg-opacity));
}

.bg-j-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 192, var(--tw-bg-opacity));
}

.bg-j-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.bg-j-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 72, 167, var(--tw-bg-opacity));
}

.bg-j-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 56, 151, var(--tw-bg-opacity));
}

.bg-j-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 233, 214, var(--tw-bg-opacity));
}

.bg-j-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 211, 173, var(--tw-bg-opacity));
}

.bg-j-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 189, 132, var(--tw-bg-opacity));
}

.bg-j-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 167, 91, var(--tw-bg-opacity));
}

.bg-j-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 145, 50, var(--tw-bg-opacity));
}

.bg-j-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 130, 26, var(--tw-bg-opacity));
}

.bg-j-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 115, 5, var(--tw-bg-opacity));
}

.bg-j-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 240, 241, var(--tw-bg-opacity));
}

.bg-j-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 223, 226, var(--tw-bg-opacity));
}

.bg-j-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 192, 198, var(--tw-bg-opacity));
}

.bg-j-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 160, 169, var(--tw-bg-opacity));
}

.bg-j-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 128, 140, var(--tw-bg-opacity));
}

.bg-j-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 96, 111, var(--tw-bg-opacity));
}

.bg-j-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.bg-j-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 246, 237, var(--tw-bg-opacity));
}

.bg-j-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 237, 219, var(--tw-bg-opacity));
}

.bg-j-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 219, 183, var(--tw-bg-opacity));
}

.bg-j-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 192, 128, var(--tw-bg-opacity));
}

.bg-j-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 165, 74, var(--tw-bg-opacity));
}

.bg-j-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 146, 60, var(--tw-bg-opacity));
}

.bg-j-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 120, 47, var(--tw-bg-opacity));
}

.bg-j-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 240, 255, var(--tw-bg-opacity));
}

.bg-j-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(218, 224, 254, var(--tw-bg-opacity));
}

.bg-j-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 178, 253, var(--tw-bg-opacity));
}

.bg-j-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 101, 252, var(--tw-bg-opacity));
}

.bg-j-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 76, 238, var(--tw-bg-opacity));
}

.bg-j-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 51, 199, var(--tw-bg-opacity));
}

.bg-j-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 237, var(--tw-bg-opacity));
}

.bg-j-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 244, 207, var(--tw-bg-opacity));
}

.bg-j-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 233, 160, var(--tw-bg-opacity));
}

.bg-j-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 213, 66, var(--tw-bg-opacity));
}

.bg-j-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 112, 0, var(--tw-bg-opacity));
}

.bg-hickory-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 9, 9, var(--tw-bg-opacity));
}

.bg-hickory-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 12, 12, var(--tw-bg-opacity));
}

.bg-hickory-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 45, 45, var(--tw-bg-opacity));
}

.bg-gingerbread-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 35, 9, var(--tw-bg-opacity));
}

.bg-gingerbread-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 44, 12, var(--tw-bg-opacity));
}

.bg-gingerbread-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 72, 45, var(--tw-bg-opacity));
}

.bg-olive-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 72, 9, var(--tw-bg-opacity));
}

.bg-olive-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 89, 12, var(--tw-bg-opacity));
}

.bg-olive-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 110, 45, var(--tw-bg-opacity));
}

.bg-cucumber-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 78, 12, var(--tw-bg-opacity));
}

.bg-cucumber-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 117, 38, var(--tw-bg-opacity));
}

.bg-cucumber-light {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 143, 58, var(--tw-bg-opacity));
}

.bg-ocean-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 78, 63, var(--tw-bg-opacity));
}

.bg-ocean-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 117, 99, var(--tw-bg-opacity));
}

.bg-ocean-light {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 143, 123, var(--tw-bg-opacity));
}

.bg-sapphire-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 58, 78, var(--tw-bg-opacity));
}

.bg-sapphire-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 93, 117, var(--tw-bg-opacity));
}

.bg-sapphire-light {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 117, 143, var(--tw-bg-opacity));
}

.bg-navy-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 26, 72, var(--tw-bg-opacity));
}

.bg-navy-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 29, 89, var(--tw-bg-opacity));
}

.bg-navy-light {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 59, 110, var(--tw-bg-opacity));
}

.bg-eggplant-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 12, 78, var(--tw-bg-opacity));
}

.bg-eggplant-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 38, 117, var(--tw-bg-opacity));
}

.bg-eggplant-light {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 58, 143, var(--tw-bg-opacity));
}

.bg-grape-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 9, 72, var(--tw-bg-opacity));
}

.bg-grape-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 12, 89, var(--tw-bg-opacity));
}

.bg-grape-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 45, 110, var(--tw-bg-opacity));
}

.odd\:bg-white:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.even\:bg-j-gray-100:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-juni-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 220, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-juni-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 165, 255, var(--tw-bg-opacity));
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-juni-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 247, 255, var(--tw-bg-opacity));
}

.hover\:bg-juni-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 144, 227, var(--tw-bg-opacity));
}

.hover\:bg-juni-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 77, 97, var(--tw-bg-opacity));
}

.hover\:bg-juni-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 39, 69, var(--tw-bg-opacity));
}

.hover\:bg-blue-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity));
}

.hover\:bg-j-dark-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.hover\:bg-j-dark-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 35, 80, var(--tw-bg-opacity));
}

.hover\:bg-j-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.hover\:bg-j-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
}

.hover\:bg-j-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 238, 247, var(--tw-bg-opacity));
}

.hover\:bg-j-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.hover\:bg-j-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 72, 167, var(--tw-bg-opacity));
}

.hover\:bg-j-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 240, 241, var(--tw-bg-opacity));
}

.hover\:bg-j-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.hover\:bg-j-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 165, 74, var(--tw-bg-opacity));
}

.hover\:bg-j-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(218, 224, 254, var(--tw-bg-opacity));
}

.hover\:bg-j-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 51, 199, var(--tw-bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.focus\:bg-j-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.focus\:bg-j-pink-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.focus\:bg-j-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 246, 237, var(--tw-bg-opacity));
}

.focus\:bg-j-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 51, 199, var(--tw-bg-opacity));
}

.active\:bg-j-dark-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 99, var(--tw-bg-opacity));
}

.active\:bg-j-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
}

.active\:bg-j-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 237, 244, var(--tw-bg-opacity));
}

.active\:bg-j-purple-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 238, 247, var(--tw-bg-opacity));
}

.active\:bg-j-purple-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 56, 151, var(--tw-bg-opacity));
}

.active\:bg-j-green-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 146, 60, var(--tw-bg-opacity));
}

.active\:bg-j-blue-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 178, 253, var(--tw-bg-opacity));
}

.disabled\:bg-transparent:disabled {
  background-color: transparent;
}

.disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.disabled\:bg-blue-gray-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity));
}

.disabled\:bg-j-dark-100:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.disabled\:bg-j-purple-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.bg-loader-gradient {
  background-image: linear-gradient(to left, var(--tw-gradient-from) 50%, var(--tw-gradient-to) 50%);
}

.from-white {
  --tw-gradient-from: #FFFFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.from-juni-pink-400 {
  --tw-gradient-from: #EE808C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 128, 140, 0));
}

.from-j-dark-600 {
  --tw-gradient-from: #292563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 37, 99, 0));
}

.to-juni-pink-50 {
  --tw-gradient-to: #FDEFF1;
}

.to-juni-pink-500 {
  --tw-gradient-to: #EA606F;
}

.to-j-dark-200 {
  --tw-gradient-to: #CAC9D8;
}

.hover\:from-juni-pink-700:hover {
  --tw-gradient-from: #C13A53;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(193, 58, 83, 0));
}

.hover\:to-juni-pink-700:hover {
  --tw-gradient-to: #C13A53;
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.hover\:bg-opacity-0:hover {
  --tw-bg-opacity: 0;
}

.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-loader {
  background-size: 200% 100%;
}

.border-collapse {
  border-collapse: collapse;
}

.border-transparent {
  border-color: transparent;
}

.border-current {
  border-color: currentColor;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-border-opacity));
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-border-opacity));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-border-opacity));
}

.border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 235, 238, var(--tw-border-opacity));
}

.border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 205, 210, var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-border-opacity));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity));
}

.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-border-opacity));
}

.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-border-opacity));
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-border-opacity));
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-border-opacity));
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-border-opacity));
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-border-opacity));
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity));
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-border-opacity));
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity));
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-border-opacity));
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-border-opacity));
}

.border-brown-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-border-opacity));
}

.border-brown-100 {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity));
}

.border-brown-200 {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity));
}

.border-brown-300 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-border-opacity));
}

.border-brown-400 {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity));
}

.border-brown-500 {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity));
}

.border-brown-600 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-border-opacity));
}

.border-brown-700 {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity));
}

.border-brown-800 {
  --tw-border-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-border-opacity));
}

.border-brown-900 {
  --tw-border-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-border-opacity));
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-border-opacity));
}

.border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-border-opacity));
}

.border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-border-opacity));
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-border-opacity));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-border-opacity));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-border-opacity));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-border-opacity));
}

.border-juni-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(233, 247, 255, var(--tw-border-opacity));
}

.border-juni-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 238, 255, var(--tw-border-opacity));
}

.border-juni-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 220, 255, var(--tw-border-opacity));
}

.border-juni-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(106, 202, 255, var(--tw-border-opacity));
}

.border-juni-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 185, 255, var(--tw-border-opacity));
}

.border-juni-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 165, 255, var(--tw-border-opacity));
}

.border-juni-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 144, 227, var(--tw-border-opacity));
}

.border-juni-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 123, 198, var(--tw-border-opacity));
}

.border-juni-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 101, 169, var(--tw-border-opacity));
}

.border-juni-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 79, 140, var(--tw-border-opacity));
}

.border-juni-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 221, 239, var(--tw-border-opacity));
}

.border-juni-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.border-juni-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.border-juni-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.border-juni-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.border-juni-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(208, 237, 219, var(--tw-border-opacity));
}

.border-juni-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(162, 219, 183, var(--tw-border-opacity));
}

.border-juni-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(114, 201, 147, var(--tw-border-opacity));
}

.border-juni-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(68, 183, 111, var(--tw-border-opacity));
}

.border-juni-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(20, 165, 74, var(--tw-border-opacity));
}

.border-juni-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 239, 241, var(--tw-border-opacity));
}

.border-juni-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 223, 226, var(--tw-border-opacity));
}

.border-juni-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 192, 198, var(--tw-border-opacity));
}

.border-juni-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.border-juni-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 128, 140, var(--tw-border-opacity));
}

.border-juni-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 96, 111, var(--tw-border-opacity));
}

.border-juni-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(214, 77, 97, var(--tw-border-opacity));
}

.border-juni-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 58, 83, var(--tw-border-opacity));
}

.border-juni-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(173, 39, 69, var(--tw-border-opacity));
}

.border-juni-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(152, 20, 54, var(--tw-border-opacity));
}

.border-juni-pink-gradient {
  border-color: linear-gradient(180deg, #EB7480 0%, #E75564 100%);
}

.border-juni-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 214, var(--tw-border-opacity));
}

.border-juni-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 211, 173, var(--tw-border-opacity));
}

.border-juni-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 189, 132, var(--tw-border-opacity));
}

.border-juni-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 167, 91, var(--tw-border-opacity));
}

.border-juni-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 145, 50, var(--tw-border-opacity));
}

.border-juni-dark-100 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 228, 236, var(--tw-border-opacity));
}

.border-juni-dark-200 {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.border-juni-dark-300 {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.border-juni-dark-500 {
  --tw-border-opacity: 1;
  border-color: rgba(95, 92, 138, var(--tw-border-opacity));
}

.border-juni-dark-700 {
  --tw-border-opacity: 1;
  border-color: rgba(65, 62, 117, var(--tw-border-opacity));
}

.border-juni-dark-900 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 99, var(--tw-border-opacity));
}

.border-juni-light-100 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 244, 251, var(--tw-border-opacity));
}

.border-blue-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity));
}

.border-blue-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity));
}

.border-blue-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity));
}

.border-blue-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-border-opacity));
}

.border-blue-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity));
}

.border-blue-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity));
}

.border-blue-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-border-opacity));
}

.border-blue-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity));
}

.border-blue-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-border-opacity));
}

.border-blue-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-border-opacity));
}

.border-light-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-border-opacity));
}

.border-light-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-border-opacity));
}

.border-light-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-border-opacity));
}

.border-light-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-border-opacity));
}

.border-deep-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-border-opacity));
}

.border-deep-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-border-opacity));
}

.border-deep-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity));
}

.border-eggshell-blue {
  --tw-border-opacity: 1;
  border-color: rgba(248, 253, 255, var(--tw-border-opacity));
}

.border-j-dark-100 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.border-j-dark-200 {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.border-j-dark-300 {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.border-j-dark-400 {
  --tw-border-opacity: 1;
  border-color: rgba(95, 92, 138, var(--tw-border-opacity));
}

.border-j-dark-500 {
  --tw-border-opacity: 1;
  border-color: rgba(65, 62, 117, var(--tw-border-opacity));
}

.border-j-dark-600 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 99, var(--tw-border-opacity));
}

.border-j-dark-700 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 35, 80, var(--tw-border-opacity));
}

.border-j-dark-800 {
  --tw-border-opacity: 1;
  border-color: rgba(26, 24, 62, var(--tw-border-opacity));
}

.border-j-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 250, 252, var(--tw-border-opacity));
}

.border-j-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(240, 245, 250, var(--tw-border-opacity));
}

.border-j-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 237, 244, var(--tw-border-opacity));
}

.border-j-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(218, 229, 238, var(--tw-border-opacity));
}

.border-j-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 218, 231, var(--tw-border-opacity));
}

.border-j-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 238, 247, var(--tw-border-opacity));
}

.border-j-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.border-j-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.border-j-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.border-j-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.border-j-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.border-j-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(85, 72, 167, var(--tw-border-opacity));
}

.border-j-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(70, 56, 151, var(--tw-border-opacity));
}

.border-j-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 214, var(--tw-border-opacity));
}

.border-j-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 211, 173, var(--tw-border-opacity));
}

.border-j-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 189, 132, var(--tw-border-opacity));
}

.border-j-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 167, 91, var(--tw-border-opacity));
}

.border-j-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 145, 50, var(--tw-border-opacity));
}

.border-j-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 130, 26, var(--tw-border-opacity));
}

.border-j-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 115, 5, var(--tw-border-opacity));
}

.border-j-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 240, 241, var(--tw-border-opacity));
}

.border-j-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 223, 226, var(--tw-border-opacity));
}

.border-j-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 192, 198, var(--tw-border-opacity));
}

.border-j-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.border-j-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 128, 140, var(--tw-border-opacity));
}

.border-j-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 96, 111, var(--tw-border-opacity));
}

.border-j-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 58, 83, var(--tw-border-opacity));
}

.border-j-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(231, 246, 237, var(--tw-border-opacity));
}

.border-j-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(208, 237, 219, var(--tw-border-opacity));
}

.border-j-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(162, 219, 183, var(--tw-border-opacity));
}

.border-j-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 192, 128, var(--tw-border-opacity));
}

.border-j-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(20, 165, 74, var(--tw-border-opacity));
}

.border-j-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(9, 146, 60, var(--tw-border-opacity));
}

.border-j-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 120, 47, var(--tw-border-opacity));
}

.border-j-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 240, 255, var(--tw-border-opacity));
}

.border-j-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(218, 224, 254, var(--tw-border-opacity));
}

.border-j-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(164, 178, 253, var(--tw-border-opacity));
}

.border-j-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(72, 101, 252, var(--tw-border-opacity));
}

.border-j-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(45, 76, 238, var(--tw-border-opacity));
}

.border-j-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(23, 51, 199, var(--tw-border-opacity));
}

.border-j-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 237, var(--tw-border-opacity));
}

.border-j-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 244, 207, var(--tw-border-opacity));
}

.border-j-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 233, 160, var(--tw-border-opacity));
}

.border-j-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 213, 66, var(--tw-border-opacity));
}

.border-j-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 112, 0, var(--tw-border-opacity));
}

.border-hickory-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 9, 9, var(--tw-border-opacity));
}

.border-hickory-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 12, 12, var(--tw-border-opacity));
}

.border-hickory-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 45, 45, var(--tw-border-opacity));
}

.border-gingerbread-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 35, 9, var(--tw-border-opacity));
}

.border-gingerbread-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 44, 12, var(--tw-border-opacity));
}

.border-gingerbread-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 72, 45, var(--tw-border-opacity));
}

.border-olive-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 72, 9, var(--tw-border-opacity));
}

.border-olive-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 89, 12, var(--tw-border-opacity));
}

.border-olive-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 110, 45, var(--tw-border-opacity));
}

.border-cucumber-dark {
  --tw-border-opacity: 1;
  border-color: rgba(33, 78, 12, var(--tw-border-opacity));
}

.border-cucumber-medium {
  --tw-border-opacity: 1;
  border-color: rgba(63, 117, 38, var(--tw-border-opacity));
}

.border-cucumber-light {
  --tw-border-opacity: 1;
  border-color: rgba(85, 143, 58, var(--tw-border-opacity));
}

.border-ocean-dark {
  --tw-border-opacity: 1;
  border-color: rgba(12, 78, 63, var(--tw-border-opacity));
}

.border-ocean-medium {
  --tw-border-opacity: 1;
  border-color: rgba(38, 117, 99, var(--tw-border-opacity));
}

.border-ocean-light {
  --tw-border-opacity: 1;
  border-color: rgba(58, 143, 123, var(--tw-border-opacity));
}

.border-sapphire-dark {
  --tw-border-opacity: 1;
  border-color: rgba(12, 58, 78, var(--tw-border-opacity));
}

.border-sapphire-medium {
  --tw-border-opacity: 1;
  border-color: rgba(38, 93, 117, var(--tw-border-opacity));
}

.border-sapphire-light {
  --tw-border-opacity: 1;
  border-color: rgba(58, 117, 143, var(--tw-border-opacity));
}

.border-navy-dark {
  --tw-border-opacity: 1;
  border-color: rgba(9, 26, 72, var(--tw-border-opacity));
}

.border-navy-medium {
  --tw-border-opacity: 1;
  border-color: rgba(12, 29, 89, var(--tw-border-opacity));
}

.border-navy-light {
  --tw-border-opacity: 1;
  border-color: rgba(45, 59, 110, var(--tw-border-opacity));
}

.border-eggplant-dark {
  --tw-border-opacity: 1;
  border-color: rgba(21, 12, 78, var(--tw-border-opacity));
}

.border-eggplant-medium {
  --tw-border-opacity: 1;
  border-color: rgba(49, 38, 117, var(--tw-border-opacity));
}

.border-eggplant-light {
  --tw-border-opacity: 1;
  border-color: rgba(70, 58, 143, var(--tw-border-opacity));
}

.border-grape-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 9, 72, var(--tw-border-opacity));
}

.border-grape-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 12, 89, var(--tw-border-opacity));
}

.border-grape-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 45, 110, var(--tw-border-opacity));
}

.focus-within\:border-juni-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 165, 255, var(--tw-border-opacity));
}

.focus-within\:border-j-dark-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.hover\:border-juni-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(157, 220, 255, var(--tw-border-opacity));
}

.hover\:border-juni-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.hover\:border-j-dark-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.hover\:border-j-dark-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.hover\:border-j-dark-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(95, 92, 138, var(--tw-border-opacity));
}

.hover\:border-j-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.hover\:border-j-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.hover\:border-j-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.hover\:border-j-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.hover\:border-j-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.hover\:border-j-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.hover\:border-j-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(91, 192, 128, var(--tw-border-opacity));
}

.focus\:border-j-dark-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.focus\:border-j-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.focus\:border-j-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.focus\:border-j-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.focus\:border-j-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(247, 192, 198, var(--tw-border-opacity));
}

.focus\:border-j-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(208, 237, 219, var(--tw-border-opacity));
}

.focus\:border-j-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(162, 219, 183, var(--tw-border-opacity));
}

.focus\:border-j-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(164, 178, 253, var(--tw-border-opacity));
}

.active\:border-j-dark-600:active {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 99, var(--tw-border-opacity));
}

.active\:border-j-purple-400:active {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.active\:border-j-purple-500:active {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.active\:border-j-pink-400:active {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.active\:border-j-green-400:active {
  --tw-border-opacity: 1;
  border-color: rgba(91, 192, 128, var(--tw-border-opacity));
}

.disabled\:border-transparent:disabled {
  border-color: transparent;
}

.disabled\:border-white:disabled {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.disabled\:border-blue-gray-400:disabled {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity));
}

.disabled\:border-j-dark-100:disabled {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.border-opacity-20 {
  --tw-border-opacity: 0.2;
}

.border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.border-opacity-50 {
  --tw-border-opacity: 0.5;
}

.disabled\:border-opacity-10:disabled {
  --tw-border-opacity: 0.1;
}

.disabled\:border-opacity-25:disabled {
  --tw-border-opacity: 0.25;
}

.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.disabled\:border-solid:disabled {
  border-style: solid;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.disabled\:border-2:disabled {
  border-width: 2px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.disabled\:box-border:disabled {
  box-sizing: border-box;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-items-center {
  justify-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-end {
  justify-self: end;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.order-last {
  order: 9999;
}

.float-right {
  float: right;
}

.font-sans {
  font-family: "Open Sans", sans-serif;
}

.font-graphik {
  font-family: "Graphik", sans-serif;
}

.font-serif {
  font-family: Source Serif Pro, serif;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.h-0 {
  height: 0px;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-18 {
  height: 4.5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-1\.5 {
  height: 0.375rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.333333%;
}

.h-2\/3 {
  height: 66.666667%;
}

.h-1\/4 {
  height: 25%;
}

.h-2\/4 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-1\/5 {
  height: 20%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-1\/6 {
  height: 16.666667%;
}

.h-2\/6 {
  height: 33.333333%;
}

.h-3\/6 {
  height: 50%;
}

.h-4\/6 {
  height: 66.666667%;
}

.h-5\/6 {
  height: 83.333333%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.text-2xs {
  font-size: 0.625rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-heading-900 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.m-0 {
  margin: 0px;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-7 {
  margin: 1.75rem;
}

.m-8 {
  margin: 2rem;
}

.m-9 {
  margin: 2.25rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-11 {
  margin: 2.75rem;
}

.m-12 {
  margin: 3rem;
}

.m-14 {
  margin: 3.5rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-28 {
  margin: 7rem;
}

.m-32 {
  margin: 8rem;
}

.m-36 {
  margin: 9rem;
}

.m-40 {
  margin: 10rem;
}

.m-44 {
  margin: 11rem;
}

.m-48 {
  margin: 12rem;
}

.m-52 {
  margin: 13rem;
}

.m-56 {
  margin: 14rem;
}

.m-60 {
  margin: 15rem;
}

.m-64 {
  margin: 16rem;
}

.m-72 {
  margin: 18rem;
}

.m-80 {
  margin: 20rem;
}

.m-96 {
  margin: 24rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.m-0\.5 {
  margin: 0.125rem;
}

.m-1\.5 {
  margin: 0.375rem;
}

.m-2\.5 {
  margin: 0.625rem;
}

.m-3\.5 {
  margin: 0.875rem;
}

.-m-0 {
  margin: 0px;
}

.-m-1 {
  margin: -0.25rem;
}

.-m-2 {
  margin: -0.5rem;
}

.-m-3 {
  margin: -0.75rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-7 {
  margin: -1.75rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-9 {
  margin: -2.25rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-11 {
  margin: -2.75rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-14 {
  margin: -3.5rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-28 {
  margin: -7rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-36 {
  margin: -9rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-44 {
  margin: -11rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-52 {
  margin: -13rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-60 {
  margin: -15rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-72 {
  margin: -18rem;
}

.-m-80 {
  margin: -20rem;
}

.-m-96 {
  margin: -24rem;
}

.-m-px {
  margin: -1px;
}

.-m-0\.5 {
  margin: -0.125rem;
}

.-m-1\.5 {
  margin: -0.375rem;
}

.-m-2\.5 {
  margin: -0.625rem;
}

.-m-3\.5 {
  margin: -0.875rem;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}

.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}

.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.my-1\.5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}

.-my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.-mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}

.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}

.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}

.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}

.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}

.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}

.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}

.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}

.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}

.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-0\.5 {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}

.-mx-0\.5 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

.-mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}

.-my-2\.5 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.-mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.-my-3\.5 {
  margin-top: -0.875rem;
  margin-bottom: -0.875rem;
}

.-mx-3\.5 {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-16 {
  margin-left: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.ml-24 {
  margin-left: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.ml-28 {
  margin-left: 7rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.ml-32 {
  margin-left: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mr-36 {
  margin-right: 9rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.ml-36 {
  margin-left: 9rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.ml-40 {
  margin-left: 10rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mr-44 {
  margin-right: 11rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.ml-44 {
  margin-left: 11rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.ml-48 {
  margin-left: 12rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mr-52 {
  margin-right: 13rem;
}

.mb-52 {
  margin-bottom: 13rem;
}

.ml-52 {
  margin-left: 13rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.ml-56 {
  margin-left: 14rem;
}

.mt-60 {
  margin-top: 15rem;
}

.mr-60 {
  margin-right: 15rem;
}

.mb-60 {
  margin-bottom: 15rem;
}

.ml-60 {
  margin-left: 15rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.ml-64 {
  margin-left: 16rem;
}

.mt-72 {
  margin-top: 18rem;
}

.mr-72 {
  margin-right: 18rem;
}

.mb-72 {
  margin-bottom: 18rem;
}

.ml-72 {
  margin-left: 18rem;
}

.mt-80 {
  margin-top: 20rem;
}

.mr-80 {
  margin-right: 20rem;
}

.mb-80 {
  margin-bottom: 20rem;
}

.ml-80 {
  margin-left: 20rem;
}

.mt-96 {
  margin-top: 24rem;
}

.mr-96 {
  margin-right: 24rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.ml-96 {
  margin-left: 24rem;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.mr-0\.5 {
  margin-right: 0.125rem;
}

.mb-0\.5 {
  margin-bottom: 0.125rem;
}

.ml-0\.5 {
  margin-left: 0.125rem;
}

.mt-1\.5 {
  margin-top: 0.375rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.ml-1\.5 {
  margin-left: 0.375rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.mr-2\.5 {
  margin-right: 0.625rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.ml-2\.5 {
  margin-left: 0.625rem;
}

.mt-3\.5 {
  margin-top: 0.875rem;
}

.mr-3\.5 {
  margin-right: 0.875rem;
}

.mb-3\.5 {
  margin-bottom: 0.875rem;
}

.ml-3\.5 {
  margin-left: 0.875rem;
}

.-mt-0 {
  margin-top: 0px;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-mb-3 {
  margin-bottom: -0.75rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-44 {
  margin-top: -11rem;
}

.-mt-0\.5 {
  margin-top: -0.125rem;
}

.-ml-1\.5 {
  margin-left: -0.375rem;
}

.first\:mt-0:first-child {
  margin-top: 0px;
}

.last\:mb-0:last-child {
  margin-bottom: 0px;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-50vh {
  max-height: 50vh;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-xs {
  max-width: 475px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.min-h-0 {
  min-height: 0px;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-18 {
  min-width: 4.5rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.object-top {
  object-position: top;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.hover\:opacity-60:hover {
  opacity: 0.6;
}

.hover\:opacity-70:hover {
  opacity: 0.7;
}

.hover\:opacity-80:hover {
  opacity: 0.8;
}

.disabled\:opacity-70:disabled {
  opacity: 0.7;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-72 {
  padding: 18rem;
}

.p-80 {
  padding: 20rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.p-0\.5 {
  padding: 0.125rem;
}

.p-1\.5 {
  padding: 0.375rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.p-3\.5 {
  padding: 0.875rem;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pt-18 {
  padding-top: 4.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pl-28 {
  padding-left: 7rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pr-36 {
  padding-right: 9rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pr-44 {
  padding-right: 11rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pl-44 {
  padding-left: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pt-52 {
  padding-top: 13rem;
}

.pr-52 {
  padding-right: 13rem;
}

.pb-52 {
  padding-bottom: 13rem;
}

.pl-52 {
  padding-left: 13rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pt-60 {
  padding-top: 15rem;
}

.pr-60 {
  padding-right: 15rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pl-60 {
  padding-left: 15rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pr-72 {
  padding-right: 18rem;
}

.pb-72 {
  padding-bottom: 18rem;
}

.pl-72 {
  padding-left: 18rem;
}

.pt-80 {
  padding-top: 20rem;
}

.pr-80 {
  padding-right: 20rem;
}

.pb-80 {
  padding-bottom: 20rem;
}

.pl-80 {
  padding-left: 20rem;
}

.pt-96 {
  padding-top: 24rem;
}

.pr-96 {
  padding-right: 24rem;
}

.pb-96 {
  padding-bottom: 24rem;
}

.pl-96 {
  padding-left: 24rem;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.pt-0\.5 {
  padding-top: 0.125rem;
}

.pr-0\.5 {
  padding-right: 0.125rem;
}

.pb-0\.5 {
  padding-bottom: 0.125rem;
}

.pl-0\.5 {
  padding-left: 0.125rem;
}

.pt-1\.5 {
  padding-top: 0.375rem;
}

.pr-1\.5 {
  padding-right: 0.375rem;
}

.pb-1\.5 {
  padding-bottom: 0.375rem;
}

.pl-1\.5 {
  padding-left: 0.375rem;
}

.pt-2\.5 {
  padding-top: 0.625rem;
}

.pr-2\.5 {
  padding-right: 0.625rem;
}

.pb-2\.5 {
  padding-bottom: 0.625rem;
}

.pl-2\.5 {
  padding-left: 0.625rem;
}

.pt-3\.5 {
  padding-top: 0.875rem;
}

.pr-3\.5 {
  padding-right: 0.875rem;
}

.pb-3\.5 {
  padding-bottom: 0.875rem;
}

.pl-3\.5 {
  padding-left: 0.875rem;
}

.first\:pt-0:first-child {
  padding-top: 0px;
}

.first\:pt-4:first-child {
  padding-top: 1rem;
}

.last\:pb-0:last-child {
  padding-bottom: 0px;
}

.last\:pb-4:last-child {
  padding-bottom: 1rem;
}

.placeholder-blue-gray-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity));
}

.placeholder-blue-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-placeholder-opacity));
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.inset-4 {
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.inset-x-0 {
  right: 0px;
  left: 0px;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.top-1 {
  top: 0.25rem;
}

.right-1 {
  right: 0.25rem;
}

.bottom-1 {
  bottom: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.left-2 {
  left: 0.5rem;
}

.top-3 {
  top: 0.75rem;
}

.right-3 {
  right: 0.75rem;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-20 {
  bottom: 5rem;
}

.top-0\.5 {
  top: 0.125rem;
}

.left-0\.5 {
  left: 0.125rem;
}

.top-1\.5 {
  top: 0.375rem;
}

.right-1\.5 {
  right: 0.375rem;
}

.top-2\.5 {
  top: 0.625rem;
}

.-top-1 {
  top: -0.25rem;
}

.-right-1 {
  right: -0.25rem;
}

.-left-1 {
  left: -0.25rem;
}

.-top-2 {
  top: -0.5rem;
}

.-left-2 {
  left: -0.5rem;
}

.-right-3 {
  right: -0.75rem;
}

.-bottom-14 {
  bottom: -3.5rem;
}

.-right-16 {
  right: -4rem;
}

.-top-28 {
  top: -7rem;
}

.-right-28 {
  right: -7rem;
}

.-right-32 {
  right: -8rem;
}

.-bottom-36 {
  bottom: -9rem;
}

.-top-2\.5 {
  top: -0.625rem;
}

.-right-3\.5 {
  right: -0.875rem;
}

.top-1\/2 {
  top: 50%;
}

.left-2\/4 {
  left: 50%;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize {
  resize: both;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow-1 {
  --tw-shadow: 0px 2px 4px rgba(98, 85, 176, 0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2 {
  --tw-shadow: 0px 4px 8px rgba(98, 85, 176, 0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-3 {
  --tw-shadow: 0px 8px 24px rgba(98, 85, 176, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-4 {
  --tw-shadow: 0px 16px 24px rgba(98, 85, 176, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0px 16px 24px #E7EEF9;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-button-j-purple-600 {
  --tw-shadow: 0 0 0 2px #6255B0;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-1:hover {
  --tw-shadow: 0px 2px 4px rgba(98, 85, 176, 0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-2:hover {
  --tw-shadow: 0px 4px 8px rgba(98, 85, 176, 0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-button-j-purple-300:focus {
  --tw-shadow: 0 0 0 2px #C1BCE0;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-j-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 245, 250, var(--tw-ring-opacity));
}

.ring-j-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 85, 176, var(--tw-ring-opacity));
}

.focus\:ring-j-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(208, 237, 219, var(--tw-ring-opacity));
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.table-auto {
  table-layout: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-transparent {
  color: transparent;
}

.text-current {
  color: currentColor;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-text-opacity));
}

.text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 235, 238, var(--tw-text-opacity));
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 205, 210, var(--tw-text-opacity));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-text-opacity));
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-text-opacity));
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-text-opacity));
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-text-opacity));
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-text-opacity));
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity));
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-text-opacity));
}

.text-brown-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-text-opacity));
}

.text-brown-100 {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity));
}

.text-brown-200 {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity));
}

.text-brown-300 {
  --tw-text-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-text-opacity));
}

.text-brown-400 {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity));
}

.text-brown-500 {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity));
}

.text-brown-600 {
  --tw-text-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-text-opacity));
}

.text-brown-700 {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity));
}

.text-brown-800 {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity));
}

.text-brown-900 {
  --tw-text-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-text-opacity));
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-text-opacity));
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-text-opacity));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-text-opacity));
}

.text-juni-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(233, 247, 255, var(--tw-text-opacity));
}

.text-juni-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(207, 238, 255, var(--tw-text-opacity));
}

.text-juni-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(157, 220, 255, var(--tw-text-opacity));
}

.text-juni-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(106, 202, 255, var(--tw-text-opacity));
}

.text-juni-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(55, 185, 255, var(--tw-text-opacity));
}

.text-juni-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 165, 255, var(--tw-text-opacity));
}

.text-juni-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(1, 144, 227, var(--tw-text-opacity));
}

.text-juni-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(1, 123, 198, var(--tw-text-opacity));
}

.text-juni-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(1, 101, 169, var(--tw-text-opacity));
}

.text-juni-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(1, 79, 140, var(--tw-text-opacity));
}

.text-juni-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 221, 239, var(--tw-text-opacity));
}

.text-juni-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(193, 188, 224, var(--tw-text-opacity));
}

.text-juni-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.text-juni-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 192, var(--tw-text-opacity));
}

.text-juni-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(98, 85, 176, var(--tw-text-opacity));
}

.text-juni-green-100 {
  --tw-text-opacity: 1;
  color: rgba(208, 237, 219, var(--tw-text-opacity));
}

.text-juni-green-200 {
  --tw-text-opacity: 1;
  color: rgba(162, 219, 183, var(--tw-text-opacity));
}

.text-juni-green-300 {
  --tw-text-opacity: 1;
  color: rgba(114, 201, 147, var(--tw-text-opacity));
}

.text-juni-green-400 {
  --tw-text-opacity: 1;
  color: rgba(68, 183, 111, var(--tw-text-opacity));
}

.text-juni-green-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 165, 74, var(--tw-text-opacity));
}

.text-juni-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 239, 241, var(--tw-text-opacity));
}

.text-juni-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(251, 223, 226, var(--tw-text-opacity));
}

.text-juni-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(247, 192, 198, var(--tw-text-opacity));
}

.text-juni-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(243, 160, 169, var(--tw-text-opacity));
}

.text-juni-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(238, 128, 140, var(--tw-text-opacity));
}

.text-juni-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(234, 96, 111, var(--tw-text-opacity));
}

.text-juni-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(214, 77, 97, var(--tw-text-opacity));
}

.text-juni-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(193, 58, 83, var(--tw-text-opacity));
}

.text-juni-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(173, 39, 69, var(--tw-text-opacity));
}

.text-juni-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(152, 20, 54, var(--tw-text-opacity));
}

.text-juni-pink-gradient {
  color: linear-gradient(180deg, #EB7480 0%, #E75564 100%);
}

.text-juni-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(253, 233, 214, var(--tw-text-opacity));
}

.text-juni-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 211, 173, var(--tw-text-opacity));
}

.text-juni-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 189, 132, var(--tw-text-opacity));
}

.text-juni-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(247, 167, 91, var(--tw-text-opacity));
}

.text-juni-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 145, 50, var(--tw-text-opacity));
}

.text-juni-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(229, 228, 236, var(--tw-text-opacity));
}

.text-juni-dark-200 {
  --tw-text-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-text-opacity));
}

.text-juni-dark-300 {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.text-juni-dark-500 {
  --tw-text-opacity: 1;
  color: rgba(95, 92, 138, var(--tw-text-opacity));
}

.text-juni-dark-700 {
  --tw-text-opacity: 1;
  color: rgba(65, 62, 117, var(--tw-text-opacity));
}

.text-juni-dark-900 {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.text-juni-light-100 {
  --tw-text-opacity: 1;
  color: rgba(239, 244, 251, var(--tw-text-opacity));
}

.text-blue-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity));
}

.text-blue-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity));
}

.text-blue-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity));
}

.text-blue-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-text-opacity));
}

.text-blue-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity));
}

.text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity));
}

.text-blue-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-text-opacity));
}

.text-blue-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity));
}

.text-blue-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity));
}

.text-blue-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity));
}

.text-light-green-100 {
  --tw-text-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-text-opacity));
}

.text-light-green-200 {
  --tw-text-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-text-opacity));
}

.text-light-green-400 {
  --tw-text-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-text-opacity));
}

.text-light-green-700 {
  --tw-text-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-text-opacity));
}

.text-deep-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-text-opacity));
}

.text-deep-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-text-opacity));
}

.text-deep-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity));
}

.text-eggshell-blue {
  --tw-text-opacity: 1;
  color: rgba(248, 253, 255, var(--tw-text-opacity));
}

.text-j-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.text-j-dark-200 {
  --tw-text-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-text-opacity));
}

.text-j-dark-300 {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.text-j-dark-400 {
  --tw-text-opacity: 1;
  color: rgba(95, 92, 138, var(--tw-text-opacity));
}

.text-j-dark-500 {
  --tw-text-opacity: 1;
  color: rgba(65, 62, 117, var(--tw-text-opacity));
}

.text-j-dark-600 {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.text-j-dark-700 {
  --tw-text-opacity: 1;
  color: rgba(37, 35, 80, var(--tw-text-opacity));
}

.text-j-dark-800 {
  --tw-text-opacity: 1;
  color: rgba(26, 24, 62, var(--tw-text-opacity));
}

.text-j-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-text-opacity));
}

.text-j-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(240, 245, 250, var(--tw-text-opacity));
}

.text-j-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(229, 237, 244, var(--tw-text-opacity));
}

.text-j-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(218, 229, 238, var(--tw-text-opacity));
}

.text-j-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(203, 218, 231, var(--tw-text-opacity));
}

.text-j-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(239, 238, 247, var(--tw-text-opacity));
}

.text-j-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.text-j-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(193, 188, 224, var(--tw-text-opacity));
}

.text-j-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.text-j-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 192, var(--tw-text-opacity));
}

.text-j-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(98, 85, 176, var(--tw-text-opacity));
}

.text-j-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(85, 72, 167, var(--tw-text-opacity));
}

.text-j-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(70, 56, 151, var(--tw-text-opacity));
}

.text-j-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(253, 233, 214, var(--tw-text-opacity));
}

.text-j-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 211, 173, var(--tw-text-opacity));
}

.text-j-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 189, 132, var(--tw-text-opacity));
}

.text-j-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(247, 167, 91, var(--tw-text-opacity));
}

.text-j-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 145, 50, var(--tw-text-opacity));
}

.text-j-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(239, 130, 26, var(--tw-text-opacity));
}

.text-j-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(232, 115, 5, var(--tw-text-opacity));
}

.text-j-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(253, 240, 241, var(--tw-text-opacity));
}

.text-j-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 223, 226, var(--tw-text-opacity));
}

.text-j-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(247, 192, 198, var(--tw-text-opacity));
}

.text-j-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(243, 160, 169, var(--tw-text-opacity));
}

.text-j-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(238, 128, 140, var(--tw-text-opacity));
}

.text-j-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(234, 96, 111, var(--tw-text-opacity));
}

.text-j-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(193, 58, 83, var(--tw-text-opacity));
}

.text-j-green-100 {
  --tw-text-opacity: 1;
  color: rgba(231, 246, 237, var(--tw-text-opacity));
}

.text-j-green-200 {
  --tw-text-opacity: 1;
  color: rgba(208, 237, 219, var(--tw-text-opacity));
}

.text-j-green-300 {
  --tw-text-opacity: 1;
  color: rgba(162, 219, 183, var(--tw-text-opacity));
}

.text-j-green-400 {
  --tw-text-opacity: 1;
  color: rgba(91, 192, 128, var(--tw-text-opacity));
}

.text-j-green-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 165, 74, var(--tw-text-opacity));
}

.text-j-green-600 {
  --tw-text-opacity: 1;
  color: rgba(9, 146, 60, var(--tw-text-opacity));
}

.text-j-green-700 {
  --tw-text-opacity: 1;
  color: rgba(3, 120, 47, var(--tw-text-opacity));
}

.text-j-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 240, 255, var(--tw-text-opacity));
}

.text-j-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(218, 224, 254, var(--tw-text-opacity));
}

.text-j-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(164, 178, 253, var(--tw-text-opacity));
}

.text-j-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(72, 101, 252, var(--tw-text-opacity));
}

.text-j-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(45, 76, 238, var(--tw-text-opacity));
}

.text-j-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(23, 51, 199, var(--tw-text-opacity));
}

.text-j-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 237, var(--tw-text-opacity));
}

.text-j-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 244, 207, var(--tw-text-opacity));
}

.text-j-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(254, 233, 160, var(--tw-text-opacity));
}

.text-j-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(254, 213, 66, var(--tw-text-opacity));
}

.text-j-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(144, 112, 0, var(--tw-text-opacity));
}

.text-hickory-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 9, 9, var(--tw-text-opacity));
}

.text-hickory-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 12, 12, var(--tw-text-opacity));
}

.text-hickory-light {
  --tw-text-opacity: 1;
  color: rgba(110, 45, 45, var(--tw-text-opacity));
}

.text-gingerbread-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 35, 9, var(--tw-text-opacity));
}

.text-gingerbread-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 44, 12, var(--tw-text-opacity));
}

.text-gingerbread-light {
  --tw-text-opacity: 1;
  color: rgba(110, 72, 45, var(--tw-text-opacity));
}

.text-olive-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 72, 9, var(--tw-text-opacity));
}

.text-olive-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 89, 12, var(--tw-text-opacity));
}

.text-olive-light {
  --tw-text-opacity: 1;
  color: rgba(110, 110, 45, var(--tw-text-opacity));
}

.text-cucumber-dark {
  --tw-text-opacity: 1;
  color: rgba(33, 78, 12, var(--tw-text-opacity));
}

.text-cucumber-medium {
  --tw-text-opacity: 1;
  color: rgba(63, 117, 38, var(--tw-text-opacity));
}

.text-cucumber-light {
  --tw-text-opacity: 1;
  color: rgba(85, 143, 58, var(--tw-text-opacity));
}

.text-ocean-dark {
  --tw-text-opacity: 1;
  color: rgba(12, 78, 63, var(--tw-text-opacity));
}

.text-ocean-medium {
  --tw-text-opacity: 1;
  color: rgba(38, 117, 99, var(--tw-text-opacity));
}

.text-ocean-light {
  --tw-text-opacity: 1;
  color: rgba(58, 143, 123, var(--tw-text-opacity));
}

.text-sapphire-dark {
  --tw-text-opacity: 1;
  color: rgba(12, 58, 78, var(--tw-text-opacity));
}

.text-sapphire-medium {
  --tw-text-opacity: 1;
  color: rgba(38, 93, 117, var(--tw-text-opacity));
}

.text-sapphire-light {
  --tw-text-opacity: 1;
  color: rgba(58, 117, 143, var(--tw-text-opacity));
}

.text-navy-dark {
  --tw-text-opacity: 1;
  color: rgba(9, 26, 72, var(--tw-text-opacity));
}

.text-navy-medium {
  --tw-text-opacity: 1;
  color: rgba(12, 29, 89, var(--tw-text-opacity));
}

.text-navy-light {
  --tw-text-opacity: 1;
  color: rgba(45, 59, 110, var(--tw-text-opacity));
}

.text-eggplant-dark {
  --tw-text-opacity: 1;
  color: rgba(21, 12, 78, var(--tw-text-opacity));
}

.text-eggplant-medium {
  --tw-text-opacity: 1;
  color: rgba(49, 38, 117, var(--tw-text-opacity));
}

.text-eggplant-light {
  --tw-text-opacity: 1;
  color: rgba(70, 58, 143, var(--tw-text-opacity));
}

.text-grape-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 9, 72, var(--tw-text-opacity));
}

.text-grape-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 12, 89, var(--tw-text-opacity));
}

.text-grape-light {
  --tw-text-opacity: 1;
  color: rgba(110, 45, 110, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-juni-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(1, 144, 227, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-j-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-j-dark-300 {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.hover\:text-juni-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(1, 144, 227, var(--tw-text-opacity));
}

.hover\:text-juni-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.hover\:text-j-dark-100:hover {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.hover\:text-j-dark-600:hover {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.hover\:text-j-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.hover\:text-j-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(98, 85, 176, var(--tw-text-opacity));
}

.hover\:text-j-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(45, 76, 238, var(--tw-text-opacity));
}

.active\:text-j-dark-700:active {
  --tw-text-opacity: 1;
  color: rgba(37, 35, 80, var(--tw-text-opacity));
}

.active\:text-j-purple-500:active {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 192, var(--tw-text-opacity));
}

.active\:text-j-green-700:active {
  --tw-text-opacity: 1;
  color: rgba(3, 120, 47, var(--tw-text-opacity));
}

.disabled\:text-white:disabled {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.disabled\:text-blue-gray-200:disabled {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity));
}

.disabled\:text-j-dark-300:disabled {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.text-opacity-70 {
  --tw-text-opacity: 0.7;
}

.disabled\:text-opacity-70:disabled {
  --tw-text-opacity: 0.7;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ordinal {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.ordinal {
  --tw-ordinal: ordinal;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0em;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.w-0 {
  width: 0px;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-18 {
  width: 4.5rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1\.5 {
  width: 0.375rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: -webkit-min-content;
  width: min-content;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-1003 {
  z-index: 1003;
}

.z-1004 {
  z-index: 1004;
}

.gap-0 {
  gap: 0px;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-0\.5 {
  gap: 0.125rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-x-2 {
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.gap-x-4 {
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.gap-x-6 {
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.gap-x-8 {
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-500 {
  --tw-scale-x: 5;
  --tw-scale-y: 5;
}

.scale-700 {
  --tw-scale-x: 7;
  --tw-scale-y: 7;
}

.rotate-180 {
  --tw-rotate: 180deg;
}

.-rotate-12 {
  --tw-rotate: -12deg;
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.translate-y-0 {
  --tw-translate-y: 0px;
}

.translate-y-2 {
  --tw-translate-y: 0.5rem;
}

.translate-y-0\.5 {
  --tw-translate-y: 0.125rem;
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
}

.translate-y-3\/4 {
  --tw-translate-y: 75%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@-webkit-keyframes loader {
  0% {
    background-position: 0 0;
    opacity: 1;
  }

  100% {
    background-position: -80% 0;
    opacity: 1;
  }
}

@keyframes loader {
  0% {
    background-position: 0 0;
    opacity: 1;
  }

  100% {
    background-position: -80% 0;
    opacity: 1;
  }
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

.animate-loader {
  -webkit-animation: loader 5s ease normal forwards;
          animation: loader 5s ease normal forwards;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

@media (min-width: 350px) {
}

@media (min-width: 475px) {
  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:items-center {
    align-items: center;
  }

  .xs\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xs\:mt-0 {
    margin-top: 0px;
  }

  .xs\:ml-auto {
    margin-left: auto;
  }

  .xs\:w-96 {
    width: 24rem;
  }
}

@media (min-width: 640px) {
  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-j-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 237, 244, var(--tw-bg-opacity));
  }

  .sm\:bg-j-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 153, 208, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-j-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
  }

  .sm\:border-j-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(203, 218, 231, var(--tw-border-opacity));
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:font-medium {
    font-weight: 500;
  }

  .sm\:h-28 {
    height: 7rem;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:mb-0 {
    margin-bottom: 0px;
  }

  .sm\:ml-0 {
    margin-left: 0px;
  }

  .sm\:mt-1 {
    margin-top: 0.25rem;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:ml-2 {
    margin-left: 0.5rem;
  }

  .sm\:mt-3 {
    margin-top: 0.75rem;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:-mt-36 {
    margin-top: -9rem;
  }

  .sm\:-mt-64 {
    margin-top: -16rem;
  }

  .sm\:max-w-screen-xs {
    max-width: 475px;
  }

  .sm\:max-w-screen-sm {
    max-width: 640px;
  }

  .sm\:p-0 {
    padding: 0px;
  }

  .sm\:p-1 {
    padding: 0.25rem;
  }

  .sm\:p-2 {
    padding: 0.5rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0px;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:pb-28 {
    padding-bottom: 7rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:text-j-dark-400 {
    --tw-text-opacity: 1;
    color: rgba(95, 92, 138, var(--tw-text-opacity));
  }

  .sm\:text-j-dark-600 {
    --tw-text-opacity: 1;
    color: rgba(41, 37, 99, var(--tw-text-opacity));
  }

  .sm\:text-j-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(239, 238, 247, var(--tw-text-opacity));
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:w-44 {
    width: 11rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-1\/3 {
    width: 33.333333%;
  }

  .sm\:w-2\/3 {
    width: 66.666667%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-2\/6 {
    width: 33.333333%;
  }

  .sm\:w-5\/6 {
    width: 83.333333%;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
}

@media (min-width: 768px) {
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:rounded-lg {
    border-radius: 0.5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-baseline {
    align-items: baseline;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .md\:leading-10 {
    line-height: 2.5rem;
  }

  .md\:leading-normal {
    line-height: 1.5;
  }

  .md\:m-0 {
    margin: 0px;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:-mt-40 {
    margin-top: -10rem;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:p-3 {
    padding: 0.75rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pb-0 {
    padding-bottom: 0px;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pl-20 {
    padding-left: 5rem;
  }

  .md\:static {
    position: static;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:top-0 {
    top: 0px;
  }

  .md\:left-0 {
    left: 0px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-2\/3 {
    width: 66.666667%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }
}

@media (min-width: 1024px) {
  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:bg-top {
    background-position: top;
  }

  .lg\:border-r-0 {
    border-right-width: 0px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:flex-1 {
    flex: 1 1 0%;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:m-0 {
    margin: 0px;
  }

  .lg\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:mr-0 {
    margin-right: 0px;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:ml-3 {
    margin-left: 0.75rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:max-h-80 {
    max-height: 20rem;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }

  .lg\:p-0 {
    padding: 0px;
  }

  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pl-40 {
    padding-left: 10rem;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:static {
    position: static;
  }

  .lg\:left-0 {
    left: 0px;
  }

  .lg\:-top-20 {
    top: -5rem;
  }

  .lg\:-top-40 {
    top: -10rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.333333%;
  }

  .lg\:w-2\/3 {
    width: 66.666667%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:gap-3 {
    gap: 0.75rem;
  }

  .lg\:gap-y-0 {
    row-gap: 0px;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0px;
  }
}

@media (min-width: 1280px) {
  .xl\:flex {
    display: flex;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xl\:mx-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .xl\:px-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:gap-x-8 {
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
}

@media (min-width: 1536px) {
  .\32xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
