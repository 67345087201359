@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:600);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,400i,600,600i,700,800,900&display=swap);
.icon {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  padding: 8px;
}

.icon > svg {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

.icon.icon-small {
  width: 8px;
  height: 8px;
  padding: 2px;
}
.icon.icon-small > svg {
  width: 8px;
  height: 8px;
}

.icon.icon-medium {
  width: 14px;
  height: 14px;
}

.icon.icon-large {
  width: 20px;
  height: 20px;
}

.icon.icon-huge {
  width: 160px;
  height: 160px;
  border-radius: 160px;
  margin-bottom: 24px;
}
.icon.icon-huge > svg {
  width: 96px;
  height: 96px;
}

.icon.icon-xlarge {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-bottom: 24px;
}
.icon.icon-xlarge > svg {
  width: 40px;
  height: 40px;
}

.icon-white {
  background: white;
  border: 1px solid hsl(208, 36%, 87%);
}

.icon-red {
  background: hsl(0, 72%, 49%);
}

.icon-green {
  background: rgb(32, 177, 3);
}

.icon-blue {
  background: hsl(208, 100%, 53%);
}

.icon-lightblue {
  background: hsl(208, 100%, 62%);
}
.icon-remove.icon-lightblue {
  background: rgba(0, 165, 255, 0.15);
}

.icon-lightblue polyline {
  stroke: white !important;
}

.icon-lightblue-bg {
  background: hsl(208, 100%, 62%);
}

.icon-lighterblue {
  background: rgb(241, 249, 255);
}

.icon-midblue {
  background: hsl(208, 100%, 92%);
}

.icon-green-line polyline {
  stroke: rgb(31, 191, 94);
}

.icon-blue-line path,
.icon-blue-line rect {
  fill: hsl(208, 100%, 53%);
}

.icon-red-line polyline {
  stroke: hsl(0, 72%, 49%);
}

.icon-red-line path {
  stroke: hsl(0, 72%, 49%);
}
.icon-red-fill path {
  fill: hsl(0, 72%, 49%);
}

.icon-blue-line polyline {
  stroke: hsl(208, 100%, 53%);
}

.icon-blue-line-alt path {
  stroke: hsl(208, 100%, 53%);
}

.icon-white-line path {
  fill: white;
}

.icon-white-stroke path {
  stroke: white;
}

.icon-white-line polyline {
  stroke: white;
}

.icon-blue-border {
  border: 4px solid hsl(208, 100%, 53%);
}

.icon.chevron polyline {
  stroke: hsl(208, 100%, 53%);
}

.icon.chevron.juni-blue-line polyline {
  stroke: hsl(201, 100%, 50%);
}

.icon.chevron.blue-gray-line polyline {
  stroke: hsl(201, 24%, 40%);
}

.icon.arrow-icon.right {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.icon.arrow-icon.up {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.icon.arrow-icon.down {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.icon.wide-arrow-icon.left {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.icon.wide-arrow-icon.up {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.icon.wide-arrow-icon.right {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.icon.chevron.right,
.icon.caret-icon.right,
.icon.tall-chevron.down {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.icon.chevron.left,
.icon.caret-icon.left,
.icon.tall-chevron.up {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.icon.chevron.flipped,
.icon.caret-icon.flipped,
.icon.tall-chevron.left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.icon.icon-logo {
  width: 65px;
  height: auto;
  display: flex;
}

.icon.icon-logo.logo-large {
  width: 118px;
  height: 50px;
}

.icon-logo path,
.icon-logo rect,
.icon-logo-mini path,
.icon-logo-mini rect {
  fill: white;
}

.icon.icon-logo-mini {
  width: 36px;
  height: 36px;
  background: transparent;
}

.logo-blue path,
.logo-blue rect {
  fill: hsl(208, 100%, 53%);
}

.learnerlogin input,
.learnerlogin textarea {
  z-index: 123;
  border: 1px solid #dee5e9;
  background: white;
  font-family: "Graphik", "Nunito Sans", "Open Sans", sans-serif;
  font-size: 12px;
  padding: 10px;
  height: initial;
  flex: 1 1;
  border: 1px solid #e8e8f1;
  background: white;
  z-index: 1;
  padding: 1rem;
  color: #320e78;
}

.learnerlogin input::-webkit-input-placeholder, .learnerlogin textarea::-webkit-input-placeholder {
  color: #648194;
  font-size: 14px;
  opacity: 0.5;
  font-style: italic;
}

.learnerlogin input::placeholder,
.learnerlogin textarea::placeholder {
  color: #648194;
  font-size: 14px;
  opacity: 0.5;
  font-style: italic;
}

.learnerlogin form.inline input {
  background: rgba(255, 255, 255, 0.0392157);
  border: 2px solid #4a149c;
  border-right: 0;
  padding: 0 1rem;
}

.learnerlogin form.inline input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
}

.learnerlogin form.inline input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
}

.learnerlogin #auth #auth-content-wrap #auth-content form > .form-error-wrap {
  margin-bottom: -1.5rem;
}

.learnerlogin #auth #auth-content-wrap #auth-content form .form-field {
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  flex: 1 1;
  align-items: stretch;
  border-radius: 3px;
  overflow: hidden;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  form
  .form-field.error
  input {
  background: #ffe4e4;
  border: 2px solid #ff3333;
  font-weight: 800;
  color: #e01a1a;
}

.learnerlogin #auth #auth-content-wrap #auth-content form .form-field input {
  border: 0;
  width: 100%;
  background: #f0f7fb;
}

.juni-spinner-wrapper {
  display: flex;
  flex: 1 1;
  align-items: center !important;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}

.juni-spinner-circles-container div {
  position: absolute;
  border-radius: 50%;
  top: 60px;
  left: 20px;
}

.juni-spinner-circles-container div:nth-child(1) {
  background: #6255B0;
  -webkit-animation: orbit-1 1s linear infinite;
          animation: orbit-1 1s linear infinite;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.juni-spinner-circles-container div:nth-child(2) {
  background: #292563;
  -webkit-animation: orbit-1 1s linear infinite;
          animation: orbit-1 1s linear infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.juni-spinner-circles-container div:nth-child(3) {
  background: #6255B0;
  -webkit-animation: orbit-2 1s linear infinite;
          animation: orbit-2 1s linear infinite;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.juni-spinner {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.juni-spinner-circles-container {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.juni-spinner-circles-container div { box-sizing: content-box; }

@-webkit-keyframes orbit-2 {
  0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
 49.99% { opacity: 1; -webkit-transform: translate(80%,0); transform: translate(80%,0) }
 50%    { opacity: 0; -webkit-transform: translate(80%,0); transform: translate(80%,0) }
100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}

@keyframes orbit-2 {
  0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
 49.99% { opacity: 1; -webkit-transform: translate(80%,0); transform: translate(80%,0) }
 50%    { opacity: 0; -webkit-transform: translate(80%,0); transform: translate(80%,0) }
100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@-webkit-keyframes orbit-1 {
  0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
 50% { -webkit-transform: translate(80%,0); transform: translate(80%,0) }
100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@keyframes orbit-1 {
  0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
 50% { -webkit-transform: translate(80%,0); transform: translate(80%,0) }
100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}

.layout-container {
  margin: 0px;
  padding: 36px 16px;
  align-self: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.layout-container.take-full-height {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1 1;
}

.layout-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.layout-container.take-full-height .layout-content {
  flex: 1 1;
}

@media only screen and (min-width: 480px) {
  .layout-container {
    padding: 36px 25px;
  }
}

@media only screen and (min-width: 720px) {
  .layout-container {
    padding: 36px 36px;
  }
}

@media only screen and (min-width: 1500px) {
  .layout-container {
    padding: 36px;
  }
  .layout-content {
    max-width: 1400px;
    margin: 0 auto;
  }
  .layout-container.take-full-height .layout-content {
    width: 100%;
  }
}

.coppa-direct-notice {
  background: transparent;
}
.coppa-direct-notice h1,
.coppa-direct-notice h2,
.coppa-direct-notice p,
.coppa-direct-notice li {
  color: inherit;
}
.coppa-direct-notice h1 {
  font-size: 1.25em;
}
.coppa-direct-notice h2 {
  font-size: 1.1em;
  text-decoration: underline;
}
.coppa-direct-notice p,
.coppa-direct-notice li {
  line-height: 1.8em;
}
.coppa-direct-notice a {
  text-decoration: underline;
}

.spinner-v2-wrapper {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.spinner-v2 {
  margin: 8px;
  position: relative;
  width: 80px;
  height: 80px;
}
button .spinner-v2,
.button .spinner-v2,
.submit-button .spinner-v2 {
  width: 24px;
  height: 24px;
}
.spinner-v2 .spinner-dot-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.spinner-v2 .spinner-dot-container > div:first-child {
  display: flex;
  flex: 15 1;
}
.spinner-v2 .spinner-dot-container > div:last-child {
  flex: 85 1;
}
.spinner-v2 .spinner-dot-container .spinner-dot {
  background-color: #ccc;
  display: block;
  flex: 30 1;
  border-radius: 50%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.spinner-v2 .spinner-dot-container .spinner-dot-left,
.spinner-v2 .spinner-dot-container .spinner-dot-right {
  flex: 85 1;
}
.spinner-v2 .spinner-dot-container-1 {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.spinner-v2 .spinner-dot-container-2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.spinner-v2 .spinner-dot-container-3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.spinner-v2 .spinner-dot-container-4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.spinner-v2 .spinner-dot-container-5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.spinner-v2 .spinner-dot-container-6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.spinner-v2 .spinner-dot-container-7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.spinner-v2 .spinner-dot-container-8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.spinner-v2 .spinner-dot-container-9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.spinner-v2 .spinner-dot-container-10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.spinner-v2 .spinner-dot-container-11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.spinner-v2 .spinner-dot-container-12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.spinner-v2 .spinner-dot-container-1 .spinner-dot {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}
.spinner-v2 .spinner-dot-container-2 .spinner-dot {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner-v2 .spinner-dot-container-3 .spinner-dot {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner-v2 .spinner-dot-container-4 .spinner-dot {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner-v2 .spinner-dot-container-5 .spinner-dot {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.spinner-v2 .spinner-dot-container-6 .spinner-dot {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.spinner-v2 .spinner-dot-container-7 .spinner-dot {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.spinner-v2 .spinner-dot-container-8 .spinner-dot {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.spinner-v2 .spinner-dot-container-9 .spinner-dot {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.spinner-v2 .spinner-dot-container-10 .spinner-dot {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.spinner-v2 .spinner-dot-container-11 .spinner-dot {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.spinner-v2 .spinner-dot-container-12 .spinner-dot {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.ui-field {
  display: block;
}

.ui-field > * + * {
  margin-top: 0.5rem;
}

.ui-field-label {
  font-weight: bold;
}

.ui-field-error {
  font-size: 0.9em;
  color: #c13a53;
}

.ui-input {
  background: #fbfdff;
  border: 1px solid hsl(201, 70%, 50%);
  border-radius: 0.5rem;
  box-shadow: inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
  font-family: inherit;
  font-size: 1rem;
  line-height: 1;
  box-sizing: border-box;
  padding: 1rem;
  height: 3rem;
  width: 100%;
}

.ui-input:focus {
  border: 2px solid #00a5ff;
  padding-left: calc(1rem - 1px);
  box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.2),
    inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
}

.ui-input:disabled {
  background: #e7eaec;
  border: 2px solid #adc2ce;
  color: #b7c0c7;
}

.ui-input.ui-input-error {
  background: #ffffff;
  border-color: #c13a53;
  color: #a73434;
}

.ui-input::-webkit-input-placeholder {
  color: #adc2ce;
}

.ui-input::placeholder {
  color: #adc2ce;
}

.ui-input.ui-input-error::-webkit-input-placeholder {
  color: #ee808c;
}

.ui-input.ui-input-error::placeholder {
  color: #ee808c;
}

.ui-button-row {
  margin: 0rem -0.5rem -0.5rem -0.5rem;
}

.ui-button-row * {
  margin: 0.5rem;
}

.ui-button-row.centered {
  text-align: center;
}

.ui-pre-wrap {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

/* UI Content styles reflect style guide */

/**
 * Fonts
 */

.ui-content {
  font-family: "Graphik", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #292563;
  line-height: 1.5;
}

.ui-content h1,
.ui-content h2,
.ui-content h3,
.ui-content h4,
.ui-content p,
.ui-admin-content h1,
.ui-admin-content h2,
.ui-admin-content h3,
.ui-admin-content h4,
.ui-admin-content p,
.learner-app .ui-content p {
  margin: 0;
}

.ui-content h1,
.ui-admin-content h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
}

.ui-content h2,
.ui-admin-content h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}

.ui-content h3,
.ui-admin-content h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.ui-content h4,
.ui-admin-content h4 {
  font-weight: 500;
  color: #a4b2fd;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.ui-content .ui-large-text {
  font-size: 1.5em;
}

.ui-content .ui-small-text {
  font-size: 0.75em;
  color: #5f798c;
}

.ui-content a {
  color: #4865fc;
}

.ui-admin-content {
  font-family: "Graphik", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #292563;
  letter-spacing: 0.02em;
  line-height: 1.5;
}

/**
 * Layout
 */

.ui-row {
  display: flex;
  flex-direction: row;
}

/**
 * Cards
 */

.ui-card-base {
  box-shadow: 0px 0.6rem 1rem rgba(209, 231, 239, 0.4);
  border-radius: 0.25rem;
}

/**
 * Spaced Content
 */
.ui-vertical-spacing > * + *,
.ui-content .ui-vertical-spacing > * + *,
.ui-content.ui-vertical-spacing > * + *,
.ui-admin-content .ui-vertical-spacing > * + *,
.learner-app .ui-content .ui-vertical-spacing > * + * {
  margin-top: 1rem;
}
.ui-vertical-spacing-small > * + *,
.ui-content .ui-vertical-spacing-small > * + *,
.ui-admin-content .ui-vertical-spacing-small > * + *,
.learner-app .ui-content .ui-vertical-spacing-small > * + * {
  margin-top: 0.5rem;
}

.ui-horizontal-spacing > * + *,
.ui-content .ui-horizontal-spacing > * + *,
.ui-admin-content .ui-horizontal-spacing > * + *,
.learner-app .ui-content .ui-horizontal-spacing > * + * {
  margin-left: 1rem;
}

.ui-content .ui-no-top-margin,
.ui-admin-content .ui-no-top-margin,
.learner-app .ui-content .ui-no-top-margin {
  margin-top: 0;
}

.ui-grid {
  margin: -0.5rem;
}

.ui-grid * {
  margin: 0.5rem;
}

.ui-grid-small {
  margin: -0.25rem;
}

.ui-grid-small * {
  margin: 0.25rem;
}

.ui-accordion .ui-accordion-title {
  cursor: pointer;
  font-size: 1.2em;
}

.ui-accordion .ui-accordion-title.ui-active {
  font-weight: bold;
}

.ui-accordion .ui-accordion-title.ui-disabled {
  color: #aaa;
  cursor: inherit;
}

.ui-accordion .ui-accordion-section {
  border-top: 1px solid #ccc;
  padding-top: 1rem;
  margin-top: 1rem;
}

.ui-accordion .ui-accordion-section:first-of-type {
  border-top: none;
}

/* We need disabled too to override global styles from button... */
.Button_button__3Ooan,
.Button_button__3Ooan:disabled {
  color: #fff;
  font-size: inherit;
  font-weight: bold;
  background-color: hsl(201, 100%, 50%);
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border: 2px solid rgba(255, 255, 255, 0.15);
  letter-spacing: 0.05em;
  transition: 0.1s;
  text-transform: none;
  font-family: inherit;
  line-height: 1.5rem;
}

.Button_button__3Ooan.Button_withIcon__uYayC {
  padding-right: 4.5rem;
  position: relative;
}

.Button_icon__3pFhU {
  position: absolute;
  top: 50%;
  right: 0.5em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 1.5em;
  width: 1.5em;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button_button__3Ooan.Button_secondary__3vAYS {
  background-color: hsl(201, 100%, 21%);
}

.Button_button__3Ooan.Button_tertiary__3G89W {
  background-color: #fff;
  color: #00a5ff;
  border: 1px solid #d1eaf7;
}

.Button_button__3Ooan.Button_tertiary__3G89W .Button_icon__3pFhU {
  background: rgba(0, 165, 255, 0.15);
}

.Button_button__3Ooan:disabled {
  opacity: 0.7;
}

.Button_button__3Ooan.Button_small__2gkED {
  padding: 0.3rem 0.5rem;
  font-size: 0.7em;
  line-height: 1.2;
  border-radius: 0.1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.Button_button__3Ooan.Button_link__14OU1 {
  background-color: transparent;
  color: #00a5ff;
  padding: 0;
  box-shadow: none;
  border: none;
  text-transform: uppercase;
}

.Button_button__3Ooan.Button_link__14OU1.Button_withIcon__uYayC {
  padding-right: 2rem;
}

.Button_button__3Ooan.Button_small__2gkED.Button_link__14OU1 {
  font-size: 0.9em;
}

.Button_button__3Ooan:hover:not(:disabled) {
  background-color: hsl(201, 100%, 60%);
}

.Button_button__3Ooan.Button_secondary__3vAYS:hover:not(:disabled) {
  background-color: hsl(201, 100%, 31%);
}

.Button_button__3Ooan.Button_tertiary__3G89W:hover:not(:disabled) {
  background: #fff;
  box-shadow: 0px 4px 6px rgba(11, 45, 64, 0.08);
}

.Button_button__3Ooan.Button_tertiary__3G89W:active,
.Button_button__3Ooan.Button_tertiary__3G89W:active:hover,
.Button_button__3Ooan.Button_tertiary__3G89W.Button_active__2cTp2 {
  border: 1px solid #00a5ff;
  box-shadow: 0px 0px 2px #00a5ff;
}

.Button_button__3Ooan.Button_tertiary__3G89W:active:hover:not(:disabled),
.Button_button__3Ooan.Button_tertiary__3G89W.Button_active__2cTp2:hover:not(:disabled) {
  box-shadow: 0px 0px 3px #00a5ff;
}

.Button_button__3Ooan.Button_link__14OU1:hover:not(:disabled) {
  background-color: transparent;
  color: hsl(201, 100%, 60%);
  text-decoration: underline;
}

.Card_card__2Zp3a {
  background: #f8fdff;
  border: 1px solid #e0f2ff;
}

.Card_card__2Zp3a.Card_whiteCard__3RbzE {
  background: #fff;
}

.Card_card__2Zp3a > * {
  padding: 2rem;
}

.Card_card__2Zp3a.Card_narrowPadding__3nwBx > * {
  padding: 1rem;
}

.Card_card__2Zp3a.Card_noPadding__KXVB8 > * {
  padding: 0;
}

.Card_cardHeader__1nhRR > * + *,
.Card_cardFooter__2qM_Q > * + *,
.Card_cardContent__2uJeq > * + * {
  margin-top: 1rem;
}

.Card_cardHeader__1nhRR {
  background: #fbfeff;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: 1px solid #e0f2ff;
}

.Card_cardFooter__2qM_Q {
  background: #fbfeff;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: 1px solid #e0f2ff;
}

.Card_addBoxShadow__1k1MW {
  box-shadow: 0px 10px 30px hsl(208, 46%, 78%);
}

.ui-layout {
  padding: 1rem;
}

.ui-layout.ui-full-height-layout {
  flex: 1 1;
  display: flex;
}

.ui-layout-content {
  min-width: 35rem;
}

.ui-layout-content-no-min-width {
  min-width: 0;
}

.ui-layout .ui-full-height-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.ui-layout .ui-cap-width {
  width: 100%;
  margin: 0 auto;
}

.ui-layout .ui-cap-width.ui-cap-width-medium {
  max-width: 60rem;
}

.ui-layout .ui-cap-width.ui-cap-width-large {
  max-width: 75rem;
}

.ui-layout .ui-cap-width.ui-cap-width-x-large {
  max-width: 90rem;
}

.ui-layout .ui-cap-width.ui-cap-width-xx-large {
  max-width: 120rem;
}

.ui-layout > .ui-layout-title {
  color: #000;
  font-size: 1.7rem;
}

.spinner-wrapper {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.sk-fading-circle {
  margin: 8px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  height: 80px;
}

button .sk-fading-circle,
.button .sk-fading-circle,
.submit-button .sk-fading-circle {
  width: 24px;
  height: 24px;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #ccc;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.Message_message__3GZX9 {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid rgba(255, 255, 255.75);
  font-weight: bold;
}

.Message_message__3GZX9.Message_success__3eD9l {
  background-color: #e8ffe6;
  color: rgb(16, 63, 13);
}

.Message_message__3GZX9.Message_warning__CIINw {
  background-color: #fff5e2;
  color: rgb(88, 65, 20);
}

.Message_message__3GZX9.Message_error__2cxh7 {
  background-color: #ffeded;
  color: rgb(187, 34, 34);
}

.Message_message__3GZX9.Message_info__kDPkH {
  background-color: #f5f5f5;
  color: #6e6e6e;
}

.Message_message__3GZX9.Message_withIcon__3vbx1 {
  padding-left: 3.5rem;
  position: relative;
}

.Message_icon__3n61S {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0.5rem;
  border-radius: 0.5rem;
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  padding: 0.5rem;
}

.Message_message__3GZX9.Message_success__3eD9l .Message_icon__3n61S {
  background-color: rgba(16, 63, 13, 0.05);
}

.Message_message__3GZX9.Message_warning__CIINw .Message_icon__3n61S {
  background-color: rgba(88, 65, 20, 0.05);
}

.Message_message__3GZX9.Message_error__2cxh7 .Message_icon__3n61S {
  background-color: rgba(187, 34, 34, 0.05);
}

.ui-page-error {
  padding: 2rem 1rem;
  margin: 0 auto;
  font-size: 1rem;
  width: 50%;
}

.ui-page-error .ui-content {
  font-size: 1.2em;
}

/* TREE STYLING */

.ui-tree .ui-tree-subtree {
  margin: 0;
  padding: 0;
}

.ui-tree .ui-tree-subtree > li {
  list-style-type: none;
  display: flex;
  align-items: stretch;
}

.ui-tree .ui-tree-lines {
  display: flex;
  flex-direction: column;
}

.ui-tree .ui-tree-lines::before,
.ui-tree .ui-tree-lines::after {
  content: '';
  display: block;
  margin-left: 6px;

  border-left: 1px solid #afcfda;
}

.ui-tree .ui-tree-lines::before {
  border-bottom: 1px solid #afcfda;
  height: 7px;
  width: 16px;
}

.ui-tree .ui-tree-lines::after {
  flex-grow: 1;
}

.ui-tree li:last-of-type > .ui-tree-lines::after {
  display: none;
}

/* TREE NODE STYLING */
.ui-tree .ui-tree-node {
  display: flex;
  flex-direction: column;
}

.ui-tree .ui-tree-label {
  display: flex;
}

.ui-tree .ui-tree-bullet-container {
  display: flex;
  flex-direction: column;
}

.ui-tree .ui-tree-bullet {
  height: 14px;
  width: 14px;
  border: 1px solid #afcfda;
  box-sizing: border-box;
  border-radius: 1px;

  display: flex;
  align-items: center;

  cursor: pointer;
}

.ui-tree .ui-tree-bullet.ui-opened {
  background: #3f6876;
}

.ui-tree .ui-tree-bullet svg {
  display: block;
  margin: 0 auto;
}

.ui-tree .ui-tree-bullet.ui-collapsed path {
  fill: #3f6876;
}

.ui-tree .ui-tree-bullet.ui-opened path {
  fill: #fff;
}

.ui-tree .ui-tree-bullet-container::after {
  flex-grow: 1;

  content: '';
  display: block;
  width: 7px;
  box-sizing: border-box;
  border-right: 1px solid #afcfda;
}

.ui-tree .ui-tree-node-end .ui-tree-bullet-container::after {
  display: none;
}

.ui-tree .ui-tree-node-link {
  margin-left: 20px;
  margin-bottom: 20px;
}

.ui-tree .ui-tree-node-link a {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.12em;
  color: #245364;
  text-decoration: none;
  vertical-align: top;
}

.ui-tree .ui-tree-node-link a.ui-tree-active {
  font-weight: bold;
}

.Tooltip_tooltip__tCIgw {
  position: relative;
}

.Tooltip_tooltip__tCIgw .Tooltip_tooltipContent__HLN6H {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 10px 40px -10px hsl(208, 46%, 78%);
  padding: 1em;
  width: 200px;
  z-index: 999;
}

.Tooltip_tooltip__tCIgw .Tooltip_tooltipContent__HLN6H.Tooltip_right__Kok3b {
  top: 0;
  left: 100%;
}

.Tooltip_tooltip__tCIgw .Tooltip_tooltipContent__HLN6H.Tooltip_below__1OVb7 {
  top: 100%;
  left: 0;
}

.Tooltip_tooltip__tCIgw:hover .Tooltip_tooltipContent__HLN6H {
  display: block;
}

.Statistic_statistic__Vm0-P > .Statistic_figure__1veBA {
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 1px;
}

.Statistic_statistic__Vm0-P > .Statistic_label__1jEHL {
  line-height: 1em;
}

.Tabs_tabHeader__1CfNe {
  display: flex;
  flex-direction: row;
}

.Tabs_tabHeader__1CfNe > * {
  flex: 1 1;
}

.ui-form-group {
  margin-top: 1rem;
}

.ui-form-group .ui-title {
  font-weight: bold;
  font-size: 1.2em;
}

.CheckedGroup_checkedGroup__39XAi {
  border: none;
  padding: 0;
  margin: 0;
}

.ui-checked-field {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ui-checked-field .ui-checked-label {
  margin-left: 0.5rem;
  margin-top: 0;
}

.ui-checked-field.ui-disabled .ui-checked-label {
  color: #999;
}

.ui-radio-group {
  border: none;
  padding: 0;
  margin: 0;
}

.ui-radio-group.ui-has-label {
  margin-top: 1rem;
}

/* Awkward CSS rule to make am/pm fit */
.DatePickerField_timePicker__tVe1c div.react-datepicker__time-box {
  width: 100px !important;
}

.DatePickerField_timePicker__tVe1c .react-datepicker__time-container {
  width: 100px;
}

.DatePickerField_fluidWidth__33Dcp .react-datepicker-wrapper {
  width: 100%;
}

.DatePickerField_fluidWidth__33Dcp .react-datepicker__input-container {
  width: 100%;
}

.ui-text-area {
  background: #fbfdff;
  border: 1px solid hsl(201, 70%, 50%);
  border-radius: 0.5rem;
  box-shadow: inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
  font-family: inherit;
  font-size: 1rem;
  line-height: 1;
  box-sizing: border-box;
  padding: 1rem;
  height: 5rem;
  width: 100%;
  resize: vertical;
}

.ui-text-area:focus {
  border: 2px solid #00a5ff;
  padding-left: calc(1rem - 1px);
  box-shadow: 0px 0.25rem 1rem rgba(0, 165, 255, 0.2),
    inset 0px 0.25rem 1rem rgba(41, 95, 124, 0.05);
}

.ui-text-area:disabled {
  background: #e7eaec;
  border: 2px solid #adc2ce;
  color: #b7c0c7;
}

.ui-select-field > select {
  font-size: 1em;
  height: 3em;
  width: 100%;
}

.full-width {
  width: 100%;
}

.ui-editable-date-picker.ui-pop-out {
  position: relative;
}

.ui-editable-date-picker.ui-pop-out .ui-editor {
  position: absolute;
  min-width: 12rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 1rem;
  z-index: 999;
}

.ui-editable-react-select.ui-pop-out {
  position: relative;
}

.ui-editable-react-select.ui-pop-out .ui-editor {
  position: absolute;
  min-width: 15rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 1rem;
  z-index: 999;
}

.ui-sortable-list {
  margin-top: 1rem;
  border: 1px solid #ccc;
  background-color: #eee;
}

.ui-sortable-list-item {
  padding: 1em;
  background-color: #fff;
  cursor: move;
}

.ui-sortable-list-item.ui-dragging {
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px #aaa;
}

.ui-sortable-list .ui-sortable-list-item {
  border: none;
  border-top: 1px solid #ccc;
}

.ui-sortable-list .ui-sortable-list-item:first-of-type {
  border-top: none;
}

@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-Black.c9e42599.otf);
  font-weight: bolder;
}
@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-Bold.f9a5c924.otf);
  font-weight: bold;
}
@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-BookItalic.ad21531a.otf);
  font-weight: light;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-Light.59aa4004.otf);
  font-weight: light;
}
@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-Thin.6447770f.otf);
  font-weight: lighter;
}
@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-ThinItalic.632acb55.otf);
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham-Medium.bcd733e6.otf);
  font-weight: normal;
}
@font-face {
  font-family: "Gotham";
  src: url(/static/media/GothamMediumItalic.b35ab0df.ttf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-SuperItalic.f2150692.otf);
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Super.0972a8c9.otf);
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-BlackItalic.541296f4.otf);
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Black.fa870e66.otf);
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-BoldItalic.13b9b876.otf);
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Bold.6417b640.otf);
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-SemiboldItalic.606f93e5.otf);
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Semibold.59cd93ff.otf);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-MediumItalic.55f9a34d.otf);
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Medium.870f914f.otf);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-RegularItalic.1bb44f45.otf);
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Regular.2552527e.otf);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-LightItalic.2a0f75a6.otf);
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Light.1bff1621.otf);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-ExtralightItalic.d6878f2d.otf);
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Extralight.f0f07053.otf);
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-ThinItalic.6951dd75.otf);
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(/static/media/Graphik-Thin.927995dd.otf);
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-Black.bf6aa8bd.ttf);
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-BlackItalic.755bd5ae.ttf);
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-Bold.bada4fe1.ttf);
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-BoldItalic.9f9b1eb2.ttf);
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-SemiBold.b27cb117.ttf);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-SemiBoldItalic.1266f2c1.ttf);
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-Regular.6f22301c.ttf);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-Italic.90931d6a.ttf);
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-Light.3dea7027.ttf);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-LightItalic.3747e1d8.ttf);
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-ExtraLight.8d03a055.ttf);
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(/static/media/SourceSerifPro-ExtraLightItalic.e4631085.ttf);
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  color: hsl(208, 14%, 24%);
  text-rendering: optimizeLegibility;
  background-color: #eff4fb;
  position: relative;
}

#root {
  min-height: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#root > div:first-child {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#root > div.juni-spinner-wrapper:first-child {
  align-items: center;
}

a {
  color: #333;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}

th,
td {
  padding: 5px 15px;
  vertical-align: top;
}

.clear {
  clear: both;
}

.header-tag-v2 {
  font-size: 10px;
  border-radius: 24px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border: 2px solid;
  box-sizing: border-box;
  min-height: 30px;
}

.header-tag-v2 > span {
  display: none;
}

.header-tag-v2.with-right-icon .icon {
  width: 10px;
  height: 10px;
}

.header-tag-v2.incomplete {
  background: hsla(0, 72%, 49%, 0.1);
  color: hsl(0, 72%, 49%);
  border: 2px solid hsl(0, 72%, 49%);
}

.header-tag-v2.complete {
  background: rgba(31, 191, 94, 0.1);
  color: rgb(31, 191, 94);
  border: 2px solid rgb(31, 191, 94);
}

.header-tag-v2.missed,
.header-tag-v2.upcoming {
  background: hsla(208, 100%, 53%, 0.1);
  color: hsl(208, 100%, 53%);
  border: 2px solid hsl(208, 100%, 53%);
}

.header-tag {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 10px;
  background: rgb(46, 203, 93);
  color: #fff;
  border-radius: 2px;
  padding: 0px 8px;
  font-weight: 300;
  line-height: 22px;
}

.header-tag-red {
  background: hsl(0, 72%, 49%);
}

.header-tag-blue {
  background-color: hsl(208, 100%, 53%);
}

.page-error {
  margin: 30px auto;
  text-align: center;
  font-size: 30px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  display: none;
  width: 250px;
  padding: 10px;
  border: 1px solid #333;
  background: #fff;
  font-size: 12px;
  position: absolute;
  z-index: 100;
  top: 25px;
  left: -10px;
}

.tooltip-container:hover .tooltip {
  display: inline-block;
}

.badge {
  font-weight: 400;
  white-space: nowrap;
  color: #fff;
  padding: 10px 12px;
  border: 0px;
  font-size: 12px;
  margin: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Gotham", sans-serif;
}

button,
.button {
  color: #fff;
  background-color: hsl(208, 100%, 53%);
  padding: 5px 8px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  margin: 0px;
  text-transform: uppercase;
  transition: 0.2s;
}

button:hover:not(.ignore-juni-globals),
.button:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.button {
  margin-top: 20px;
  padding: 10px 20px;
  display: inline-block;
  font-size: 12px;
}

button:disabled:not(.ignore-juni-globals),
.button-disabled {
  background-color: #efefef;
  cursor: default;
  border: 1px solid #efefef;
  color: #ccc;
  box-shadow: none;
}

.button-disabled .icon {
  background: #ccc;
}

.button-disabled:hover {
  box-shadow: none;
}

.card {
  background: #fff;
  margin: 25px;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-weight: 300;
}

.card-v2 {
  background: #fff;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid hsl(208, 36%, 87%);
  border-radius: 4px 0px 4px 4px;
  box-shadow: 0 20px 80px -10px hsl(208, 46%, 78%);
  padding: 20px;
}

.card-v3 {
  background: #fff;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  border-radius: 8px;
  border-radius: var(--radius, 8px);
  box-shadow: 0px 10px 60px -10px #deebf2;
}
.card-v3.padded {
  padding: 1.5rem 1.25rem;
}
@media only screen and (min-width: 480px) {
  .card-v3.padded {
    padding: 1.75rem 1.5rem;
  }
}

.card-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.card-error {
  font-size: 14px;
  color: hsl(0, 72%, 49%);
}

.form-container {
  width: 500px;
  margin: 100px auto;
  text-align: center;
  background: #fff;
  padding: 25px;
  border: 1px solid #ccc;
}

.form-container-wide {
  width: 900px;
}

.form-container-xwide {
  width: 1100px;
}

.form-container-light table {
  font-weight: 300;
  font-size: 13px;
}

.form-container-full-width {
  display: inline-block;
  width: auto !important;
  margin: 100px 50px;
}

.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;
}

.form-subtitle {
  font-size: 18px;
  text-align: left;
  margin: 20px 0px 10px 0px;
}

.form-subtitle-2 {
  font-size: 18px;
  margin: -10px 0px 20px 0px;
  font-weight: 300;
}

.form-description {
  font-size: 12px;
  margin-bottom: 20px;
}

.form-field-description {
  text-align: left;
  font-size: 12px;
  margin: 25px 0px 0px 2px;
}

.form-reset-link {
  font-size: 12px;
  color: #666;
}

.form-secure-payment {
  text-align: left;
  font-size: 12px;
  color: #333;
}

.form-secure-payment img {
  width: 10px;
  margin-right: 8px;
  vertical-align: middle;
  margin-top: -3px;
}

.form-secure-payment-cards {
  float: right;
  display: inline-block;
}

.form-secure-payment-cards img {
  width: 22px;
  margin-right: 0px;
  margin-left: 5px;
}

.form-container input[type="text"],
.form-container input[type="password"],
.form-container textarea,
.stripe-field {
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(46, 60, 73, 0.05);
}

.form-container input.input-narrow {
  width: 78%;
  float: left;
}

.form-select {
  text-align: left;
}

.form-select-label {
  display: inline-block;
  font-size: 12px;
  width: 100px;
  margin-left: 2px;
}

.form-container table {
  font-size: 14px;
  text-align: left;
  margin: auto;
}

.form-container button:not(.react-datepicker__navigation):not(.ui-button) {
  width: 200px;
  padding: 10px 0px;
  font-size: 12px;
  margin-top: 25px;
}

.form-container button.table-link:not(.ui-button) {
  margin: 0px !important;
  width: auto;
  padding: 8px 15px;
  font-size: 10px;
  font-weight: 300;
}

.form-container button.notes-button:not(.ui-button) {
  width: auto;
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
  padding: 2px 5px;
  margin-top: 0px;
  margin-right: 5px;
  font-size: 10px;
}

.form-container button.verify-coupon:not(.ui-button) {
  float: right;
  margin: 5px 0px 0px 0px !important;
  width: auto;
  padding: 5px 8px;
  font-size: 10px;
  border: 1px solid hsl(208, 100%, 53%);
  background-color: transparent;
  color: #333;
}

.form-container .tr-strikethrough {
  text-decoration: line-through;
}

.form-error {
  color: hsl(0, 72%, 49%);
  font-size: 11px;
  text-align: left;
  margin: 10px 0px;
}

.form-coupon-link {
  float: left;
  text-decoration: underline;
  margin-top: 10px;
  font-size: 11px;
  cursor: pointer;
  margin-left: 2px;
  display: inline-block;
}

.form-coupon-link:hover {
  text-decoration: none;
}

.form-tos {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}

.form-tos input[type="checkbox"] {
  margin-right: 5px;
  vertical-align: middle;
}

.react-datepicker-wrapper {
  float: none !important; /* to override DatePicker styles */
}

@media only screen and (min-width: 720px) {
  .card-v2 {
    padding: 30px;
  }
}

@media only screen and (min-width: 560px) {
  .header-tag-v2 {
    padding: 0 16px;
  }
  .header-tag-v2 > span {
    display: initial;
  }

  .header-tag-v2.with-right-icon {
    padding: 0 8px 0 16px;
  }
}

.alert-banner {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #292563;
  color: white;
  transition: all 0.2s;
}

.alert-banner > .icon-container {
  background: #413e75;
  padding: 0;
  height: 100%;
  position: absolute;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-banner.alert-banner-warning {
  background: hsl(58, 94%, 13%);
}
.alert-banner.alert-banner-warning > .icon-container {
  background: hsl(58, 85%, 16%);
}

div.alert-banner-content {
  margin-left: 52px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  align-items: flex-end;
}
.alert-banner-content .alert-banner-text {
  line-height: 1.4em;
  align-self: flex-start;
  font-size: 12px;
}
.alert-banner-content.with-button .alert-banner-text {
  margin-bottom: 16px;
}

.alert-banner.session-alert {
  margin-bottom: 36px;
}

@media only screen and (min-width: 340px) {
  .alert-banner > .icon-container {
    width: 52px;
  }
  div.alert-banner-content {
    margin-left: 52px;
  }
  .alert-banner-content .alert-banner-text {
    font-size: 13px;
  }
}

@media only screen and (min-width: 560px) {
  .alert-banner {
    min-height: 60px;
  }
  .alert-banner > .icon-container {
    width: 60px;
  }
  div.alert-banner-content {
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 16px;
    margin-left: 60px;
  }
  .alert-banner-content .alert-banner-text {
    font-size: 14px;
    align-self: auto;
  }
  .alert-banner-content.with-button .alert-banner-text {
    margin-right: 28px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 900px) {
  .alert-banner.session-alert .alert-banner-content .alert-banner-text {
    font-size: 16px;
  }
}

.jce {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  box-sizing: border-box;
  padding: 0px;

  --jce-bg: rgba(36, 37, 47, 1);
  --jce-bg-light: rgb(48, 51, 63);
  --jce-white: rgba(250, 250, 240, 1);
  --jce-white-faint: rgba(250, 250, 240, 0.05);
  --jce-white-light: rgba(250, 250, 240, 0.3);
}
.jce-bar {
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
  background-color: #0f3a52;
  color: white;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
}
.jce-bar-left {
  display: flex;
  height: 100%;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
}
.jce-bar-right {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
}
.jce-controls {
  display: flex;
  height: 60%;
  width: 80px;
  font-family: "Gotham", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: white;
  padding: 0px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.jce-controls > div {
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.jce-controls.jce-status-button {
  width: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.jce-controls.jce-status-button.jce-modal {
  /* display: none; */
}
.jce-controls.jce-run {
}
.jce-controls.jce-stop {
}
.jce-controls.jce-restore {
  padding: 0 1.15em;
  width: auto;
}
.jce-controls.jce-restore.jce-inactive {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}
.jce-controls.jce-run.jce-active {
  background-color: #3da5ff;
}
.jce-controls.jce-stop.jce-active {
  background-color: #d52224;
}
.jce-controls.jce-run.jce-inactive.jce-in-progress {
  background-color: rgba(61, 165, 255, 0.67);
  cursor: auto;
}
.jce-controls.jce-stop.jce-inactive.jce-in-progress {
  background-color: rgba(213, 34, 37, 0.67);
  cursor: auto;
}
.jce-controls.jce-run.jce-inactive:not(.jce-in-progress) {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}
.jce-controls.jce-stop.jce-inactive:not(.jce-in-progress) {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}

.jce-main {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-grow: 1;
  background-color: #292a36;
}
.jce-file-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.jce-file-viewer .jce-status-button {
  display: none;
  font-size: 40px;
  background-color: rgb(15, 58, 82);
  color: rgb(255, 255, 255, 0.8);
  border-radius: 7px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}
.jce-file-viewer .jce-status-button.jce-modal {
  display: block;
  position: absolute;
  z-index: 100;
  top: 15%;
  width: 120px;
  height: 120px;
}
.jce-ace {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.jce-ace .ace_gutter {
  color: rgba(248, 248, 242, 0.5);
}
.jce-ace.new-horizons {
  padding-top: 15px;
}

.jce-ace .ace-dracula .ace_marker-layer .ace_active-line {
  background: rgba(68, 71, 90, 0.3) !important;
}
.jce-ace .ace-dracula .ace_gutter-active-line {
  background: rgba(68, 71, 90, 0.3) !important;
}
.jce-file-viewer img,
.jce-file-viewer video,
.jce-file-viewer audio {
  max-width: 95%;
  max-height: 95%;
}
.jce-file-viewer.jfv-text {
  max-width: 85%;
  text-align: center;
}

.jce-status-button.jce-hardreset {
  background-color: rgba(97, 34, 213, 0.67) !important;
}
.jce-status-button > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.jce-status-button > div > svg {
  flex-grow: 1;
}
.jce-status-button-text {
  flex-grow: 0;
  margin-top: 5px;
  font-size: 8px;
  height: 30px;
  font-family: "Gotham", sans-serif;
}
.jce-status-button.jce-spinning {
  animation: colorcycle1 1s infinite;
  -moz-animation: colorcycle2 1s infinite;
  -webkit-animation: colorcycle3 1s infinite;
}
@-webkit-keyframes colorcycle1 {
  0% {
    background-color: rgb(255, 255, 255);
  }
  50% {
    background-color: rgb(61, 165, 255);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}
@keyframes colorcycle1 {
  0% {
    background-color: rgb(255, 255, 255);
  }
  50% {
    background-color: rgb(61, 165, 255);
  }
  100% {
    background-color: rgb(255, 255, 255);
  }
}
@-webkit-keyframes colorcycle3 {
  0% {
    background-color: rgba(31, 84, 112, 0.95);
  }
  50% {
    background-color: rgba(42, 109, 167, 0.95);
  }
  100% {
    background-color: rgba(31, 84, 112, 0.95);
  }
}

.jce-si {
  display: flex;
  background-color: var(--jce-bg);
  height: 100%;
  color: var(--jce-white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}
.jce-si div {
  display: flex;
  box-sizing: border-box;
}
.jce-si a {
  text-decoration: none;
}

.jce-si-content {
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow: auto;
}
.jce-si-content-wrapper {
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: auto;
}

.jce-si-title {
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 0.3pt;
}
.jce-si-video {
  max-height: 350px;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
  border-radius: 5px;
}
.jce-si-video video {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}
.jce-si-image {
  max-height: 350px;
  width: auto;
  margin-bottom: 20px;
}
.jce-si-link {
  background-color: var(--jce-white);
  border: 2px solid #0f8fff;
  border-radius: 5px;
  margin-bottom: 20px;
  align-items: center;
}
.jce-si-link-left {
  color: var(--jce-bg);
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
}
.jce-si-link-left:hover {
  color: #0f8fff;
}
.jce-si-link-right {
  cursor: pointer;
  background-color: #0f8fff;
  padding: 8px;
  margin-right: 6px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  color: var(--jce-white);
  opacity: 0.8;
}
.jce-si-link-right:hover {
  opacity: 1;
}
.jce-si-link-right-left {
  font-family: "Gotham", sans-serif;
  font-size: 11px;
  margin-right: 5px;
  letter-spacing: 0.3pt;
  justify-content: center;
}
.jce-si-link-right-right {
  justify-content: center;
  width: 14px;
  font-size: 12px;
}
.jce-si-text {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  padding: 20px;
  margin-bottom: 10px;
}

.jce-si-nav {
  background-color: var(--jce-bg-light);
  color: var(--jce-white-light);
  position: relative;
  z-index: 3;
  bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
}
.jce-si-nav-arrow {
  cursor: pointer;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  border-right: 2px solid var(--jce-bg);
}
.jce-si-nav-arrow:last-child {
  border-right: 0px;
  border-left: 2px solid var(--jce-bg);
}
.jce-si-nav-arrow:hover {
  color: var(--jce-white);
  background-color: var(--jce-white-light);
}
.jce-si-nav-dot {
  cursor: pointer;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.jce-si-nav-dot.jce-si-active {
  color: var(--jce-white);
}
.jce-si-nav-dot:not(.jce-si-active):hover {
  color: var(--jce-white);
  background-color: var(--jce-white-light);
  opacity: 0.5;
}
.jce-si-nav-dot.jce-si-active:hover {
  color: var(--jce-white);
  background-color: var(--jce-white-light);
}

.jco {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  box-sizing: border-box;
  align-items: stretch;
}

.jco-bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  height: 50px;
  background-color: #0f3a52;
  justify-content: space-between;
  box-sizing: border-box;
}
.jco-bar-left {
  display: flex;
  height: 100%;
  align-content: center;
}

.jco-bar-right {
  display: flex;
  height: 100%;
}
.jco-tab {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Gotham', sans-serif;
}
.jco-tab.jco-hide {
  visibility: hidden;
}
.jco-tab .jco-tab-upper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding-top: 16px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}
.jco-tab .jco-tab-lower {
  display: flex;
  box-sizing: border-box;
  padding-top: 6px;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  flex-direction: row;
  height: 16px;
  width: 100%;
}
.jco-tab .jco-iconBox {
  display: flex;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border-radius: 3px;
}
.jco-tab.jco-active {
  /* border-top: 4px solid white; */
  /* background-color: rgba(255, 255, 255, 0.05); */
}
.jco-tab.jco-active .jco-tab-upper {
  font-weight: 600;
  color: white;
}
.jco-tab.jco-active .jco-tab-lower {
  color: rgba(255, 255, 255, 1);
}
.jco-tab.jco-active .jco-iconBox {
  color: #0f3a52;
  background-color: white;
}
.jco-tab.jco-inactive {
  /* border-top: 4px solid transparent; */
}
.jco-tab.jco-inactive .jco-tab-upper {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.jco-tab.jco-inactive .jco-tab-lower {
  color: transparent;
}
.jco-tab.jco-inactive .jco-iconBox {
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
}
.jco-tab.jco-inactive:hover {
  /* border-top: 4px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.01); */
}
.jco-tab.jco-inactive:hover .jco-tab-upper {
  color: white;
}
.jco-tab.jco-inactive:hover .jco-tab-lower {
  color: rgba(255, 255, 255, 0.3);
}
.jco-tab.jco-inactive:hover .jco-iconBox {
  color: white;
}
/* .jco-iconBox.jco-errorBox {
  color: white;
  background-color: #d52224;
} */

.jco-main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  flex: 1 1;
  box-sizing: border-box;
  z-index: 0;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}
.jco-elements-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  box-sizing: border-box;
}
.jco-element {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  padding: 30px;
  box-sizing: border-box;
}
.jco-element.jco-active {
  z-index: 1;
}
.jco-element.jco-inactive {
  z-index: 0;
}
.jco-console-output {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
    monospace;
  overflow: auto;
  box-sizing: border-box;
  padding: 0px;
}
.jco .xterm {
  padding: 30px;
}
.jco-instructions-pane {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  overflow-x: auto;
  overflow-y: scroll;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
.jco-instructions-pane .header-tag-v2 {
  min-height: 28px;
  float: right;
  padding: 0 12px;
}
.jco-instructions-pane-content {
  height: 100%;
  width: 100%;
}
.jco-instructions-pane-content img {
  width: 100%;
}
.jco-instructions-pane-content video {
  width: 100%;
}
.jco-instructions-pane-content > *:first-child {
  margin-top: 0px;
}
.jco-instructions-pane-content.jco-supplemental > *:first-child {
  margin-right: 119px;
}
.jco-instructions-pane-content.jco-supplemental.easy > *:first-child {
  margin-right: 89px;
}
.jco-instructions-pane-content.jco-supplemental.medium > *:first-child {
  margin-right: 85px;
}

.jide-default-env-content {
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #eef9ff;
}

.jide-default-env-content-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  border-radius: 3px;
  /* border: 30px solid rgba(0, 165, 255, 0.2); */
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 12px rgba(100, 100, 100, 0.15);
}

.jide-default-env-content-top {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #00456b;
  font-size: 18px;
  letter-spacing: 0.03em;
  text-align: center;
  background-color: rgba(0, 141, 218, 0.2);
  padding: 20px;
  box-sizing: border-box;
}

.jide-default-env-content-bottom {
  display: flex;
  width: 100%;
  background-color: white;
  align-items: flex-end;
  justify-content: center;
  padding-top: 20px;
}

.jide-default-env-content img {
  width: 80%;
}



.cuteprogressbar {
  height: 30px;
  border-radius: 15px;
  border: 2px solid #75cefd;
  background-color: #eaf6fb;
  overflow: hidden;
  padding: 2px;
  box-shadow: 0px 0px 6px 0px rgba(117, 206, 253, 0.5) inset;
}
.cuteprogressbar > span {
  display: block;
  height: 100%;
  border-radius: 11px;
  background-color: rgba(0, 166, 255, 1);
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 11px 0px rgba(255, 255, 255, 0.4) inset;
}
.cuteprogressbar > span:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  -webkit-animation: barbershop 2s linear infinite;
          animation: barbershop 2s linear infinite;
  overflow: hidden;
}

@-webkit-keyframes barbershop {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@keyframes barbershop {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.ImageUploadField_dropzone__jhkjP {
  background-color: #eff9ff;
  border: 2px dashed #a7cee2;
  padding: 1rem;
}

.ImageUploadField_input__1rD1r {
  display: none;
}

.ImageUploadField_inputLabel__206WR {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  font-weight: 500;
  color: #335875;
  cursor: pointer;
  min-height: 6rem;
}

.ImageUploadField_progressBar__1aJ35 {
  flex: 1 1;
  align-self: center;
}

.ImageUploadField_title__9V_UJ {
  font-size: 1.4em;
  font-weight: 500;
  color: #335875;
  text-overflow: ellipsis;
}

.ImageUploadField_uploadError__21H_p {
  font-size: 1.4em;
  font-weight: 500;
  color: #753333;
  flex: 1 1;
}

.ImageUploadField_imageView__MMpzY {
  position: relative;
  display: inline-block;
  min-width: 12rem;
  min-height: 4rem;
}

.ImageUploadField_imageView__MMpzY > img {
  width: auto;
  max-height: 12rem;
}

.ImageUploadField_editPhoto__38tfE {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.whiteboard-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: hsl(197, 100%, 99%);
  flex: 1 1;
}

.whiteboard-container.hidden {
  display: none;
}

.whiteboard-container .whiteboard {
  flex: 1 1;
  display: flex;
  position: relative;
}

.whiteboard-container .whiteboard-controls-container {
  margin: 0 14px;
  border-bottom: 1px solid hsl(208, 36%, 87%);
}

.whiteboard-container .whiteboard-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  font-size: 12px;
}
.whiteboard-container .whiteboard-controls.condense {
  padding: 8px 0;
}
.whiteboard-container .whiteboard-controls .juniverse-heading-container {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin-top: 0.5rem;
}
.whiteboard-container
  .whiteboard-controls
  .juniverse-heading-container
  .heading-img-container {
  max-height: 2.5rem;
  margin-right: 1rem;
}
.whiteboard-container
  .whiteboard-controls
  .juniverse-heading-container
  .heading-img-container
  img {
  height: 2.5rem;
}
.whiteboard-container
  .whiteboard-controls
  .juniverse-heading-container
  span#view-work {
  font-size: 1rem;
  font-weight: 600;
  color: #122b39;
}
.whiteboard-container .whiteboard-controls .whiteboard-control {
  margin-right: 12px;
}

button.whiteboard-control {
  background: hsl(197, 60%, 94%);
  padding: 6px;
  border: none;
  color: hsl(208, 100%, 11%);
  font-weight: bold;
}

.whiteboard-control.icon-button {
  line-height: 0;
}

button.whiteboard-control.active {
  background: hsl(208, 100%, 53%);
}

button.whiteboard-control .icon {
  height: 12px;
  width: 12px;
  padding: 0;
}

button.whiteboard-control .icon path {
  fill: hsl(208, 100%, 11%);
}

button.whiteboard-control .icon line,
button.whiteboard-control.stroke-only .icon path,
button.whiteboard-control.stroke-only .icon rect,
button.whiteboard-control.stroke-only .icon ellipse,
button.whiteboard-control .icon polyline {
  stroke: hsl(208, 100%, 11%);
}

button.whiteboard-control.active .icon path {
  fill: #fff;
}

button.whiteboard-control.active.stroke-only .icon path,
button.whiteboard-control.stroke-only .icon rect,
button.whiteboard-control.stroke-only .icon ellipse,
button.whiteboard-control.active .icon line,
button.whiteboard-control.active .icon polyline {
  stroke: #fff;
}

.whiteboard-container .whiteboard-page-nav {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whiteboard-container .whiteboard-page-nav button.whiteboard-control .icon {
  height: 8px;
  width: 8px;
}

.whiteboard-page-nav button.whiteboard-control:last-child {
  margin-right: 0;
}

button.whiteboard-control:disabled {
  color: hsl(208, 38%, 75%);
  border: none;
  background: hsl(197, 60%, 94%);
}

button.whiteboard-control.stroke:disabled .icon path,
button.whiteboard-control:disabled .icon polyline {
  stroke: hsl(208, 38%, 75%);
}

button.whiteboard-control:disabled .icon path {
  fill: hsl(208, 38%, 75%);
}

.whiteboard-container .wb-control-select {
  line-height: 0;
  position: relative;
}

.whiteboard-container .select-dropdown {
  line-height: 0;
  position: absolute;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 6px 10px -2px hsl(208, 46%, 78%);
  z-index: 2;
  padding: 4px;
}

.whiteboard-container .select-dropdown > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whiteboard-container .select-dropdown .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.whiteboard-container .wb-control-select button {
  position: relative;
}

.whiteboard-container .select-dropdown button {
  margin: 3px;
}
.whiteboard-container .shape-select .select-dropdown button:not(.active) {
  background: none;
}
.whiteboard-container
  .shape-select
  .select-dropdown
  button.stroke-only:not(.active)
  rect,
.whiteboard-container
  .shape-select
  .select-dropdown
  button.stroke-only:not(.active)
  ellipse {
  stroke: hsl(208, 100%, 11%);
}
.whiteboard-container .shape-select .select-dropdown button:not(.active):hover {
  background: hsl(208, 80%, 92%);
}
.whiteboard-container .select-dropdown button.active {
  box-shadow: none;
}
.whiteboard-container .color-select .select-dropdown button {
  background: none;
  box-shadow: none;
  padding: 0;
}

.whiteboard-container .color-select .select-dropdown button.active {
  background: hsl(208, 80%, 92%);
}

.whiteboard-container .select-dropdown button .color-indicator {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.whiteboard-container .select-dropdown button:not(.active):hover .color-indicator {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.whiteboard-container .color-indicator.outer {
  border-radius: 50%;
  height: 12px;
  width: 12px;
  opacity: 0.5;
}

.whiteboard-container .color-indicator.inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteboard-container .color-indicator.inner > div {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.whiteboard-container .select-dropdown .color-indicator.outer {
  height: 18px;
  width: 18px;
  margin: 4px;
}

.whiteboard-container .select-dropdown .color-indicator.inner > div {
  height: 14px;
  width: 14px;
}

.whiteboard-container .whiteboard > textarea {
  -webkit-appearance: none;
  box-shadow: none;
  border: 1px solid hsl(208, 36%, 87%);
  position: absolute;
  width: 400px;
  padding: 6px;
  top: 100px;
  left: 100px;
  box-shadow: 0 6px 10px -4px hsl(208, 46%, 78%);
  box-sizing: border-box;
  z-index: 1002;
  font-size: 16px;
}

.whiteboard-container .whiteboard > svg {
  flex: 1 1;
}

.whiteboard-container .whiteboard > svg path {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.whiteboard-container .whiteboard > svg text {
  display: block;
  font-size: 16px;
  white-space: pre;
}

.whiteboard-container .whiteboard > svg path {
  stroke-width: 3px;
}

.whiteboard-container .whiteboard > svg image.wb-image-delete-btn {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.whiteboard-container .whiteboard > svg image.wb-grid-delete-btn {
  cursor: pointer;
  transition: all 0.2s;
  width: 16px;
  height: 16px;
}

.whiteboard-container .whiteboard > svg image.wb-image-delete-btn:hover,
.whiteboard-container .whiteboard > svg image.wb-grid-delete-btn:hover {
  width: 18px;
  height: 18px;
}

.whiteboard-container .whiteboard > svg rect.wb-grid-delete-btn-content {
  fill: #fff;
}

.whiteboard-container .whiteboard > svg image.wb-grid {
  pointer-events: none;
}

.whiteboard-container:not(.image-tool) .whiteboard > svg image.wb-image-delete-btn,
.whiteboard-container:not(.grid-tool) .whiteboard > svg image.wb-grid-delete-btn {
  display: none;
}

.whiteboard-container .whiteboard .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(208, 14%, 24%, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.whiteboard-container .page-count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whiteboard-container .page-count-container .sk-fading-circle {
  margin-right: 0;
}

.whiteboard-container .ws-error-alert .alert-banner-content {
  padding: 16px;
}

.whiteboard-container .ws-error-alert .alert-banner-text {
  max-width: 400px;
}

.whiteboard-container .ws-error-alert .lost-connection {
  display: flex;
  align-items: center;
}

.whiteboard-container .svg-defs {
  position: absolute;
  height: 0;
  width: 0;
}

.whiteboard-container .resizers {
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
}

.whiteboard-container .resizers .right-handle {
  display: none;
}
.whiteboard-container .resizers .right-handle.active {
  display: block;
  position: absolute;
  width: 0.5em;
  top: 1em;
  bottom: 1.6em;
  right: 0.8em;
  cursor: move;
}

.whiteboard-container .resizers .bottom-handle {
  display: none;
}
.whiteboard-container .resizers .bottom-handle.active {
  display: block;
  position: absolute;
  height: 0.5em;
  left: 1em;
  bottom: 0.8em;
  right: 1.6em;
  cursor: move;
}

.whiteboard-container .resizers .left-handle {
  display: none;
}
.whiteboard-container .resizers .left-handle.active {
  display: block;
  position: absolute;
  width: 0.5em;
  top: 1em;
  bottom: 1em;
  left: 0.8em;
  cursor: move;
}

.whiteboard-container .resizers .top-handle {
  display: none;
}
.whiteboard-container .resizers .top-handle.active {
  display: block;
  position: absolute;
  height: 0.5em;
  left: 1em;
  top: 0.8em;
  right: 1em;
  cursor: move;
}

.whiteboard-container .resizers .corner-handle {
  display: none;
}

.whiteboard-container .resizers .corner-handle.active {
  display: block;
  background-color: white;
  width: 1em;
  height: 1em;
  position: absolute;
  border: 2px solid #9ddaff;
  border-radius: 0.25em;
}

.whiteboard-container .resizers .ne-handle.active {
  top: 0.5em;
  right: 0.45em;
  cursor: nesw-resize;
}
.whiteboard-container .resizers .nw-handle.active {
  top: 0.5em;
  left: 0.45em;
  cursor: nwse-resize;
}
.whiteboard-container .resizers .se-handle.active {
  bottom: 0.5em;
  right: 0.45em;
  cursor: nwse-resize;
}
.whiteboard-container .resizers .sw-handle.active {
  bottom: 0.5em;
  left: 0.45em;
  cursor: nesw-resize;
}

.whiteboard-container .image-container {
  cursor: move;
}

.whiteboard-container .image-container,
.whiteboard-container .text-container {
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow: hidden;
  border: 2px dashed transparent;
}

.whiteboard-container .image-container,
.whiteboard-container .text-container.active {
  border: 2px dashed #9ddaff;
}

.whiteboard-container .text-input {
  min-width: 1em; /* Without this, width could be zero and cursor will not show */
  display: inline-block;
  line-height: 1.2;
}

.wb-control-select > .select-dropdown > .ui-field > * {
  min-width: 400px;
}

@media only screen and (max-width: 460px) {
  .wb-control-select > .select-dropdown {
    position: fixed;
    left: 0px;
    right: 0px;
  }
  .wb-control-select > .select-dropdown > .ui-field > * {
    width: 100%;
  }
}



.jide-math-env {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: stretch;
  overflow-y: hidden;
  background-color: white;
}

.jide-math-env .left-panel {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid hsl(208, 36%, 87%);
}

.jide-math-env .right-panel {
  flex: 1 1;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
}

.jide-math-env .right-panel-header {
  margin: 0 14px;
  border-bottom: 1px solid hsl(208, 36%, 87%);
  box-sizing: border-box;
  max-width: 100%;
}

.jide-math-env .right-panel-header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 0;
  min-height: 56px;
}

.jide-math-env .header-tag-v2 {
  color: hsl(201, 100%, 43%);
  background: hsla(201, 100%, 43%, 0.1);
  margin: 0 10px;
}

.jide-math-env .math-problem-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-family: 'Open Sans', sans-serif;
  color: hsl(207, 20%, 46%);
  font-weight: bold;
}

.jide-math-env .math-problem-nav button.whiteboard-control .icon {
  height: 8px;
  width: 8px;
}

.jide-math-env .math-problem-nav button.whiteboard-control:last-child {
  margin-right: 0;
  margin-left: 12px;
}

.jide-math-env .right-panel-header h3.right-panel-title {
  margin: 0;
  font-size: 18px;
  font-family: 'Gotham', sans-serif;
}



.link-copy-button {
  color: hsl(208, 100%, 53%);
  border: 1px solid hsl(208, 100%, 53%);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  cursor: pointer;
}

.link-copy-button.icon-only {
  border: none;
  padding: 0;
}

.link-copy-button .icon {
  margin-left: 10px;
}

.link-copy-button.icon-only .icon {
  margin-left: 0;
}

.jide-scratch-env {
  height: 100%;
  width: 100%;
  background: #eef9ff;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  overflow-y: auto;
}
.jide-scratch-env p {
  color: #446785;
}
.jide-scratch-env.loading-state-view {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-content: center;
}
.jide-scratch-env .upper-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 12px;
  height: 16px;
  background: #eef9ff;
}
.jide-scratch-env .layout {
  max-width: 730px;
  margin: 0 auto;
  padding: 16px 32px;
}
.jide-scratch-env .layout * {
  letter-spacing: 0;
  line-height: 1.7;
}
.jide-scratch-env .layout h3 {
  font-size: 20px;
  margin: 0;
  border-bottom: 1px solid #def0fc;
  padding-bottom: 0.75rem;
  margin-bottom: 0.75rem;
}
.jide-scratch-env .sub-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}
.jide-scratch-env .sub-header .line {
  max-width: 200px;
  flex: 1 1;
  height: 1px;
  background: #6c9cb5;
}
.jide-scratch-env .sub-header p {
  font-family: "Gotham", sans-serif;
  letter-spacing: 0.06em;
  color: #6c9cb5;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 14px;
  font-size: 12px;
}
.jide-scratch-env h1,
.jide-scratch-env h2,
.jide-scratch-env h3 {
  color: #00456b;
}
.jide-scratch-env-header {
  text-align: left;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.jide-scratch-env-header h1 {
  flex: 1 1;
  font-size: 24px;
  margin: 0;
}
.jide-scratch-env-header .header-tag-v2 {
  min-height: 28px;
  padding: 0 12px;
  margin-left: 12px;
}
.jide-scratch-select {
  margin: 0 0 1rem;
}
.jide-scratch-env .scratch-env-options {
  padding-top: 0.25rem;
  display: flex;
  flex-direction: row;
  flex: 1 1;
}
.jide-scratch-env .scratch-env-option {
  background: #fff;
  margin: 0;
  overflow: hidden;
  text-align: center;
  border: 2px solid #d5f1ff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 14px;
  box-shadow: 0px 14px 44px 0px hsl(202deg 39% 76% / 38%);
  min-width: calc(100% - 16px);
  box-sizing: border-box;
  border-radius: 0.75rem;
}

.jide-scratch-env .scratch-env-option h2 {
  margin-bottom: 0;
  font-size: 20px;
}
.jide-scratch-env .scratch-buttons-container {
  margin-right: 1.5rem;
  max-width: 330px;
  min-width: 280px;
}
.jide-scratch-env .scratch-buttons-container > :not(:first-child) {
  margin-top: 1rem;
}
.jide-scratch-env .scratch-project-container {
  width: 100%;
}
.jide-scratch-env .scratch-project-container-desktop {
  border-radius: 8px;
  overflow: initial;
  width: 100%;
  max-width: 930px;
}

.jide-scratch-env .scratch-project-container-desktop.playground-project {
  max-width: 1000px;
  margin: 0 auto;
}
.jide-scratch-env .scratch-env-button {
  background: #fff;
  box-shadow: 0px 6.75439px 21.2281px rgba(159, 205, 231, 0.22);
  border: 2px solid #dcf3ff;
  color: #335875;
  font-size: 14px;
  border-radius: 8px;
  margin: 0;
  padding: 12px;
  width: 100%;
  margin: 0;
  letter-spacing: 0;
  text-transform: none;
  text-align: left;
}
.jide-scratch-env .scratch-env-button:first-child {
  margin-top: 0px;
}
.jide-scratch-env .scratch-env-button span {
  margin-right: 20px;
}
.jide-scratch-env .scratch-env-button:hover {
  background: #f0fbff;
}
.jide-scratch-env .scratch-env-button.selected {
  background: #fafdff;
  border: 2px solid #00a5ff;
  font-weight: bold;
  box-shadow: 0px 6.75439px 21.2281px rgba(107, 181, 221, 0.22);
}
.jide-scratch-env .scratch-env-button.selected:hover {
  background: #fff;
}
.jide-scratch-env .scratch-env-option > .icon {
  border: 14px solid hsl(202, 73%, 97%);
  background: hsl(201, 100%, 45%);
  margin: 0 auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 30px;
  flex-shrink: 0;
}
.jide-scratch-env .scratch-env-option > .icon path {
  fill: #fff;
  stroke: #fff;
}
.jide-scratch-env .scratch-env-option p,
.jide-scratch-env .jide-scratch-instructions p {
  font-size: 14px;
  letter-spacing: -0.12px;
}

.jide-scratch-env .scratch-env-option .buttons-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 1rem;
}
.jide-scratch-env .scratch-env-link {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 16px;
  background: #f2faff;
  border: 1px solid #ceecff;
  box-shadow: inset 0px 4px 14px 0px hsl(200, 60%, 92%);
  box-sizing: border-box;
}
.jide-scratch-env .scratch-env-link .link-text {
  padding: 0 0.5rem;
  flex: 1 1;
  width: 100px;
  font-size: 14px;
  color: #004c75;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.jide-scratch-env .scratch-env-link .link-copy-button {
  background: #fff;
  border: 2px solid #ceecff;
  border-radius: 8px;
  color: hsl(201, 100%, 63%);
  text-transform: uppercase;
  padding: 5px;
  padding-left: 0;
}
.jide-scratch-env .scratch-env-link .link-copy-button span {
  margin: 0 18px;
  line-height: 0;
  font-weight: bold;
  letter-spacing: 0.06em;
}
.jide-scratch-env .scratch-env-link .link-copy-button .icon {
  background: hsl(204, 100%, 95%);
  padding: 5px;
  margin: 0;
}
.jide-scratch-env .scratch-env-link .link-copy-button .icon path {
  fill: hsl(201, 100%, 63%);
}
.jide-scratch-env .scratch-env-action-button {
  display: flex;
  text-align: center;
  text-transform: unset;
  align-items: center;
  color: white;
  padding: 8px;
  padding-left: 24px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.06em;
  border-radius: 6px;
  background: hsl(201, 100%, 50%);
  box-sizing: border-box;
}
.jide-scratch-env .scratch-env-action-button:hover {
  box-shadow: 0 6px 8px 0 hsla(201, 100%, 37%, 0.2);
}
.jide-scratch-env .scratch-env-action-button span {
  flex: 1 1;
  padding: 0 12px;
}
.jide-scratch-env .scratch-env-action-button .icon {
  background: hsl(201, 100%, 47%);
  border-radius: 6px;
  margin-left: auto;
}
.jide-scratch-env .scratch-env-action-button .icon path {
  fill: #fff;
}
.jide-scratch-env .scratch-env-option small {
  margin: 1rem 0 0;
  flex-shrink: 1;
}

.started-project__container,
.added-project__container h1 {
  padding: 1.25rem;
  margin: 0;
}

.unstarted-scratch-project {
  display: flex;
  text-align: left;
  padding: 1rem 1rem 1.25rem;
  flex-direction: row;
}
.unstarted-scratch-project h3 {
  margin-top: 0px;
}
.unstarted-scratch-video {
  flex: 1 0 42.5%;
  background: #edf6fb;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2f2fb;
  border-radius: 6px;
}
.unstarted-scratch-video video {
  max-width: 475px;
  width: 100%;
  border-radius: 6px;
  object-fit: contain;
  max-height: initial;
}
.unstarted-scratch-link {
  flex: 0 1 auto;
  display: flex;
  margin: 1rem 0 0 0;
  flex-direction: column;
  justify-content: space-between;
}
.unstarted-scratch-desc {
  margin-bottom: 1rem;
}
.unstarted-scratch-link.no-video {
  width: 100%;
  margin-left: 0;
}
.jide-scratch-instructions {
  background: #f5fcff;
  padding: 0.75rem 1.25rem;
  margin: 1rem 0 0 0;
  text-align: left;
  font-size: 14px;
  border: 1px solid #def4ff;
  box-shadow: initial;
  min-width: calc(100% - 16px);
  box-sizing: border-box;
}
.jide-scratch-instructions p {
  padding: 8px 0;
}
.jide-scratch-instructions h3 {
  padding: 0 !important;
  border-bottom: initial !important;
  margin-bottom: 0 !important;
}
@media (min-width: 992px) {
  .unstarted-scratch-link {
    margin: 0 0 0 1.25rem;
  }
  .jide-scratch-env .layout {
    max-width: 1280px;
  }
}
@media (max-width: 992px) {
  .unstarted-scratch-project {
    flex-direction: column;
    align-items: center;
  }
  .unstarted-scratch-video,
  .unstarted-scratch-link {
    width: 100%;
  }
  .unstarted-scratch-project h3 {
    margin-top: 20px;
  }
}

@media (max-width: 720px) {
  .jide-scratch-env-header h1 {
    font-size: 18px;
  }
}

button.action-button {
  background-color: hsl(208, 100%, 53%);
  margin-top: 0;
  padding: 4px 24px;
  font-family: 'Gotham', sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-size: 12px;
  line-height: 1.8em;
  text-rendering: optimizeLegibility;
  font-weight: 800;
  letter-spacing: 1.5px;
  border-radius: 2px;
}

button.action-button.outline {
  border: 1px solid #9ce0ff;
  color: hsl(208, 100%, 53%);
  background: transparent;
}

button.action-button.with-icon {
  padding-right: 8px;
}
button.action-button.with-icon.icon-left {
  padding-right: 24px;
  padding-left: 8px;
}
button.action-button.with-icon .icon {
  margin-left: 16px;
  float: right;
  flex-shrink: 0;
}
button.action-button.with-icon.icon-left .icon {
  margin-left: 0;
  margin-right: 16px;
  float: left;
}
button.action-button:disabled {
  background: #efefef;
  color: #fff;
  cursor: default;
}
button.action-button:disabled:hover {
  box-shadow: none;
}
button.action-button:disabled .icon,
button.action-button:disabled .icon.icon-lightblue {
  background: #e8e8e8;
}

.jide-eb {
  display: flex;
  flex: 1 1;
  position: relative;
}

.jide-environment-container {
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.jide-environment-container {
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.jide-environment-container:not(.jide-environment-container-active) {
  z-index: 0;
}
.jide-environment-container:not(.jide-environment-container-active)
  .jide-scratch-env {
  position: absolute;
  left: -9999em;
  top: -9999em;
}

.jide-environment-container.jide-environment-container-active {
  z-index: 3;
}
.jide-environment-container.jide-environment-container-active.jide-environment-container-fullscreen {
  z-index: 1001 !important;
  background-color: hsl(201, 69%, 19%);
}

.jide-math-problem {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 14px;
  font-size: 15px;
  max-height: 100%;
  overflow-y: hidden;
}

.jide-math-problem .scrollable-content {
  overflow-y: hidden;
  flex: 100 1;
  position: relative;
}

.jide-math-problem .problem-instructions {
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: hsl(207, 20%, 46%);
  line-height: 1.6em;
}

.jide-math-problem .problem-instructions span.katex {
  color: hsl(208, 14%, 24%);
  font-size: 1.3em;
  line-height: 1.8em;
}

.problem-answer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
}

.problem-answer .label-and-correctness {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.problem-answer .label-and-correctness .correctness-tag {
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.problem-answer .label-and-correctness .correctness-tag .icon {
  height: 6px;
  width: 6px;
  padding: 4px;
  border-radius: 50%;
  margin-right: 6px;
}

.problem-answer .label-and-correctness .correctness-tag .icon path,
.problem-answer .label-and-correctness .correctness-tag .icon polyline {
  stroke: #fff;
}

.problem-answer .label-and-correctness .correctness-tag.correct {
  color: rgb(31, 191, 94);
}

.problem-answer .label-and-correctness .correctness-tag.correct .icon {
  background: rgb(31, 191, 94);
}

.problem-answer .label-and-correctness .correctness-tag.incorrect {
  color: hsl(0, 72%, 49%);
}

.problem-answer .label-and-correctness .correctness-tag.incorrect .icon {
  background: hsl(0, 72%, 49%);
}

.problem-answer .label-and-correctness label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  margin-right: 8px;
  color: hsl(207, 20%, 46%);
  font-weight: bold;
}

.problem-answer .problem-changed-msg {
  margin-top: 10px;
  font-size: 12px;
  color: hsl(207, 20%, 46%);
}

.problem-answer textarea {
  padding: 10px;
  font-size: 14px;
  line-height: 1.3em;
  border: 1px solid hsl(197, 58%, 80%);
  border-radius: 2px;
  margin-top: 12px;
  background: hsl(200, 100%, 98%);
  color: hsl(207, 20%, 46%);
}

.problem-answer button {
  align-self: flex-end;
  margin-top: 12px;
}

.problem-answer button .icon {
  background: hsl(208, 100%, 62%);
}

.problem-answer button .icon path {
  fill: #fff;
}

.problem-answer.has-answer-attempt.correct textarea.show-correctness {
  color: rgb(31, 191, 94);
  border: 1px solid rgb(31, 191, 94);
  background: rgba(31, 191, 94, 0.05);
}

.problem-answer.has-answer-attempt.incorrect textarea.show-correctness {
  color: hsl(0, 72%, 49%);
  border: 1px solid hsl(0, 72%, 49%);
  background: hsla(0, 72%, 49%, 0.05);
}

#jide-nb {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color:#292563;
  height: 60px;
}

#jide-nb div {
  display: flex;
  box-sizing: border-box;
}

#jide-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 1);
  width: 60px;
}
#jide-loading-spinner svg {
  height: 60%;
  width: 60%;
}

.status-icon.icon {
  height: 8px;
  width: 8px;
  padding: 4px;
  border-radius: 50%;
  background: hsl(201, 77%, 35%);
  flex-shrink: 0;
  box-sizing: content-box;
}
#jide-nb div.status-icon.icon {
  box-sizing: content-box;
}
.status-icon.icon polyline {
  stroke: #fff;
}
.status-icon.icon path,
.status-icon.icon rect {
  fill: #fff;
}
.status-icon.completed.icon {
  background: hsl(128, 68%, 53%);
}

#jide-nb .jide-nav {
  display: inline-block;
  height: 100%;
  position: relative;
}
.jide-nav {
  cursor: pointer;
  z-index: 100;
}

.jide-nav-header {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #292563;
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  align-items: stretch;
}

.jide-nav-left {
  height: 100%;
  flex-direction: column;
  padding: 12px 0;
  padding-right: 20px;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
}
.jide-nav-left-upper {
  align-self: flex-start;
  align-items: center;
  color: #c1bce0;
  letter-spacing: 0.8px;
  font-family: "Graphik", "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 10px;
}
#jide-nb div.jide-nav-left-lower {
  display: block;
  color: #fff;
  font-family: "Graphik", "Open Sans", sans-serif;
  font-weight: normal;
  letter-spacing: 0.8px;
  font-size: 15px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.jide-nav-right {
  flex-direction: column;
  justify-content: center;
}
.jide-nav-right button {
  padding: 0px;
  background: #413e75;
  text-align: center;
  border-radius: 4px;
}

.jide-nav-right button .icon {
  height: 8px;
  width: 8px;
  padding: 12px;
}

.jide-nav-right button .icon path {
  fill: #292563;
}

#jide-nb .jide-nav-dd {
  display: none;
  box-shadow: 0px 0px 8px "#A199D0"; /* jPurple 400 */
}
#jide-nb .jide-nav-dd.jide-nav-expanded {
  display: inline-block;
  position: absolute;
  background: #fff;
  width: 100%;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.jide-nav-dd-option {
  flex-direction: row;
  align-items: center;
  border-left: 1px solid #cac9d8; /* jDark 200 */
  border-right: 1px solid #cac9d8; /* jDark 200 */
  border-bottom: 1px solid #e2e1ed; /* jDark 100 */
  width: 100%;
  padding: 15px;
  background: #fff;
  font-family: "Graphik", "Open Sans", sans-serif;
  letter-spacing: 0.6px;
  font-size: 13px;
  font-weight: normal;
}
.jide-nav-dd-option:last-child {
  border-bottom: none;
}
.jide-nav-dd-option.subheader {
  background: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-family: "Graphik", "Open Sans", sans-serif;
  cursor: default;
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover {
  background: #f0f5fa; /* jGray 200 */
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon {
  background: #a199d0; /* jPurple 400 */
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon
  path,
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon
  rect {
  fill: #fff;
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon.math-icon
  path {
  stroke: #fff;
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon
  polyline {
  stroke: #fff;
}
.jide-nav-dd-option:first-child {
  border-top: 1px solid #cac9d8;
}
.jide-nav-dd-option:last-child {
  border-bottom: 1px solid #cac9d8;
}
.jide-nav-dd-option.disabled {
  cursor: not-allowed;
}
.jide-nav-dd-option.selected {
  background: #6255b0; /* jPurple 600 */
  color: #fff;
  font-weight: 600;
  cursor: default;
}
.jide-nav-dd-option.selected .icon {
  background: #463897; /* jPurple 800 */
}
.jide-nav-dd-option.selected .icon path,
.jide-nav-dd-option.selected .icon rect {
  fill: #fff;
}
.jide-nav-dd-option.selected .icon.math-icon path {
  stroke: #fff;
}
.jide-nav-dd-option.selected .icon polyline {
  stroke: #fff;
}
.jide-nav-dd-option-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
}
.jide-nav-dd-option-left .icon {
  background: #efeef7;
  padding: 10px;
}
.jide-nav-dd-option-left .icon path,
.jide-nav-dd-option-left .icon rect {
  fill: #6255b0; /* jPurple 600 */
}
.jide-nav-dd-option-left .icon.math-icon path {
  stroke: #6255b0; /* jPurple 600 */
}
.jide-nav-dd-option-left .icon polyline {
  stroke: "#6255B0"; /* jPurple 600 */
}
.jide-nav-dd-option-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  margin-left: auto;
}
.jide-nav-dd-option .jide-nav-dd-option-right .icon {
  background: none;
  height: 16px;
  width: 16px;
  padding: 0;
  margin-left: 0.75rem;
}
.jide-nav-dd-option .jide-nav-dd-option-right .icon polyline {
  stroke: #e2e1ed;
}
.jide-nav-dd-option .jide-nav-dd-option-right .icon path {
  fill: #e2e1ed;
}
.jide-nav-dd-option.selected .jide-nav-dd-option-right .icon polyline,
.jide-nav-dd-option:hover .jide-nav-dd-option-right .icon polyline {
  stroke: #c1bce0;
}
.jide-nav-dd-option.selected .jide-nav-dd-option-right .icon path,
.jide-nav-dd-option:hover .jide-nav-dd-option-right .icon path {
  fill: #c1bce0;
}
.jide-nav-dd-option .jide-nav-dd-option-right a .icon {
  transition: -webkit-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}
.jide-nav-dd-option .jide-nav-dd-option-right a:hover .icon {
  -webkit-transform: scale(1.08);
          transform: scale(1.08);
}
.jide-nav-dd-option-upper {
  align-self: flex-start;
}
.jide-nav-dd-option-lower {
  align-self: flex-start;
  font-family: "Graphik", "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}
.jide-nav-dd-option-middle {
  display: flex;
  align-items: center;
  flex: 1 1;
}
.jide-nav-dd-option-middle span {
  margin-right: 0.75rem;
}
.jide-nav-dd-option-middle .icon {
  margin-left: auto;
}
#jide-nav-dd-search {
  flex-direction: row;
  height: 50px;
  padding: 5px 10px 5px 10px;
  background-color: white;
  align-items: center;
  color: rgba(15, 58, 82, 0.5);
}
#jide-nav-dd-search-input {
  padding-left: 10px;
  border: 0px;
  color: #0f3a52;
  background-color: white;
  width: 100%;
}
#jide-nav-dd-search-input[type="text"] {
  font-family: "Graphik", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.modal-v2-container {
  display: flex;
  position: fixed;
  top: 60px; /*nav height*/
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  z-index: 999;
  padding: 15px;
}

.modal-v2-container.cover-nav {
  z-index: 1001;
  top: 0;
}

.modal-v2-container.hidden {
  display: none;
}

.modal-v2-container .modal-content {
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.modal-content > .remove-icon {
  float: right;
  margin-top: 15px;
  margin-right: 15px;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.fitvid {
  display: flex;
  justify-content: center;
}
.fitvid div {
  display: flex;
}
.fitvid > video {
  max-width: 100%;
  max-height: 500px;
  border-radius: 5px;
}
.fitvid.fitvid-noBR > video {
  border-radius: 0px;
}

.jrs-modal-line {
  font-size: 14px;
}
.jrs-modal-line-icon {
  flex-shrink: 0;
  align-items: flex-start;
  padding-top: 3px;
  padding-right: 10px;
  width: 30px;
  font-size: 16px;
}
.jrs-modal-line span {
  padding-right: 4px;
}
.jrs-modal-line span.jrs-bold {
  font-weight: 600;
}

.jrs-modal-cards {
  cursor: pointer;
  justify-content: space-between;
}
.jrs-modal-card {
  flex-direction: column;
  height: 90px;
  flex: 1 1;
  border-radius: 4px;
  background: white;
  border: 2px solid #cbecff;
  opacity: 0.8;
  margin-right: 18px;
}
.jrs-modal-card:last-child {
  margin-right: 0px;
}
.jrs-modal-card-lower {
  color: #8daec0;
  padding: 6px 0 8px;
}
.jrs-modal-card.jrs-green {
  background-color: rgba(222, 248, 219, 0.67);
  border: 2px solid rgba(53, 158, 45, 0.5);
}
.jrs-modal-card.jrs-active {
  border: 3px solid var(--jrs-blue);
  box-shadow: 0px 0px 8px 0px rgba(0, 166, 255, 0.4);
  opacity: 1;
}
.jrs-modal-card-upper {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  border-bottom: 2px solid #d3efff;
}
.jrs-green .jrs-modal-card-upper {
  border-bottom: 1px solid rgba(52, 158, 45, 0.15);
}
.jrs-modal-card-lower {
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.jrs-modal-backbutton {
  flex-grow: 1;
  cursor: not-allowed;
  align-items: center;
  background-color: white;
  border: 2px solid var(--jrs-blue);
  border-radius: 4px;
  padding: 5px;
  color: var(--jrs-blue);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  opacity: 0.25;
}
.jrs-modal-backbutton:hover {
  background-color: var(--jrs-blue);
  color: white;
  border: 2px solid transparent;
}
.jrs-modal-backbutton.jrs-red:hover {
  background-color: var(--jrs-red);
  color: white;
  border: 2px solid transparent;
}
.jrs-modal-backbutton.jrs-active {
  cursor: pointer;
  opacity: 1;
}
.jrs-modal-backbutton-content {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.jrs-modal-backbutton-arrow {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  height: 26px;
  width: 26px;
  background-color: rgba(0, 166, 255, 0.2);
}
.jrs-modal-backbutton:hover .jrs-modal-backbutton-arrow {
  background-color: rgba(255, 255, 255, 0.2);
}

.jrs-modal-nextbutton {
  flex-grow: 1;
  cursor: not-allowed;
  align-items: center;
  background-color: var(--jrs-blue);
  border-radius: 4px;
  padding: 5px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  opacity: 0.25;
}
.jrs-modal-nextbutton.jrs-red {
  background-color: var(--jrs-red);
}
.jrs-modal-nextbutton.jrs-active {
  cursor: pointer;
  opacity: 1;
}
.jrs-modal-nextbutton.jrs-active:hover {
  background-color: #23b2ff;
}
.jrs-modal-nextbutton.jrs-active.jrs-red:hover {
  background-color: #db7171;
}
.jrs-modal-nextbutton-content {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  letter-spacing: 0;
}
.jrs-modal-nextbutton-arrow {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  height: 26px;
  width: 26px;
  background-color: rgba(255, 255, 255, 0.15);
}
.jrs-modal-nextbutton.jrs-link {
  flex-grow: 0;
  cursor: inherit;
  display: block;
  text-align: center;
  text-decoration: underline;
  background-color: transparent !important;
  color: var(--jrs-blue);
}
.jrs-modal-nextbutton.jrs-link .jrs-modal-nextbutton-content {
  display: inline;
  font-size: 13px;
}
.jrs-modal-nextbutton.jrs-link .jrs-modal-nextbutton-content:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  cursor: pointer;
}
.jrs-modal-nextbutton.jrs-link .jrs-modal-nextbutton-arrow {
  display: none;
}

.jrs-modal-statusmessage {
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.01em;
  background-color: var(--jrs-blue);
  color: white;
}
.jrs-modal-statusmessage.jrs-green {
  background-color: #def8db;
  color: #349e2d;
}
.jrs-modal-statusmessage.jrs-red {
  background-color: #ffeded;
  color: #bb2222;
}
.jrs-modal-statusmessage.jrs-yellow {
  background-color: #f8efc4;
  color: #bbb122;
}
.jrs-modal-statusmessage.jrs-gray {
  background-color: #ebebeb;
  color: #a5a5a5;
}
.jrs-modal-statusmessage-content {
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
}
.jrs-modal-statusmessage-icon {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
  height: 26px;
  width: 26px;
  margin-right: 10px;
  background-color: rgba(0, 0, 0, 0.05);
}
.jrs-modal-statusmessage-button {
  cursor: pointer;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 26px;
  padding: 0px 24px;
  margin-left: 15px;
  margin-right: 5px;
  background-color: #349e2d;
  color: white;
  opacity: 1;
  background-color: var(--jrs-modal-text);
}
.jrs-red .jrs-modal-statusmessage-button {
  background-color: #bb2222;
  color: white;
}
.jrs-modal-statusmessage-button:hover {
  opacity: 0.8;
}

.jrs-modal-gif {
  flex-direction: row;
}
.jrs-modal-video {
  border-radius: 5px;
  justify-content: center;
}

.jrs {
  --jrs-blue: #00a5ff;
  --jrs-red: #d33838;
  --jrs-modal-text: #325265;
  --jrs-modal-border: #c8e0eb;
  --jrs-modal-shadow: #182f3a;
  --jrs-modal-heading: #556f7a;

  display: flex;
}
.jrs div {
  display: flex;
  box-sizing: border-box;
}

.jrs-modal-container {
  position: absolute;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 30px;
}
.jrs-modal-container:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.jrs-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  font-family: "Open Sans";
  color: var(--jrs-modal-text);
  overflow: hidden;
  border-radius: 12px;
  border: 2px solid var(--jrs-modal-border);
  box-shadow: 0px 10px 40px -10px var(--jrs-modal-shadow);
  width: 600px;
  z-index: 999;
}
.jrs-modal div {
  display: flex;
}
.jrs-modal-section {
  border-bottom: 2px solid rgba(0, 166, 255, 0.15);
}
.jrs-modal-section:last-child {
  border-bottom: 0px;
}
.jrs-modal-section.jrs-column {
  flex-direction: column;
}
.jrs-modal-section.jrs-row > *:last-child {
  margin-right: 0px;
}
.jrs-modal-section.jrs-column > * {
  margin-bottom: 16px;
}
.jrs-modal-section.jrs-column > *:last-child {
  margin-bottom: 0px;
}
.jrs-modal-subsection.jrs-column {
  flex-direction: column;
}
.jrs-modal-subsection.jrs-row > * {
  margin-right: 10px;
}
.jrs-modal-subsection.jrs-row > *:last-child {
  margin-right: 0px;
}
.jrs-modal-subsection.jrs-column > * {
  margin-bottom: 14px;
}
.jrs-modal-subsection.jrs-column > *:last-child {
  margin-bottom: 0px;
}
.jrs-modal-subsection.jrs-column .jrs-modal-heading1 {
  font-size: 14px;
  align-self: center;
}

.jrs-modal-heading1 {
  font-weight: 800;
  font-size: 20px;
  letter-spacing: 0.3pt;
}
.jrs-modal-heading2 {
  color: var(--jrs-modal-heading);
  font-weight: 600;
  font-size: 12px;
  flex-grow: 1;
}
.jrs-modal-heading3 {
  color: var(--jrs-modal-heading);
  font-weight: 500;
  font-size: 11px;
  flex-grow: 1;
  letter-spacing: 0.07em;
}
.jrs-modal-spacing {
  height: 20px;
}

.jrs-modal-x {
  cursor: pointer;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
}
.jrs-modal-x-inner {
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  border-radius: 13px;
  opacity: 0.5;
  font-size: 16px;
}
.jrs-modal-x:hover .jrs-modal-x-inner {
  opacity: 0.6;
  color: white;
  background-color: var(--jrs-red);
}

.jrs-modal-input {
  font-family: "Open Sans";
  font-size: 16px;
  color: var(--jrs-modal-text);
  padding: 8px;
  border: 2px solid var(--jrs-blue);
  border-radius: 4px;
}

.jrs-modal-header {
  padding: 18px;
}

.jrs-section {
  padding: 24px;
  background: #f6fcff;
}

.jrs-modal-heading4 {
  font-weight: 800;
}

.jrs-modal-heading5 {
  font-weight: 600;
  font-size: 18px;
  opacity: 0.6;
  justify-content: center;
}

.jrs-modal-subsection.jrs-column.jrs-cta {
  border-top: 1px solid #d1ebf8;
  padding-top: 12px;
  margin-top: 8px;
  margin-bottom: 18px;
}

.jrs-cta .jrs-modal-heading4 {
  margin: 4px 0 12px;
}
.jrs-modal-subsection.jrs-column.jrs-cta .jrs-modal-line {
  margin: 0 0 8px;
}

.jrs-modal-subsection.jrs-buttons.jrs-row * {
  font-size: 12px;
}

.jrs-modal-spinner-container {
  position: absolute;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.jrs-modal-spinner {
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  height: 120px;
  width: 120px;
}

.jrs-uploadprogress {
  padding: 10px;
}

.jrs-viewrecordings {
  width: 750px;
  min-height: 500px;
  max-height: 650px;
}

.jrs-modal-viewrecordings-header {
  flex-shrink: 0;
}
.jrs-modal-recordings {
  flex-grow: 1;
  min-height: 0px;
}
.jrs-modal-previews {
  background: #e9f7fd;
  padding: 14px 10px;
  flex-shrink: 0;
  width: 160px;
  border-right: 2px solid #def4fd;
}
.jrs-modal-previewsheading {
  flex: 0 0;
  padding-left: 2px;
  margin-bottom: 5px !important;
}
.jrs-modal-previewslist {
  flex-grow: 1;
  min-height: 0px;
}
.jrs-modal-previewslist-content-wrapper {
  flex-grow: 1;
  overflow-y: auto;
}
.jrs-modal-subsection.jrs-column.jrs-modal-previewslist {
    margin-top: 8px;
}
/* .jrs-modal-previewslist-content {
} */
.jrs-modal-feature {
  flex-grow: 1;
  padding: 24px;
}
.jrs-modal-previews .jrs-modal-nextbutton.jrs-active {
  flex: 0 1;
  font-size: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.jrs-modal-previews .jrs-modal-nextbutton-content {
  margin-right: 5px;
}
.jrs-modal-previews .jrs-modal-nextbutton-arrow {
  padding: 0;
  height: 16px;
  width: 16px;
  font-size: 12px;
}

.jrs-modal-preview {
  --jrs-blue: #00a5ff;

  flex: 0 0 auto;
  cursor: pointer;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid #98d9ff;
  overflow: hidden;
}
.jrs-modal-previews {
    padding: 14px 14px;
    width: 170px;
}
.jrs-modal-preview-upper {
  position: relative;
  border-bottom: 2px solid #98d9ff;
  background-color: #98d9ff;
}
.jrs-modal-preview-lower {
  background-color: white;
  flex-direction: column;
  padding: 4px 0 8px 6px;
  justify-content: center;
  flex: 1 1;
}
.jrs-modal-preview-length {
  position: absolute;
  bottom: 3px;
  right: 3px;
  padding: 0px 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  box-shadow: 0px 0px 5px 0px rgba(103, 202, 255, 0.7);
  border-radius: 3px;
  background-color: white;
  color: #3c6176;
}
.jrs-modal-preview-title {
  font-size: 13px;
  font-weight: 600;
  color: #3c6176;
}
.jrs-modal-preview-createdat {
  font-size: 11px;
  color: rgba(60, 97, 118, 0.5);
}
.jrs-modal-preview:hover {
  border: 2px solid #67c9ff;
  box-shadow: 0px 0px 6px 0px rgba(103, 202, 255, 0.7);
}
.jrs-modal-preview:hover .jrs-modal-preview-upper {
  border-bottom: 2px solid #67c9ff;
  background-color: #67c9ff;
}
.jrs-modal-preview.jrs-selected {
  border: 2px solid #2db6ff;
  box-shadow: 0px 0px 6px 0px rgba(0, 166, 255, 0.7);
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-upper {
  border-bottom: 2px solid #2db6ff;
  background-color: #2db6ff;
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-lower {
  background-color: #2db6ff;
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-title {
  color: white;
}
.jrs-modal-preview.jrs-selected .jrs-modal-preview-createdat {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
}
.jideshare-sp .jrs-modal-preview-upper {
  max-height: 105px;
}

.jrs-modal-preview.jrs-modal-createnewbtn {
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: #98d9ff;
}
.jrs-modal-preview.jrs-modal-createnewbtn:hover {
  color: #67c9ff;
}
.jrs-modal-preview.jrs-modal-createnewbtn.jrs-selected {
  background-color: var(--jrs-blue);
  color: white;
}

.jrs-modal-selectedvideo {
  flex-grow: 1;
}
.jrs-modal-selectedvideo > .jrs-modal-video {
  border: 2px solid rgba(206, 237, 255, 1);
  background-color: rgba(206, 237, 255, 0.2);
  box-shadow: 0px 0px 6px 0px rgba(206, 237, 255, 0.6);
  max-height: 350px;
}
.jrs-modal-selectedvideo .jrs-modal-heading3 {
  margin-bottom: 3px !important;
}
.jrs-modal-selectedvideo .jrs-modal-selectedvideo-metadata > .jrs-modal-subsection {
  margin-right: 30px;
}
.jrs-modal-selectedvideo .jrs-modal-input {
  flex-grow: 1;
}
.jrs-modal-selectedvideo .jrs-modal-titlebutton {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 5px;
  padding: 0px 8px;
  margin-right: 10px;
  box-shadow: 0px 0px 4px 0px rgba(50, 82, 101, 0.2);
  height: 22px;
}
.jrs-modal-selectedvideo .jrs-modal-titlebutton:hover {
  box-shadow: 0px 0px 4px 0px rgba(50, 82, 101, 0.5);
}
.jrs-modal-selectedvideo .jrs-modal-titlebutton {
    border: 1px solid;
    height: 28px;
    width: 28px;
    padding: 0;
    border: 2px solid;
}
.jrs-modal-titlebutton.jrs-modal-edittitle {
  color: rgba(0, 166, 255, 0.5);
}
.jrs-modal-titlebutton.jrs-modal-deletevideo {
  color: rgba(211, 56, 56, 0.5);
}

.jrs-deletevideo-splash {
  flex-grow: 1;
  border-radius: 10px;
  background-color: #fff3f3;
  align-items: center;
  justify-content: center;
  color: var(--jrs-red);
  font-weight: 600;
}
.jrs-deletevideo-splash-icon {
  font-size: 40px;
}
.jrs-deletevideo-splash-message {
  font-size: 20px;
}
.jrs-deletevideo-splash-text {
  font-size: 13px;
}
.jrs-deletevideo-splash-btns {
  width: 100%;
  padding: 0px 10px;
}

.jrs-modal-createnew {
  flex-grow: 1;
}
.jrs-modal-createnew .jrs-modal-line-icon {
    margin-right: .5rem;
    justify-content: center;
}
.jrs-modal-createnew .jrs-modal-line-icon svg * {
    fill: #86b6cf;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column {
  margin-bottom: 12px;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column:last-child {
  margin-top: auto;
  margin-bottom: 0px;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column img {
  width: 100%;
}
.jrs-modal-createnew .jrs-modal-subsection.jrs-column.jrs-cta .jrs-bold {
    margin-right: .5rem;
}
.jrs-viewrecordings .jrs-modal-cards {
  margin-top: 20px;
}
.jrs-viewrecordings .jrs-modal-cards {
    margin-top: 8px;
}
.jrs-saverec .jrs-column:not(.jrs-section) {
    padding: 14px;
}
.jrs-intro {
  width: 500px;
}
.jrs-intro-upper {
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.jrs-intro-lower {
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.jrs-intro-lower > div {
  text-align: center;
}

.jrs-displaymediarequest {
  width: 700px;
}
.jrs-displaymediarequest-instructions .jrs-modal-subsection .jrs-modal-heading2 {
  flex: 0 1;
}
.jrs-displaymediarequest-instructions > div {
  flex: 1 1;
}
.jrs-displaymediarequest-instructions > .jrs-modal-gif {
  height: 250px;
}
.jrs-displaymediarequest.jrs-waiting {
  position: absolute;
  padding: 10px;
  top: 0px;
  width: 300px;
  height: auto;
  z-index: 1000;
  border-radius: 12px;
  margin: 1rem;
  background: linear-gradient(45deg, #084361, #016ea7);
  border: 2px solid #237ba9;
  box-shadow: 0px 10px 40px -10px #031e2c;
  color: #cfdee7;
  left: 0;
}
.jrs-displaymediarequest.jrs-waiting > .jrs-modal-section {
  padding: 0px;
  height: 100%;
  flex-direction: column;
}
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-upper {
  margin-right: 0px;
}
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-upper > .jrs-modal-gif {
  width: 100%;
  max-width: 580px;
  align-items: flex-start;
}
/* .jrs-displaymediarequest.jrs-waiting .jrs-waiting-left > .jrs-modal-gif > .fitvid {
  justify-content: flex-end;
} */
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-lower {
  padding-bottom: 10px;
}
.jrs-displaymediarequest.jrs-waiting .jrs-waiting-lower .jrs-modal-heading1 {
  align-self: flex-start;
}

.jrs-displaymediarequest-instructions .sm * {
  color: #8ba1ac;
  font-size: 11px !important;
}
.jrs-displaymediarequest-instructions .jrs-modal-line span {
  flex-direction: column;
  display: flex;
  font-size: 13px;
}

.jrs-displaymediarequest-instructions .jrs-modal-line span:not(.jrs-bold) {
  font-size: 13px;
}

.jrs-displaymediarequest-instructions .jrs-modal-line span.jrs-bold {
  font-weight: 700;
  margin-bottom: 4px;
}
.jrs-modal-subsection.jrs-row.jrs-displaymediarequest-instructions .jrs-modal-line-icon {
    margin-right: .5rem;
}
.jrs-waiting-right .jrs-modal-line > span {
  flex-direction: column;
  display: flex;
}
.jrs-waiting-right .jrs-modal-line > span > :nth-child(2) {
  margin-top: 6px;
  color: #c1cfd7;
}

.jrs-waiting-right * {
  color: white;
  text-align: center;
}

.jrs-waiting-right .jrs-modal-line {
  justify-content: center;
}

.jrs-usermediarequest .jrs-modal-gif {
  justify-content: center;
}

.jrs-bar {
  position: relative;
  display: none !important;
  height: 50px;
  background-color: var(--jrs-blue);
  flex-grow: 1;
  z-index: 1002;
  justify-content: center;
}
.jrs-bar.jrs-red {
  background-color: var(--jrs-red);
}
.jrs-bar.jrs-expanded {
  display: flex !important;
}
.jrs-bar-button {
  cursor: pointer;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  opacity: 0.9;
}
.jrs-bar-button:hover {
  opacity: 1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.jrs-bar-button-left {
  align-items: center;
  margin-right: 10px;
  font-size: 15px;
}
.jrs-bar-button-right {
  align-items: center;
}
.jrs-bar-button.jrs-cancel {
  margin-right: 15px;
  background-color: #325265;
  border: 2px solid #608ea9;
}

.jrs-bar-button.jrs-start {
  margin-right: 15px;
  background-color: #981313;
  border: 2px solid #ff4444;
  animation: fadeInJrsStart ease 4s;
  -webkit-animation: fadeInJrsStart ease 4s;
  -moz-animation: fadeInJrsStart ease 4s;
}
@keyframes fadeInJrsStart {
  50% {
    border-color: #ebc3c3;
    background-color: white;
    color: #981313;
  }
}
@-webkit-keyframes fadeInJrsStart {
  50% {
    border-color: #ebc3c3;
    background-color: white;
    color: #ff4444;
  }
}

.jrs-bar-button.jrs-stop {
  position: absolute;
  right: 15px;
  background-color: #981313;
  border: 2px solid #ff4444;
}
.jrs-bar-button.jrs-duration {
  cursor: initial;
  font-size: 16px;
  margin-right: 0px;
}
.jrs-bar-button.jrs-duration:hover {
  opacity: 0.9;
  box-shadow: none;
}
.jrs-bar-button.jrs-maxduration {
  cursor: initial;
  font-size: 14px;
  padding-left: 0px;
  margin-right: 10px;
  opacity: 0.5;
}
.jrs-bar-button.jrs-maxduration:hover {
  opacity: 0.5;
  box-shadow: none;
}

.jrs-uservideo-container {
  position: absolute;
  z-index: 3;
  bottom: 15px;
  left: 15px;
  height: 200px;
  width: 200px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 3px solid var(--jrs-blue);
  /* box-shadow: 0px 0px 4px 3px var(--jrs-blue); */
  background-color: var(--jrs-blue);
  transition: 0.5s;
}
.jrs-uservideo-container:hover {
  opacity: 0.3;
}
.jrs-red .jrs-uservideo-container {
  background-color: var(--jrs-red);
  border: 3px solid var(--jrs-red);
  bottom: 65px;
}
.jrs-uservideo-container video {
  height: 100%;
}
.jrs-uservideo {
  position: absolute;
  z-index: 1002;
}
.jrs-uservideo-background {
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.jrs-uservideo-background img {
  height: 90%;
  width: 90%;
}

.jrs-options {
  position: absolute;
  align-items: center;
  left: 230px;
  bottom: 7px;
  z-index: 5;
  transition: 0.6s;
}
.jrs-red .jrs-options {
  left: 40px;
}

.jrs-bar-logo {
  height: 100%;
  align-items: center;
}

.jrs-controls {
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  z-index: 4;
}

@media only screen and (max-height: 1100px) {
  .jrs-uservideo-container {
    height: 180px;
    width: 180px;
    border-radius: 90px;
  }
  .jrs-options {
    margin-left: 210px;
  }
}
@media only screen and (max-height: 900px) {
  .jrs-uservideo-container {
    height: 150px;
    width: 150px;
    border-radius: 75px;
  }
  .jrs-options {
    margin-left: 180px;
  }
}
@media only screen and (max-height: 600px) {
  .jrs-uservideo-container {
    height: 110px;
    width: 110px;
    border-radius: 55px;
  }
  .jrs-options {
    margin-left: 140px;
  }
}

.jrs-selector {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.jrs-selector-button {
  cursor: pointer;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  margin-right: 15px;
  opacity: 0.85;
}
.jrs-selector-button.jrs-selected {
  color: var(--jrs-blue);
  background-color: white;
}
.jrs-selector-button.jrs-enabled:hover {
  opacity: 1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.jrs-selector-button.jrs-disabled {
  display: none;
}
.jrs-selector-devicelist {
  flex-direction: column;
  position: absolute;
  z-index: 6;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 2px solid var(--jrs-blue);
  border-radius: 5px;
  color: var(--jrs-modal-text);
  font-size: 14px;
  width: 300px;
}
.jrs-selector-0 .jrs-selector-devicelist {
  bottom: 60px;
}
.jrs-selector-1 .jrs-selector-devicelist {
  bottom: 60px;
}
.jrs-selector-deviceheader {
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
}
.jrs-selector-deviceheader-left {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.jrs-selector-deviceheader-right {
  flex-grow: 1;
  font-size: 14px;
  align-items: center;
  font-weight: 800;
}
.jrs-selector-device {
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
}
.jrs-selector-device:hover {
  padding: 10px;
  background-color: rgba(0, 166, 255, 0.1);
}
.jrs-selector-device.jrs-selected {
  font-weight: 600;
  color: var(--jrs-blue);
  background-color: rgba(0, 166, 255, 0.3);
}
.jrs-selector-device.jrs-unusable {
  color: #b60000;
}

.jsb {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--jsb-bg);

  --jsb-jide-darkblue: #0f3a52;
  --jsb-bg: rgba(36, 37, 47, 1);
  --jsb-bg-light: rgb(48, 51, 63);
  --jsb-white: rgba(250, 250, 240, 1);
  --jsb-white-faint: rgba(250, 250, 240, 0.05);
  --jsb-white-light: rgba(250, 250, 240, 0.3);
  --jsb-white-medium: rgba(250, 250, 240, 0.6);
}
.jsb.jsb-expanded {
  min-width: 310px;
}
.jsb.jsb-closed {
  width: 62px;
}
.jsb div {
  box-sizing: border-box;
}

.jsb-bar {
  cursor: pointer;
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  background-color: var(--jsb-jide-darkblue);
  color: var(--jsb-white-medium);
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 22px;
}
.jsb-bar:hover {
  color: var(--jsb-white);
}
.jsb-fullscreen {
  width: 200px;
  z-index: 10;
}
.jsb-bar-text {
  margin-left: 12px;
  font-size: 16px;
}

.jsb-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
}

.jsb-tabs {
  display: flex;
  flex-direction: column;
  background-color: var(--jsb-bg);
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
}
.jsb-expanded .jsb-tabs {
  border: 0px;
  width: 60px;
}
.jsb-closed .jsb-tabs {
  border-right: 2px solid var(--jsb-bg-light);
  width: 62px;
}
.jsb-tab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 44px;
  border-radius: 3px;
  margin-bottom: 5px;
}
.jsb-tab.jsb-tab-active {
  color: var(--jsb-bg);
  background-color: var(--jsb-white);
}
.jsb-tab.jsb-tab-inactive {
  color: var(--jsb-white-light);
}
.jsb-tab.jsb-tab-inactive:hover {
  color: var(--jsb-white-medium);
  background-color: var(--jsb-white-faint);
}
.jsb-tab.jsb-tab-disabled,
.jsb-tab.jsb-tab-disabled:hover {
  cursor: not-allowed;
}

.jsb-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.jsb-closed .jsb-content {
  display: none;
}
.jsb-content-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.jsb-content-pane {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--jsb-bg-light);
}
.jsb-content-pane.jsb-content-active {
  z-index: 3;
}
.jsb-content-pane.jsb-content-inactive {
  z-index: 1;
  display: none;
}
.jsb-content-pane-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px;
}
.jsb-content-scrollable {
  /* make the pane scrollable */
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
}
.jsb-content-pane-notice {
  display: flex;
  flex-direction: row;
  padding: 1em;
  border-radius: 3px;
  margin-top: 10px;
  background-color: rgba(40, 42, 54, 0.5);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  align-items: center;
  justify-content: center;
}
.jsb-content-pane-loading {
  font-size: 18px;
}

.jsb-content-card {
  display: flex;
  flex-direction: row;
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 15px;
  letter-spacing: 0.3pt;
  font-family: 'Open Sans', sans-serif;
  color: var(--jsb-white);
  background-color: var(--jsb-white-faint);
  overflow: hidden;
}

.jsb-file {
  padding-left: 0px;
  letter-spacing: 0pt;
}
.jsb-file:last-child {
  margin-bottom: 0px;
}
.jsb-file-left {
  display: flex;
  width: 45px;
  min-width: 45px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.jsb-file-mid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
}
.jsb-file-mid-upper {
  font-size: 14px;
}
.jsb-file-mid-lower {
  font-size: 10px;
}
.jsb-file-filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.jsb-file-right {
  display: flex;
  width: 30px;
  min-width: 30px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  visibility: hidden;
}
.jsb-file.jsb-file-active {
  color: var(--jsb-bg-light);
  background-color: var(--jsb-white);
  border-radius: 3px;
}
/* .jsb-file:hover .jsb-file-right {
  visibility: visible;
}
.jsb-file-right:hover {
  color: rgba(255, 100, 100, 0.5) !important;
} */

.jsb-file-mgmt {
  flex-direction: column;
  border-radius: 3px;
  padding: 10px 0px;
}
.jsb-file-mgmt-top {
  flex-direction: column;
  display: flex;
  margin-bottom: 10px;
}
.jsb-file-mgmt-top input {
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  border: none;
  color: var(--jsb-bg-light);
  background-color: var(--jsb-white);
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  letter-spacing: 0.3pt;
}
.jsb-file-mgmt-top input.jsb-invalid {
  color: #d52224;
}
.jsb-file-mgmt-bottom {
  display: flex;
}
.jsb-file-mgmt-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  flex-grow: 1;
  background-color: var(--jsb-white-faint);
  color: var(--jsb-white-light);
  border-radius: 3px;
  margin-right: 10px;
}
.jsb-file-mgmt-button:last-child {
  margin-right: 0px;
}
.jsb-file-mgmt-button:hover {
  background-color: var(--jsb-white-light);
  color: var(--jsb-white);
}
.jsb-file-mgmt-button.jsb-active {
  background-color: var(--jsb-white);
  color: var(--jsb-bg-light);
}

.jsb-icontext {
  padding-left: 0px;
}
.jsb-icontext-left {
  display: flex;
  width: 45px;
  align-items: center;
  justify-content: center;
}
.jsb-icontext-right {
  display: inline-block;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  word-wrap: break-word;
}

.jsb-text {
}

.jsb-content-header {
  font-family: 'Gotham', sans-serif;
  font-size: 14px;
  color: var(--jsb-white);
  letter-spacing: 2px;
  padding-left: 5px;
  margin-bottom: 5px;
}
.jsb-content-header-small {
  font-family: 'Gotham', sans-serif;
  font-size: 11px;
  color: var(--jsb-white);
  letter-spacing: 2px;
  padding-left: 2px;
  margin-bottom: 5px;
}

.jsb-space {
  height: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.jsb-toggle {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 60px;
  height: 30px;
  margin-top: 10px;
  padding-left: 15px;
}
.jsb-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.jsb-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.jsb-slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .jsb-slider {
  background-color: #3da5ff;
}
input:focus + .jsb-slider {
  box-shadow: 0 0 1px #3da5ff;
}
input:checked + .jsb-slider:before {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
.jsb-slider.jsb-round {
  border-radius: 30px;
}
.jsb-slider.jsb-round:before {
  border-radius: 50%;
}

.jsb-hardreset {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 2px solid #d52224;
  padding: 10px 0px;
  color: #d52224;
  font-family: 'Gotham', sans-serif;
  letter-spacing: 0.3pt;
  font-size: 11px;
  opacity: 0.7;
  margin-top: auto;
  margin-bottom: 10px;
}
.jsb-hardreset:hover {
  opacity: 1;
  background-color: #d52224;
  color: var(--jsb-white);
}
.jsb-hardreset div {
  display: flex;
  box-sizing: border-box;
}
.jsb-hardreset-top {
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.jsb-hardreset-bottom {
  align-items: center;
  justify-content: center;
}
/* overrides */
.ace-monokai .ace_gutter { 
    background: #243743!important;
    color: #627079!important;
}

.ace-monokai {
    background: #172833!important;
}

.jsb {
    --jsb-bg: #2a404f!important;
}

.jsb-closed .jsb-tabs {
    border-right: 2px solid #334856!important;
}

.ace-monokai .ace_gutter-active-line {
    background-color: #122028!important;
}

.ace-monokai .ace_marker-layer .ace_active-line {
    background: #0e1b22!important;
}

.ace-monokai {
    background: #1e2931!important;
}
.ace-monokai .ace_gutter {
    background: #242f37!important;
    color: #5f6971!important;
}

.jsb {
    --jsb-bg: #2d3942!important;
}

.jsb-closed .jsb-tabs {
    border-right: 2px solid #27333c!important;
}

.jide-tb {
  overflow: hidden;
  z-index: 4;
  height: 40px;
  background-color: #292563;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.jide-tb:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-bottom: 1px solid #00314b;
    pointer-events: none;
    z-index: 0;
}
.jide-tb.playground {
  background: #174d6c;
}
.jide-tb.playground .jide-tab-mid {
  padding: 5px 10px 9px;
}
.jide-tb .return-to-playground__button {
  display: flex;
  flex: 1 0 auto;
  height: 2.1rem;
}
.jide-tb .bottom-bar {
    margin: 0;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    border-color: #00314b52;
}
.jide-tb div {
  display: flex;
  box-sizing: border-box;
}
.jide-tb > div {
  padding-top: 7px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  align-items: flex-end;
}
.jide-tb.playground > div {
  position: unset;
}
.jide-tab {
  color: white;
  align-self: flex-end;
  z-index: 1;
  cursor: pointer;
  flex-shrink: 0;
}
.jide-tab * {
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
}
.jide-tab:not(.active) {
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px)
}
.jide-tab:not(.active) * {
    font-weight: 200;
}
.jide-tb.playground .jide-tab-mid {
  padding: 11px 11px 11px 0;
}
.jide-tab-mid {
  flex-direction: row;
  flex: 1 1;
  padding: 7px 9px 7px 0;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid hsl(201deg 100% 15%);
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  font-family: 'Gotham', sans-serif;
  font-size: 12px;
  font-weight: normal;
  background: hsl(202, 97%, 37%);
  color: rgba(255, 255, 255, 0.7);
}
.jide-tab-mid.teacher-view-mode {
  padding: 9px;
}
.jide-tab:not(.active):hover .jide-tab-mid {
  transition: color 0.2s;
  color: rgba(255, 255, 255, 0.8);
}
.jide-tab.active .jide-tab-mid {
  background: hsl(201, 69%, 19%);
  border: 1px solid hsl(201deg 100% 15%);
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
  padding: 6px 10px 8px;
  color: white;
}
.jide-tab.math.active .jide-tab-mid,
.jide-tab.english.active .jide-tab-mid {
  background: hsl(197, 100%, 99%);
  border: 1px solid hsl(197, 100%, 99%);
  color: hsl(208, 14%, 24%);
}
.jide-tab.scratch.active .jide-tab-mid,
.jide-tab.web.active .jide-tab-mid {
  background: hsl(201, 100%, 97%);
  border: 1px solid hsl(201, 100%, 97%);
  color: hsl(208, 14%, 24%);
}
.jide-tab-mid div {
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.jide-tab-mid-text {
  overflow: hidden;
  padding-right: 16px;
  padding-left: 16px;
}
.jide-tab-mid-x {
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0);
}

/* Close tab button styles */

button.jide-close-tab {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
button.jide-close-tab .icon {
  background: rgba(0, 0, 0, 0.17);
  border-radius: 50%;
  height: 6px;
  width: 6px;
  padding: 7px;
}
button.jide-close-tab .icon path {
  stroke: rgba(255, 255, 255, 0.5);
  transition: stroke 0.2s;
}
.jide-tab.active button.jide-close-tab .icon {
  background: rgba(255, 255, 255, 0.17);
}
.jide-tab.active button.jide-close-tab .icon path {
  stroke: rgba(255, 255, 255, 0.8);
}
.jide-tab.math.active button.jide-close-tab .icon,
.jide-tab.english.active button.jide-close-tab .icon,
.jide-tab.scratch.active button.jide-close-tab .icon,
.jide-tab.web.active button.jide-close-tab .icon {
  background: hsl(208, 38%, 75%);
}
button.jide-close-tab:hover {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  box-shadow: none;
}
button.jide-close-tab:hover .icon path,
.jide-tab.active button.jide-close-tab:hover .icon path {
  stroke: rgba(255, 255, 255, 1);
}

button.jide-recording-tab {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  color: #d33838;
}

/* Styles to create slopes on the left and right sides of tabs */

.jide-tab .jide-tab-right,
.jide-tab .jide-tab-left {
  position: relative;
  width: 10px;
}
.jide-tab .jide-tab-right > div,
.jide-tab .jide-tab-left > div {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  bottom: 0;
}
.jide-tab.active .jide-tab-right > div,
.jide-tab.active .jide-tab-left > div,
.jide-tab.active .jide-tab-mid {
    background: #0f3a52;
}
.jide-tab .jide-tab-right > div,
.jide-tab .jide-tab-left > div,
.jide-tab .jide-tab-mid {
    background: #1c6388;
}
.jide-tab .jide-tab-right > div {
    border: 0;
    width: 20px;
    height: 100%;
    position: absolute;
    left: -16px;
    border-right: 1px solid #00314b;
    top: 7px;
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
}
.jide-tab-left, .jide-tab-right {
    overflow: hidden;
}
.jide-tab .jide-tab-left > div {
  border: 0;
  width: 20px;
  height: 100%;
  position: absolute;
  right: -16px;
  border-left: 1px solid #00314b;
  top: 7px;
  -webkit-transform: rotate(12deg);
          transform: rotate(12deg);
}

.jide-tab.math.active .jide-tab-right > div,
.jide-tab.math.active .jide-tab-left > div,
.jide-tab.math.active .jide-tab-mid,
.jide-tab.english.active .jide-tab-right > div,
.jide-tab.english.active .jide-tab-left > div,
.jide-tab.english.active .jide-tab-mid {
    background: white;
}
.jide-tab.math.active .jide-tab-left > div,
.jide-tab.english.active .jide-tab-left > div {
  border-color: transparent transparent hsl(197, 100%, 99%) transparent;
}
.jide-tab.math.active .jide-tab-right > div,
.jide-tab.english.active .jide-tab-right > div {
  border-color: transparent transparent transparent #fff;
}
.jide-tab.scratch.active .jide-tab-left > div,
.jide-tab.web.active .jide-tab-left > div {
  border-color: transparent transparent hsl(201, 100%, 97%) transparent;
}
.jide-tab.scratch.active .jide-tab-right > div,
.jide-tab.web.active .jide-tab-right > div {
  border-color: transparent transparent transparent hsl(201, 100%, 97%);
}

/* Edit mode warning message styles for modalViewMode */

.jide-tb .edit-mode-warning {
  min-height: 0;
  margin-right: 48px;
  align-self: center;
  font-family: 'Gotham', sans-serif;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
}

.jide-tb .edit-mode-warning .icon {
  background: hsl(0, 72%, 49%);
  margin: 0 10px;
  padding: 10px;
}
.jide-tb .edit-mode-warning .icon path {
  fill: #fff;
}

.jide-video-player {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  border-radius: 10px;
}
.jide-video-player .header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(201, 100%, 21%);
  padding: 9px;
  cursor: move;
}
.jide-video-player .video-title {
  text-align: center;
  color: #fff;
  margin: 0 auto;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jide-video-player .header .buttons {
  display: flex;
  align-items: center;
}
.jide-video-player .header .buttons.left button {
  margin-right: 1rem;
}
.jide-video-player .header .buttons.right button {
  margin-left: 1rem;
}
.jide-video-player .header button {
  box-shadow: none;
  padding: 0;
  border-radius: 4px;
  background: hsl(201, 66%, 29%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  transition: all 0.2s;
}
.jide-video-player .header button:hover {
  -webkit-transform: scale(1.1) perspective(1px) translateZ(0);
          transform: scale(1.1) perspective(1px) translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.jide-video-player .header .icon {
  height: 8px;
  width: 8px;
  padding: 0;
}
.jide-video-player .header button.go-back-btn .icon {
  height: 10px;
  width: 10px;
}
.jide-video-player .header button path {
  transition: all 0.2s;
}
.jide-video-player .header button.go-back-btn path {
  fill: rgba(255, 255, 255, 0.7);
}
.jide-video-player .header button.go-back-btn:hover path {
  fill: rgba(255, 255, 255, 1);
}
.jide-video-player .header button.exit-cur-video-player path {
  stroke: rgba(255, 255, 255, 0.7);
}
.jide-video-player .header button.exit-cur-video-player:hover path {
  stroke: rgba(255, 255, 255, 1);
}
.jide-video-player .video-container {
  background: hsl(201, 100%, 14%);
}

.video-duration {
  color: hsl(201, 100%, 75%);
  background: hsl(204, 100%, 95%);
  border: 1px solid hsl(203, 100%, 89%);
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.video-duration .icon {
  line-height: 0;
  display: block;
  border-right: 1px solid hsl(203, 100%, 89%);
  height: 9px;
  width: 9px;
  padding: 4px;
}
.video-duration .icon path {
  fill: hsl(201, 100%, 75%);
}
.video-duration .duration-text {
  width: 36px;
  text-align: center;
  font-size: .7rem;
}

@media only screen and (min-width: 720px) {
  .video-duration .icon {
    height: 11px;
    width: 11px;
  }
}

.jide-videos-widget.left-corner {
  left: 16px;
  right: unset;
}
.jide-videos-widget .video-container {
  position: relative;
  padding-top: 56.25%;
}
.jide-videos-widget .video-spinner-container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jide-videos-widget .video-container .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jide-videos-widget .video-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

/********** Styles for Video Selector **********/

.jide-videos-widget .video-selector {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: hsl(201, 100%, 96%);
  border-radius: 35px;
  box-shadow: 0 10px 34px -10px #5e8db469;
  border: 2px solid #e3f2ff;
}
.jide-videos-widget .video-selector .header {
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ddefff;
  min-height: 54px;
}
.jide-videos-widget .video-selector .header .module-name {
  color: hsl(202, 94%, 19%);
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  max-width: calc(100% - 108px);
  line-height: 20px;
}
.jide-videos-widget .video-selector .header .module-name.no-videos {
  max-width: 200px;
}
.jide-videos-widget .close-video-select-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
}
.jide-videos-widget button.close-video-select {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 0;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  background: initial;
  border: none;
  box-shadow: initial;
}
.jide-videos-widget button.close-video-select:hover {
  -webkit-transform: scale(1.05) perspective(1px) translateZ(0);
          transform: scale(1.05) perspective(1px) translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.jide-videos-widget button.close-video-select .icon {
  width: 12px;
  height: 12px;
  padding: 0;
}
.jide-videos-widget button.close-video-select .icon path {
  stroke: #839fb9;
}
.jide-videos-widget .video-selector .content {
  display: flex;
  align-items: flex-start;
  padding: 0 1rem;
}
.jide-videos-widget .video-selector button.select-module {
  display: flex;
  flex-direction: column;
  text-transform: none;
  font-weight: bold;
  padding: 0;
  align-items: stretch;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
  height: 112.5px;
  width: 65px;
  transition: all 0.3s;
  box-shadow: none;
}
.jide-videos-widget .video-selector button.select-module:not(:disabled) {
  background: hsl(201, 100%, 55%);
}
.jide-videos-widget .video-selector button.select-module:not(:disabled):hover {
  box-shadow: 0px 10px 30px #b5d2ec;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.jide-videos-widget .video-selector button.select-module .module-name {
  padding: 12px;
  text-align: center;
  min-height: 41px;
  box-sizing: border-box;
}
.jide-videos-widget .video-selector button.select-module:disabled .module-name {
  background: #e8e8e8;
}
.jide-videos-widget
  .video-selector
  button.select-module:not(:disabled)
  .module-name {
  background: hsl(201, 100%, 50%);
}
.jide-videos-widget .video-selector button.select-module .icon {
  height: 16px;
  width: 16px;
  padding: 12px;
  flex: 1 1;
  align-self: center;
}
.jide-videos-widget .video-selector button.select-module .icon polyline {
  stroke: #fff;
}
.jide-videos-widget .video-selector button.select-module:disabled .icon polyline {
  stroke: #ddd;
}
.jide-videos-widget .video-selector .video-options {
  display: flex;
  flex: 1 1;
  min-height: 180.5px;
  max-width: 632px;
  overflow-x: scroll;
  margin: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
}
.jide-videos-widget .video-selector .video-options.scrollable {
  margin-bottom: 2px;
}
.jide-videos-widget .video-selector .video-options:first-child {
  margin-left: 0;
}
.jide-videos-widget .video-selector .video-options.scrollable::-webkit-scrollbar {
  height: 8px;
  -webkit-appearance: none;
}
.jide-videos-widget .video-selector .video-options.scrollable {
  /* For Firefox 64+ */
  scrollbar-color: rgba(2, 36, 63, 0.3) hsl(208, 100%, 53%);
}
.jide-videos-widget
  .video-selector
  .video-options.scrollable::-webkit-scrollbar-thumb {
  background: rgba(2, 36, 63, 0.3);
  border-radius: 4px;
}
.jide-videos-widget .video-selector .loading-videos-spinner-container,
.jide-videos-widget .video-selector .no-videos-msg {
  width: 200px;
  height: 112.5px;
  box-sizing: border-box;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jide-videos-widget .video-selector .no-videos-msg {
  color: hsl(201, 27%, 52%);
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  background: #dbeeff;
  border: none;
  letter-spacing: 0.5px;
}

/********** Styles for individual Video Options **********/

.jide-video-option {
  margin-right: 1rem;
  padding-bottom: 1rem;
  width: 200px;
  flex-shrink: 0;
}
.jide-video-option:last-child {
  margin-right: 0;
}
.jide-video-option .video-container {
  border-radius: 10px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.jide-video-option .video-container .video-overlay {
  background: linear-gradient(
    hsla(207, 100%, 13%, 0.65),
    hsla(207, 100%, 24%, 0.65)
  );
}
.jide-video-option .video-container .video-overlay .icon {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  padding: 20px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.jide-video-option .video-container .video-overlay .icon path {
  stroke: #fff;
}
.jide-video-option .video-container .video-overlay:hover .icon path {
  stroke: hsl(201, 100%, 50%);
}
.jide-video-option .video-container iframe {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.jide-video-option .video-container:hover iframe {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
.jide-video-option .video-info {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
}
.jide-video-option .video-title {
  font-size: 12px;
  font-weight: bold;
  color: hsl(202, 94%, 19%);
  margin-right: 1rem;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
}
.jide-video-option .video-title:hover {
  color: hsl(201, 100%, 50%);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.jide-video-option .video-duration {
  margin-left: auto;
}
.jide-video-option .video-duration .icon {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.jide-video-option .video-duration .duration-text {
  font-size: 10px;
  width: 40px;
}

.jideshare {
  box-sizing: border-box;
  z-index: 6;
  font-size: 0.875rem;
}

.jideshare-button-left {
  padding-top: 1px;
  margin-right: 8px;
}
.jideshare-button-right {
  padding-top: 2px;
  font-size: 15px;
}

.jideshare-modal {
  display: flex;
  position: absolute;
  bottom: 4rem;
  right: 0;
  min-width: 390px;
  width: 470px;
  max-width: 470px;
  border-radius: 10px;
  background-color: white;
  overflow-x: visible;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 5;
  border: 2px solid #c5eaff;
  box-shadow: 0px 8px 12px rgb(2 56 86 / 26%);
}
.jideshare-modal div {
  display: flex;
  box-sizing: border-box;
}

.jideshare-spinner {
  display: flex;
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

/***** username ******/

.jideshare-pick-username {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.jideshare-pick-username .top h2 {
  color: #3c6176;
  font-size: 1.5rem;
}

.jideshare-pick-username .bottom {
  padding: 0.5rem 1rem;
  flex-direction: column;
}
.jideshare-pick-username .username-instructions {
  color: #3c6176;
  font-size: 0.875rem;
  font-weight: 600;
}
.jideshare-pick-username .username-instructions-subtext {
  color: #4c6e81;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.jideshare-sp {
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.jideshare-sp-featured-section {
  padding: 0 0.5rem;
  color: #fff;
  background: #007cc9;
  font-weight: 600;
}

.jideshare-sp-featured-section a {
  color: #fff;
}

.jideshare-sp-share-link {
  width: 100%;
  flex-direction: column;
  padding: 10px 15px 15px;
  background: #115289;
  color: white;
  border: 0;
}
.jideshare-sp-share-link .jideshare-large-text {
  color: white;
  font-size: 0.875rem;
  margin: 0.25rem 0 0.75rem;
  font-weight: 600;
  line-height: 170%;
}
.jideshare-sp-makepublic {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0;
}

.jideshare-sp-upper {
  width: 100%;
  flex-direction: column;
}
.username__container {
  margin-bottom: 0.5rem;
  padding: 0.9rem;
  border-bottom: 2px solid #dff1fb;
  flex-direction: column;
}
.username__container .top {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
}
.username__container .top h2.username-header,
.username__container .top .username-group {
  display: flex;
  flex-direction: column;
  color: #3c6176;
  flex-basis: 100%;
  margin: 0;
  margin-left: auto;
  margin-right: 1rem;
  font-size: 1.125rem;
  letter-spacing: 0;
  white-space: nowrap;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.username-group:hover {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}
.username__container .top .username-group span.username {
  margin-left: auto;
  font-size: 0.75rem;
}
.username__container .top .username-group span.profile-link {
  margin-left: auto;
  font-size: 0.75rem;
}

.jideshare-sp-upper .project-name__container {
  padding: 0rem 0.9rem 0.9rem;
  flex-direction: column;
  border-bottom: 2px solid #dff1fb;
  margin-bottom: 8px;
}
.jideshare-sp-upper p,
.jideshare-sp-upper .project-name__container p {
  margin: 0;
  font-weight: 600;
}
input.jideshare-input-text-box {
  border-width: 2px;
  border-radius: 8px;
  margin: 0;
}
input.jideshare-input-text-box:focus {
  background: #fbfdff;
  border: 2px solid #00a5ff;
  box-shadow: inset 0px 4.28837px 14.5805px rgba(41, 95, 124, 0.05);
}

/* Components */
.jideshare-header {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}

.jideshare-text {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: rgba(15, 58, 82, 0.7);
  margin-bottom: 2px;
}
.jideshare-medium-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #4a6471;
  margin-bottom: 3px;
}
.jideshare-large-text {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  color: black;
  margin-bottom: 3px;
}
.jideshare-sp-lower .jideshare-large-text {
  margin: 0px 0 10px;
}
.jideshare-sp-makepublic .jideshare-large-text {
  margin: 0;
  color: #3c6176;
  font-size: 0.75rem;
}
.jideshare-sp-upper > .jideshare-large-text {
  margin-bottom: 8px;
}

.jideshare-space {
  margin-top: 10px;
  padding-top: 10px;
  height: 0;
}

.jideshare-input-text-box-wrapper {
  display: flex;
  flex-direction: column;
}
.jideshare-input-text-box-errormsg {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  color: red;
  margin-bottom: 5px;
}
.jideshare-input-text-box {
  font-family: "Open Sans", sans-serif;
  border: 1px solid rgba(0, 166, 255, 0.3);
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  color: #4a6471;
}
input.jideshare-input-text-box {
  padding: 8px 12px;
  font-size: 14px;
  color: #4a6471;
}
.jideshare-input-text-box:focus {
  border: 1px solid #00a5ff;
  box-shadow: 0px 0px 18px 0px rgba(0, 166, 255, 0.15) inset;
}

.jideshare-toggle {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 60px;
  height: 30px;
  padding-left: 15px;
}
.jideshare-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.jideshare-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.jideshare-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .jideshare-slider {
  background-color: #50e08a;
}
input:focus + .jideshare-slider {
  box-shadow: 0 0 1px #50e08a;
}
input:checked + .jideshare-slider:before {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
.jideshare-slider.jideshare-round {
  border-radius: 30px;
}
.jideshare-slider.jideshare-round:before {
  border-radius: 50%;
}

.jide-submit__container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin: 0;
}

.jide-submit__container #unpublish {
  background: transparent;
  color: #3c6176;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Open Sans";
  font-size: 0.8rem;
  margin: 0 0 0.5rem;
  letter-spacing: 0;
}
.jide-submit__container #unpublish:hover {
  box-shadow: none;
  opacity: 0.7;
}

.jideshare .jideshare-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.06rem;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 0.75rem;
  background-color: #00a5ff;
  color: white;
}
.jideshare-submit-button span {
  margin: 0 auto;
  font-size: 0.875rem;
  padding: 0 12px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.jideshare-submit-button.jideshare-unsaved:hover {
  cursor: pointer;
  background-color: #2fb6ff;
}
.jideshare-submit-button.jideshare-saved {
  opacity: 0.5;
}

.jideshare-link-button {
  width: 100%;
  font-size: 0.875rem;
}
.jideshare-link {
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}
.jideshare-link a {
  text-decoration: none;
}
.jideshare-link-left {
  border-radius: 10px;
  background: rgba(251, 253, 255, 0.35);
  border: 2px solid #00a5ff;
  box-shadow: inset 0px 4.28837px 14.5805px rgba(41, 95, 124, 0.05);
  flex-basis: 50%;
  display: flex;
  color: white;
  align-items: center;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 0px 10px 12px;
  margin: 0;
  text-overflow: ellipsis;
}
.jideshare-link-right {
  display: flex;
  justify-content: space-evenly;
}
.jideshare-link-right .jideshare__button {
  display: flex;
  align-items: center;
  width: 50%;
  margin: 0 auto 0 0.75rem;
  cursor: pointer;
  background: #00a5ff;
  border: 2px solid #5ac5ff;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 700;
  color: white;
  text-transform: capitalize;
  font-family: "Open Sans";
  padding: 0 0.25rem 0 1rem;
  letter-spacing: 0.05em;
}

.jideshare-link-right .jideshare__button span {
  justify-self: center;
  display: flex;
  align-self: center;
  margin-right: 1rem;
}

.jideshare__icons {
  color: white;
  background: #3fbbff;
  border-radius: 6px;
  padding: 0.8rem;
  display: flex;
  margin-left: auto;
  align-self: flex-end;
  width: 0.875rem;
  height: 0.875rem;
}

.jideshare__icons path {
  stroke: white;
}
.jideshare__icons svg {
  fill: white;
}

.jideshare-modal div .buttons__container > :first-child {
  margin-right: 0.5rem !important;
}
.jideshare-modal div .buttons__container > :last-child {
  margin-left: 0.5rem;
}
.jideshare-modal div .buttons__container > * {
  margin: 0;
}

@media only screen and (max-width: 650px) {
  .jideshare-modal {
    width: 90vw;
    right: 0;
  }
}
@media only screen and (max-width: 390px) {
  .jideshare-modal {
    max-width: 100%;
    min-width: 90vw;
  }
}

.jideshare-rec-container {
  padding: 0rem 0.9rem 0.9rem;
  flex-direction: column;
  border-bottom: 2px solid #dff1fb;
  width: 100%;
}
.jideshare-rec-container > p {
  font-weight: 600;
  margin-bottom: 5px !important;
}
.jideshare-rec {
  height: 150px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.jideshare-rec .jrs-modal-preview {
  height: 100%;
  width: 150px;
  margin-right: 10px;
}
.jideshare-rec .jrs-modal-preview .jrs-modal-preview-upper {
  height: 110px;
}
.jideshare-rec .jideshare-rec-new {
  width: 80px !important;
  color: #b5e3fd;
}
.jideshare-rec .jideshare-rec-new:hover {
  background-color: #e8f6fd;
  color: #67c9ff;
}
.jideshare-rec-new.jrs-modal-preview {
  border: 2px solid #27b2ff;
  color: #27b2ff;
  background: #edf9ff;
}
.jideshare-rec .jideshare-rec-new-upper {
  flex-grow: 1;
  align-items: flex-end;
  padding-bottom: 10px;
  justify-content: center;
  text-align: center;
  font-size: 20px;
}
.jideshare-rec .jideshare-rec-new-lower {
  flex-grow: 1;
  padding-top: 10px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.05em;
}
.jideshare-rec-initial {
  height: 50px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.jideshare-rec-initial .jideshare-rec-new {
  width: 140px !important;
  color: #b5e3fd;
  flex-direction: row !important;
}
.jideshare-rec-initial .jideshare-rec-new:hover {
  background-color: #e8f6fd;
  color: #67c9ff;
}
.jideshare-rec-initial .jideshare-rec-new-upper {
  flex-shrink: 0;
  width: 25px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-left: 10px;
}
.jideshare-rec-initial .jideshare-rec-new-lower {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  color: #27b2ff;
  font-weight: 800;
  letter-spacing: 0;
  padding: 0 1px;
}

.ring-spinner {
  display: inline-block;
  position: relative;
}
.ring-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: ring-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: ring-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.ring-spinner div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.ring-spinner div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.ring-spinner div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes ring-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes ring-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.jide {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: transparent;
}

.jide .spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.jide-wrapper-admin.jide-main-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: auto;
}

.jide-wrapper-admin.jide-main-content .jide {
  min-width: 1150px;
}

.jide-wrapper-public {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.jide-wrapper-teacher {
  position: relative;
  height: 100%;
  display: flex;
}

.jide-wrapper-teacher .jide-main-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: auto;
}

.pyturt {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ebf6ff;
  flex-grow: 1;
}
.pyturt.pyturt-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px 10vmax rgba(0, 0, 0, 0.75);
  -webkit-animation: grow 0.25s ease-out;
          animation: grow 0.25s ease-out;
}
@-webkit-keyframes grow {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes grow {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.pyturt.pyturt-horizontal {
  flex-direction: row;
}
.pyturt.pyturt-vertical {
  flex-direction: column;
}

.pyturt-left-pane {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  padding: 0px;
}

.pyturt-code-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0px;
  background: #272822;
}
.pyturt-code-editor-bar {
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
  background-color: #0f3a52;
  color: white;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
}
.pyturt-code-editor-bar-left {
  display: flex;
  height: 100%;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}
.pyturt-code-editor-bar-left-fstoggle {
  display: flex;
  height: 100%;
  width: 60px;
  background-color: #0f3a52;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.6);
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  font-size: 22px;
}
.pyturt-code-editor-bar-left-fstoggle:hover {
  cursor: pointer;
  color: white;
}
.pyturt-code-settings {
  display: flex;
  height: 85%;
  width: 30px;
  font-family: 'Gotham', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  margin-right: 10px;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer;
  background-color: transparent;
  visibility: hidden;
}
.pyturt-code-editor-bar-right {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  align-items: center;
}
.pyturt-code-controls {
  display: flex;
  height: 60%;
  width: 80px;
  font-family: 'Gotham', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: white;
  padding: 0px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}
.pyturt-code-controls > div {
  padding-top: 0px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pyturt-code-controls.pyturt-code-lock {
  width: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  cursor: auto;
}
.pyturt-code-controls.pyturt-code-sync {
  width: 32px;
  background-color: rgb(34 75 98);
  color: rgba(255, 255, 255, 0.7);
}
.pyturt-code-controls.pyturt-code-sync.pyturt-save-error {
  background-color: rgba(230, 102, 104, 0.4);
}
.pyturt-code-controls.pyturt-code-sync.pyturt-is-syncing {
  animation: colorcycle 1s;
  -moz-animation: colorcycle 1s infinite; /* Firefox */
  -webkit-animation: colorcycle 1s infinite; /* Safari and Chrome */
}
@keyframes colorcycle /* default */ {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgb(61, 165, 255, 0.4);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@-webkit-keyframes colorcycle /* Safari and Chrome */ {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: rgb(61, 165, 255, 0.4);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.pyturt-code-controls.pyturt-code-run {
  background-color: #3da5ff;
}
.pyturt-code-controls.pyturt-code-stop {
  background-color: #d52224;
}
.pyturt-code-controls.pyturt-active {
}
.pyturt-code-controls.pyturt-inactive {
  background-color: rgb(34 75 98);
  color: rgb(108 145 166);
  cursor: auto;
}
.pyturt-code-controls.pyturt-code-stop-in-progress {
  background-color: rgba(213, 34, 37, 0.67) !important;
  cursor: auto;
}
.pyturt-code-editor-ace {
  display: flex;
  width: 100%;
  height: 100%;
}

.pyturt-code-editor-ace.new-horizons {
  padding-top: 15px;
  background-color: #282a36;
}

.pyturt-right-pane {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  box-sizing: border-box;
  align-items: stretch;
  background-color: #002b36;
}
.pyturt-code-output {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
}

.pyturt-code-output-bar {
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 50px;
  background-color: #f1fafd;
  justify-content: space-between;
  box-sizing: border-box;
}
.pyturt-code-output-bar-left {
  display: flex;
  height: 100%;
  align-content: center;
}

.pyturt-code-output-bar-left > * {
  border-right: 2px solid #e4f3f8;
}

.pyturt-code-output-bar-right {
  display: flex;
  height: 100%;
}
.pyturt-code-output-tab {
  display: flex;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  color: #002b36;
  z-index: 3;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}
.pyturt-code-output-tab.pyturt-canvas-tab {
}
.pyturt-code-output-tab.pyturt-console-tab {
}
.pyturt-code-output-tab.pyturt-instructions-tab {
}
.pyturt-code-output-tab.pyturt-active {
  background-color: white;
  color: #00a4ff;
  font-weight: 800;
  z-index: 3;
}
.pyturt-code-output-tab.pyturt-inactive {
  color: #3a6d7d;
  background-color: transparent;
  font-weight: 600;
  z-index: 0;
}
.pyturt-right-pane .pyturt-inactive .pyturt-iconBox:not(.pyturt-errorBox) {
    background: #def5fd;
}
.pyturt-code-output-tab.pyturt-inactive:hover {
  color: #002b36;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 3;
}
.pyturt-code-output-tab svg {
}
.pyturt-iconBox {
  display: flex;
  background-color: #c8edf7;
  color: #03a5ff;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 13px;
}
.pyturt-iconBox.pyturt-errorBox {
  color: white;
  background-color: #d52224;
}

.pyturt-code-output-main {
  display: flex;
  flex: 1 1;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  z-index: 0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.pyturt-code-output-elements-wrapper {
  display: block;
  flex: 1 1;
  position: relative;
  box-sizing: border-box;
}
.pyturt-code-output-element {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  padding: 30px;
  box-sizing: border-box;
}
.pyturt-code-output-element.pyturt-active {
  z-index: 1;
}
.pyturt-code-output-element.pyturt-inactive {
  z-index: 0;
}
.pyturt-turtle-canvas {
  background-color: white;
  box-sizing: border-box;
  padding: 0px !important;
  border: 12px solid rgba(15, 143, 255, 0.01);
  overflow: hidden;
}
.pyturt-turtle-canvas.pyturt-running {
  border: 12px solid rgba(15, 143, 255, 0.5);
}
.pyturt-console-output {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
    monospace;
  overflow: auto;
  box-sizing: border-box;
}
.pyturt-instructions-pane {
  margin: 0px;
  background-color: white;
  color: #002b36;
  font-size: 14px;
  overflow: auto;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
.pyturt-instructions-pane-content {
  width: 100%;
}
.pyturt-instructions-pane-content img {
  width: 100%;
}
.pyturt-instructions-pane-content video {
  width: 100%;
}
.pyturt-instructions-pane .header-tag-v2 {
  min-height: 28px;
  float: right;
  padding: 0 12px;
}
.pyturt-instructions-pane-content > *:first-child {
  margin-top: 0px;
}
.pyturt-instructions-pane-content.pyturt-supplemental > *:first-child {
  margin-right: 119px;
}
.pyturt-instructions-pane-content.pyturt-supplemental.easy > *:first-child {
  margin-right: 89px;
}
.pyturt-instructions-pane-content.pyturt-supplemental.medium > *:first-child {
  margin-right: 85px;
}

.repl {
  display: flex;
  overflow: hidden;
  background-color: transparent;
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.repl.repl-horizontal {
  flex-direction: row;
}
.repl.repl-vertical {
  flex-direction: column;
}

.repl.repl-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px 10vmax rgba(0, 0, 0, 0.75);
  -webkit-animation: grow 0.25s ease-out;
          animation: grow 0.25s ease-out;
}
@-webkit-keyframes grow {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes grow {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.repl-sidebar-and-ce {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.not-authorized-error-page-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.learner-class-scheduler.card-v3.padded {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-shadow: 0px 20px 30px -10px #1f395029;
  padding: 1.5rem 2rem;
}
.learner-class-scheduler h1 {
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  color: hsl(208, 100%, 11%);
  line-height: 30px;
  font-weight: 900;
  margin-bottom: 1rem;
  font-size: 19px;
  letter-spacing: 0;
}
.learner-class-scheduler h1.centered {
  text-align: center;
}

.learner-class-scheduler h3 {
  font-family: 'Open San', sans-serif;
  color: hsl(208, 100%, 11%);
  font-size: 14px;
  font-weight: 700;
  text-transform: initial;
  letter-spacing: 0px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.learner-class-scheduler hr {
  border: none;
  border-bottom: 1px solid hsl(208, 36%, 87%);
  margin: 0;
}

.learner-class-scheduler .header-and-availability-controls {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0.7rem;
  margin-top: 1.5rem;
}

.learner-class-scheduler .header-and-availability-controls h3 {
  margin: 0.5rem 1rem 0.5rem 0;
}

.learner-class-scheduler .availability-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.learner-class-scheduler .no-availability {
  text-align: center;
  padding: 30px 0;
}

.learner-class-scheduler .availability-controls button {
  box-shadow: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  color: hsl(208, 100%, 53%);
  border: 1px solid hsl(208, 100%, 53%);
  border-radius: 5px;
  background: hsla(208, 100%, 53%, 0.05);
}

.learner-class-scheduler .availability-controls button:disabled {
  opacity: 0;
}
.learner-class-scheduler .availability-controls button:nth-of-type(1) {
  margin-right: 1rem;
}

.learner-class-scheduler table th {
  padding-bottom: 10px;
}

.learner-class-scheduler table th span.header-date {
  font-weight: 300;
}

.learner-class-scheduler .confirmation-message {
  text-align: center;
  margin: 16px auto;
  line-height: 30px;
}

.learner-class-scheduler .confirmation-message.extra-margin {
  text-align: center;
  margin: 48px auto;
  line-height: 30px;
}

.learner-class-scheduler .action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
}
.learner-class-scheduler .action-buttons.sm-icon {
  justify-content: center;
}
.learner-class-scheduler .action-buttons button .icon {
  height: 14px;
  width: 14px;
  padding: 6px;
}
.learner-class-scheduler .action-buttons.sm-icon button .icon {
  height: 12px;
  width: 12px;
  padding: 8px;
}
.learner-class-scheduler .action-buttons button:first-child .icon {
  background: hsl(208, 100%, 92%);
}
.learner-class-scheduler .action-buttons button:first-child .icon path {
  fill: hsl(208, 30%, 50%);
}

.learner-class-scheduler .action-buttons button:last-child .icon {
  background: hsl(208, 100%, 62%);
}
.learner-class-scheduler .action-buttons button:last-child .icon path {
  fill: #fff;
}

.learner-class-scheduler .availability-wrapper {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  overflow-y: visible;
  flex-direction: column;
  flex: 1 1;
  padding-bottom: 10px;
}

.learner-class-scheduler .availability-table-wrapper {
  flex: 1 1;
  box-sizing: border-box;
}

.learner-class-scheduler .availability-table {
  width: 600px;
}

.learner-class-scheduler .availability-row {
  display: flex;
  flex-direction: row;
}

.learner-class-scheduler .availability-table button.class-selector {
  box-shadow: none;
  border: 1px solid hsl(208, 100%, 62%);
  border-radius: 8px;
  padding: 5px 8px;
  width: 75%;
  text-transform: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: hsl(208, 100%, 53%);
  background: hsla(208, 100%, 53%, 0.03);
  font-weight: 600;
}

.learner-class-scheduler .availability-item {
  width: 120px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
}

.learner-class-scheduler .availability-item.unavailable button,
.learner-class-scheduler .availability-item button:disabled {
  text-decoration: line-through;
  border: none;
  color: #b2bbc3;
  font-weight: 100;
  background: transparent;
}

.learner-class-scheduler .availability-date-headers {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background: #fff;
  margin: 0 0 1rem;
}

.learner-class-scheduler .availability-date-headers > div {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  min-width: 120px;
  white-space: nowrap;
  margin-bottom: 6px;
  font-weight: 400;
}

.learner-class-scheduler .availability-date-headers > div span {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  white-space: nowrap;
  font-style: italic;
  opacity: 0.5;
  font-size: 0.6875rem;
  margin-top: 0.25rem;
}

.learner-class-scheduler .availability-container {
  overflow-y: scroll;
  flex: 1 1;
  padding: 10px;
}

.learner-class-scheduler table {
  border-collapse: collapse;
  width: 100%;
}

.learner-class-scheduler table td,
.learner-class-scheduler table th {
  padding: 5px 24px;
}

.learner-class-scheduler .header-table-wrapper {
  padding: 0 10px;
}
.learner-class-scheduler table th div.table-header {
  padding: 5px 8px;
}

.learner-class-scheduler table td,
.learner-class-scheduler table td > *,
.learner-class-scheduler table th,
.learner-class-scheduler table th > * {
  vertical-align: middle;
  text-align: center;
}

.learner-class-scheduler table td > div {
  border: 1px solid hsl(208, 100%, 53%);
  border-radius: 8px;
  padding: 5px 8px;
}
.learner-class-scheduler table td.unavailable div {
  text-decoration: line-through;
  border: none;
}

.learner-class-scheduler table button.class-selector {
  background: transparent;
  box-shadow: none;
  color: hsl(208, 14%, 36%);
  border: 1px solid hsl(208, 100%, 62%);
  border-radius: 8px;
  padding: 5px 8px;
  width: 100%;
  text-transform: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.learner-class-scheduler table button.class-selector:disabled {
  text-decoration: line-through;
  border: none;
  color: hsl(208, 14%, 60%);
}
@media only screen and (min-width: 400px) {
  .learner-class-scheduler .header-and-availability-controls {
    flex-direction: row;
    align-items: center;
  }
  .learner-class-scheduler .availability-controls {
    margin-left: auto;
  }
}
@media only screen and (min-width: 830px) {
  .learner-class-scheduler .availability-item {
    width: 140px;
    min-width: 140px;
  }
  .learner-class-scheduler .availability-date-headers > div {
    width: 140px;
    min-width: 140px;
  }
  .learner-class-scheduler .availability-table button.class-selector {
    width: 65%;
  }
}

.checkbox-v2-container label {
  cursor: pointer;
  position: relative;
}

.checkbox-v2-container-disabled label {
  cursor: default;
}

.checkbox-v2-container {
  margin: 0 15px;
}

.checkbox-v2-container label span {
  display: inline-block;
  position: relative;
  border: 2px solid hsl(208, 100%, 53%);
  background: #e2f4fc;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  -webkit-transform-origin: center;
          transform-origin: center;
  vertical-align: -6px;
  transition: background-color 150ms,
    -webkit-transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  transition: background-color 150ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  transition: background-color 150ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89),
    -webkit-transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}

.dark label span {
  display: inline-block;
  position: relative;
  border: 2px solid hsl(208, 100%, 53%);
  background: #E2E1ED;
  border-radius: 4px;
  width: 18px;
  height: 18px;
  -webkit-transform-origin: center;
          transform-origin: center;
  vertical-align: -6px;
  transition: background-color 150ms,
    -webkit-transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  transition: background-color 150ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
  transition: background-color 150ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89),
    -webkit-transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
}

.checkbox-v2-container-disabled label span {
  background: rgba(216, 232, 238, 0.2);
  border: 2px solid hsl(208, 36%, 87%);
  box-shadow: none;
}

.checkbox-v2-container-small {
  margin: 0 16px 0 57px;
}

.checkbox-v2-container-small label span {
  width: 13px;
  height: 13px;
  border: 2px solid hsl(208, 100%, 53%);
  box-shadow: none;
  border-radius: 4px;
  top: -2px;
}

.checkbox-v2-container-small.checkbox-v2-container-disabled label span {
  border: 2px solid hsl(208, 36%, 87%);
  background: rgba(216, 232, 238, 0.2);
}

.checkbox-v2-container-small.checkbox-v2-container-disabled {
  margin: 0 24px 0 29px;
}

.checkbox-v2-container label span:before {
  content: '';
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: #caecfc;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 9px;
  left: 3px;
  transition: width 50ms ease 50ms;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.checkbox-v2-container label span:after {
  content: '';
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: #caecfc;
  position: absolute;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  top: 13px;
  left: 6px;
  transition: width 50ms ease;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.dark label span:before {
  content: '';
  width: 0px;
  height: 2px;
  border-radius: 2px;
  background: #292563;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 9px;
  left: 3px;
  transition: width 50ms ease 50ms;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.dark label span:after {
  content: '';
  width: 0;
  height: 2px;
  border-radius: 2px;
  background: #292563;
  position: absolute;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  top: 13px;
  left: 6px;
  transition: width 50ms ease;
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.checkbox-v2-container.checkbox-v2-container-small label span:before {
  top: 6px;
  left: 3px;
  width: 0;
  background: #caecfc;
}

.checkbox-v2-container.checkbox-v2-container-small label span:after {
  top: 9px;
  left: 4px;
  width: 0;
  background: #caecfc;
}

.checkbox-v2-container label:hover span {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.checkbox-v2-container label:hover span:before {
  width: 5px;
  transition: width 100ms ease;
}

.checkbox-v2-container label:hover span:after {
  width: 9px;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container input[type='checkbox'] {
  display: none;
}

.checkbox-v2-container input[type='checkbox']:checked + label span {
  background-color: hsl(208, 100%, 53%);
  box-shadow: none;
}

.dark input[type='checkbox']:checked + label span {
  background-color: #292563;
}

.checkbox-v2-container.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span {
  background: transparent;
  border: 2px solid transparent;
}

.checkbox-v2-container-small input[type='checkbox']:checked + label span {
  border: 2px solid hsl(208, 100%, 53%);
  background: hsl(208, 100%, 53%);
  box-shadow: none;
}

.checkbox-v2-container-small.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span {
  background: transparent;
  border: 2px solid transparent;
}

.checkbox-v2-container input[type='checkbox']:checked + label span:before {
  width: 7px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-v2-container input[type='checkbox']:checked + label span:after {
  width: 13px;
  background: #fff;
  transition: width 150ms ease 100ms;
}
.checkbox-v2-container.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:before,
.checkbox-v2-container.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:after {
  background: rgb(46, 203, 93);
}
.checkbox-v2-container label:hover span:before {
  width: 7px;
  transition: width 150ms ease 100ms;
}
.checkbox-v2-container label:hover span:after {
  width: 13px;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container.checkbox-v2-container-disabled label:hover span:before,
.checkbox-v2-container.checkbox-v2-container-disabled label:hover span:after {
  width: 0;
}

.checkbox-v2-container-small label:hover span:before {
  width: 5px;
}
.checkbox-v2-container-small label:hover span:after {
  width: 9px;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled label:hover span:before {
  width: 5px;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled label:hover span:after {
  width: 9px;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled label span:before,
.checkbox-v2-container-small.checkbox-v2-container-disabled label span:after {
  background: transparent;
}
.checkbox-v2-container-small.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:before,
.checkbox-v2-container-small.checkbox-v2-container-disabled
  input[type='checkbox']:checked
  + label
  span:after {
  background: hsl(208, 100%, 53%);
}

.checkbox-v2-container-small input[type='checkbox']:checked + label span:after {
  width: 9px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container-small input[type='checkbox']:checked + label span:before {
  width: 5px;
  background: #fff;
  transition: width 150ms ease 100ms;
}

.checkbox-v2-container-disabled label:hover span {
  -webkit-transform: scale(1);
          transform: scale(1);
}

@media only screen and (min-width: 600px) {
  .checkbox-v2-container {
    margin: 0 33px;
  }
  .checkbox-v2-container-small {
    margin: 0 20px 0 63px;
  }
}

.learner-app {
  color: hsl(208, 9%, 34%);
}

.learner-app p {
  line-height: 1.8em;
  margin: 0;
}

.learner-app .layout-content {
  max-width: 1280px;
  margin: 0 auto;
}
.learner-app .bg-graphic {
  position: absolute;
  pointer-events: none;
}

.container {
  width: 100%;
}

@media (min-width: 350px) {
  .container {
    max-width: 350px;
  }
}

@media (min-width: 475px) {
  .container {
    max-width: 475px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.main-container {
  margin: auto;
  max-width: 80rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn {
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Graphik", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  text-decoration: none;
}

.btn.primary {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.btn.primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 72, 167, var(--tw-bg-opacity));
}

.btn.primary:active {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.btn.primary {
  height: 2.5rem;
  line-height: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn.secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.btn.secondary:hover {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.btn.secondary:active {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.btn.secondary {
  border-style: solid;
  border-width: 2px;
  height: 2.25rem;
  line-height: 2.25rem;
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}

.appearance-none {
  -webkit-appearance: none;
          appearance: none;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-transparent {
  background-color: transparent;
}

.bg-current {
  background-color: currentColor;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 199, 132, var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 160, 71, var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 235, 238, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 205, 210, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 115, 115, var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 57, 53, var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity));
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity));
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 213, 79, var(--tw-bg-opacity));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 179, 0, var(--tw-bg-opacity));
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity));
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity));
}

.bg-brown-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity));
}

.bg-brown-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity));
}

.bg-brown-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity));
}

.bg-brown-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 136, 127, var(--tw-bg-opacity));
}

.bg-brown-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity));
}

.bg-brown-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity));
}

.bg-brown-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 76, 65, var(--tw-bg-opacity));
}

.bg-brown-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity));
}

.bg-brown-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity));
}

.bg-brown-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity));
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 255, 255, var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 255, 255, var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 229, 255, var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 184, 212, var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(140, 158, 255, var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(83, 109, 254, var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 90, 254, var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 79, 254, var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 128, 252, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 64, 251, var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(213, 0, 249, var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 0, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 247, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 238, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 220, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 202, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 185, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 165, 255, var(--tw-bg-opacity));
}

.bg-juni-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 144, 227, var(--tw-bg-opacity));
}

.bg-juni-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 123, 198, var(--tw-bg-opacity));
}

.bg-juni-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 101, 169, var(--tw-bg-opacity));
}

.bg-juni-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 79, 140, var(--tw-bg-opacity));
}

.bg-juni-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 221, 239, var(--tw-bg-opacity));
}

.bg-juni-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 188, 224, var(--tw-bg-opacity));
}

.bg-juni-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 153, 208, var(--tw-bg-opacity));
}

.bg-juni-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 192, var(--tw-bg-opacity));
}

.bg-juni-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.bg-juni-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 237, 219, var(--tw-bg-opacity));
}

.bg-juni-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 219, 183, var(--tw-bg-opacity));
}

.bg-juni-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(114, 201, 147, var(--tw-bg-opacity));
}

.bg-juni-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 183, 111, var(--tw-bg-opacity));
}

.bg-juni-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 165, 74, var(--tw-bg-opacity));
}

.bg-juni-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 239, 241, var(--tw-bg-opacity));
}

.bg-juni-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 223, 226, var(--tw-bg-opacity));
}

.bg-juni-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 192, 198, var(--tw-bg-opacity));
}

.bg-juni-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 160, 169, var(--tw-bg-opacity));
}

.bg-juni-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 128, 140, var(--tw-bg-opacity));
}

.bg-juni-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 96, 111, var(--tw-bg-opacity));
}

.bg-juni-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 77, 97, var(--tw-bg-opacity));
}

.bg-juni-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.bg-juni-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 39, 69, var(--tw-bg-opacity));
}

.bg-juni-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(152, 20, 54, var(--tw-bg-opacity));
}

.bg-juni-pink-gradient {
  background-color: linear-gradient(180deg, #EB7480 0%, #E75564 100%);
}

.bg-juni-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 233, 214, var(--tw-bg-opacity));
}

.bg-juni-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 211, 173, var(--tw-bg-opacity));
}

.bg-juni-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 189, 132, var(--tw-bg-opacity));
}

.bg-juni-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 167, 91, var(--tw-bg-opacity));
}

.bg-juni-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 145, 50, var(--tw-bg-opacity));
}

.bg-juni-dark-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 228, 236, var(--tw-bg-opacity));
}

.bg-juni-dark-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 201, 216, var(--tw-bg-opacity));
}

.bg-juni-dark-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 146, 177, var(--tw-bg-opacity));
}

.bg-juni-dark-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(95, 92, 138, var(--tw-bg-opacity));
}

.bg-juni-dark-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(65, 62, 117, var(--tw-bg-opacity));
}

.bg-juni-dark-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 99, var(--tw-bg-opacity));
}

.bg-juni-light-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 244, 251, var(--tw-bg-opacity));
}

.bg-blue-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity));
}

.bg-blue-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity));
}

.bg-blue-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity));
}

.bg-blue-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 164, 174, var(--tw-bg-opacity));
}

.bg-blue-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity));
}

.bg-blue-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity));
}

.bg-blue-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(84, 110, 122, var(--tw-bg-opacity));
}

.bg-blue-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity));
}

.bg-blue-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity));
}

.bg-blue-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity));
}

.bg-light-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 255, 144, var(--tw-bg-opacity));
}

.bg-light-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 255, 89, var(--tw-bg-opacity));
}

.bg-light-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 255, 3, var(--tw-bg-opacity));
}

.bg-light-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 221, 23, var(--tw-bg-opacity));
}

.bg-deep-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity));
}

.bg-deep-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity));
}

.bg-deep-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity));
}

.bg-eggshell-blue {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 253, 255, var(--tw-bg-opacity));
}

.bg-j-dark-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.bg-j-dark-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 201, 216, var(--tw-bg-opacity));
}

.bg-j-dark-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 146, 177, var(--tw-bg-opacity));
}

.bg-j-dark-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(95, 92, 138, var(--tw-bg-opacity));
}

.bg-j-dark-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(65, 62, 117, var(--tw-bg-opacity));
}

.bg-j-dark-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 99, var(--tw-bg-opacity));
}

.bg-j-dark-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 35, 80, var(--tw-bg-opacity));
}

.bg-j-dark-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 24, 62, var(--tw-bg-opacity));
}

.bg-j-dark-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 11, 51, var(--tw-bg-opacity));
}

.bg-j-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.bg-j-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
}

.bg-j-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 237, 244, var(--tw-bg-opacity));
}

.bg-j-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(218, 229, 238, var(--tw-bg-opacity));
}

.bg-j-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 218, 231, var(--tw-bg-opacity));
}

.bg-j-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 238, 247, var(--tw-bg-opacity));
}

.bg-j-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.bg-j-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 188, 224, var(--tw-bg-opacity));
}

.bg-j-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 153, 208, var(--tw-bg-opacity));
}

.bg-j-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 192, var(--tw-bg-opacity));
}

.bg-j-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(98, 85, 176, var(--tw-bg-opacity));
}

.bg-j-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 72, 167, var(--tw-bg-opacity));
}

.bg-j-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 56, 151, var(--tw-bg-opacity));
}

.bg-j-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 233, 214, var(--tw-bg-opacity));
}

.bg-j-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 211, 173, var(--tw-bg-opacity));
}

.bg-j-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 189, 132, var(--tw-bg-opacity));
}

.bg-j-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 167, 91, var(--tw-bg-opacity));
}

.bg-j-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 145, 50, var(--tw-bg-opacity));
}

.bg-j-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 130, 26, var(--tw-bg-opacity));
}

.bg-j-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 115, 5, var(--tw-bg-opacity));
}

.bg-j-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 240, 241, var(--tw-bg-opacity));
}

.bg-j-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 223, 226, var(--tw-bg-opacity));
}

.bg-j-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 192, 198, var(--tw-bg-opacity));
}

.bg-j-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 160, 169, var(--tw-bg-opacity));
}

.bg-j-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 128, 140, var(--tw-bg-opacity));
}

.bg-j-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 96, 111, var(--tw-bg-opacity));
}

.bg-j-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.bg-j-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 246, 237, var(--tw-bg-opacity));
}

.bg-j-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(208, 237, 219, var(--tw-bg-opacity));
}

.bg-j-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(162, 219, 183, var(--tw-bg-opacity));
}

.bg-j-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 192, 128, var(--tw-bg-opacity));
}

.bg-j-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 165, 74, var(--tw-bg-opacity));
}

.bg-j-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 146, 60, var(--tw-bg-opacity));
}

.bg-j-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 120, 47, var(--tw-bg-opacity));
}

.bg-j-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 240, 255, var(--tw-bg-opacity));
}

.bg-j-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(218, 224, 254, var(--tw-bg-opacity));
}

.bg-j-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 178, 253, var(--tw-bg-opacity));
}

.bg-j-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 101, 252, var(--tw-bg-opacity));
}

.bg-j-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 76, 238, var(--tw-bg-opacity));
}

.bg-j-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 51, 199, var(--tw-bg-opacity));
}

.bg-j-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 237, var(--tw-bg-opacity));
}

.bg-j-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 244, 207, var(--tw-bg-opacity));
}

.bg-j-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 233, 160, var(--tw-bg-opacity));
}

.bg-j-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 213, 66, var(--tw-bg-opacity));
}

.bg-j-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 112, 0, var(--tw-bg-opacity));
}

.bg-hickory-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 9, 9, var(--tw-bg-opacity));
}

.bg-hickory-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 12, 12, var(--tw-bg-opacity));
}

.bg-hickory-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 45, 45, var(--tw-bg-opacity));
}

.bg-gingerbread-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 35, 9, var(--tw-bg-opacity));
}

.bg-gingerbread-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 44, 12, var(--tw-bg-opacity));
}

.bg-gingerbread-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 72, 45, var(--tw-bg-opacity));
}

.bg-olive-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 72, 9, var(--tw-bg-opacity));
}

.bg-olive-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 89, 12, var(--tw-bg-opacity));
}

.bg-olive-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 110, 45, var(--tw-bg-opacity));
}

.bg-cucumber-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 78, 12, var(--tw-bg-opacity));
}

.bg-cucumber-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 117, 38, var(--tw-bg-opacity));
}

.bg-cucumber-light {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 143, 58, var(--tw-bg-opacity));
}

.bg-ocean-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 78, 63, var(--tw-bg-opacity));
}

.bg-ocean-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 117, 99, var(--tw-bg-opacity));
}

.bg-ocean-light {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 143, 123, var(--tw-bg-opacity));
}

.bg-sapphire-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 58, 78, var(--tw-bg-opacity));
}

.bg-sapphire-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 93, 117, var(--tw-bg-opacity));
}

.bg-sapphire-light {
  --tw-bg-opacity: 1;
  background-color: rgba(58, 117, 143, var(--tw-bg-opacity));
}

.bg-navy-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 26, 72, var(--tw-bg-opacity));
}

.bg-navy-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 29, 89, var(--tw-bg-opacity));
}

.bg-navy-light {
  --tw-bg-opacity: 1;
  background-color: rgba(45, 59, 110, var(--tw-bg-opacity));
}

.bg-eggplant-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 12, 78, var(--tw-bg-opacity));
}

.bg-eggplant-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 38, 117, var(--tw-bg-opacity));
}

.bg-eggplant-light {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 58, 143, var(--tw-bg-opacity));
}

.bg-grape-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(72, 9, 72, var(--tw-bg-opacity));
}

.bg-grape-medium {
  --tw-bg-opacity: 1;
  background-color: rgba(89, 12, 89, var(--tw-bg-opacity));
}

.bg-grape-light {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 45, 110, var(--tw-bg-opacity));
}

.odd\:bg-white:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.even\:bg-j-gray-100:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-juni-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 220, 255, var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-juni-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 165, 255, var(--tw-bg-opacity));
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.hover\:bg-juni-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 247, 255, var(--tw-bg-opacity));
}

.hover\:bg-juni-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 144, 227, var(--tw-bg-opacity));
}

.hover\:bg-juni-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 77, 97, var(--tw-bg-opacity));
}

.hover\:bg-juni-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 39, 69, var(--tw-bg-opacity));
}

.hover\:bg-blue-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity));
}

.hover\:bg-j-dark-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.hover\:bg-j-dark-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 35, 80, var(--tw-bg-opacity));
}

.hover\:bg-j-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.hover\:bg-j-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
}

.hover\:bg-j-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 238, 247, var(--tw-bg-opacity));
}

.hover\:bg-j-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.hover\:bg-j-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 72, 167, var(--tw-bg-opacity));
}

.hover\:bg-j-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 240, 241, var(--tw-bg-opacity));
}

.hover\:bg-j-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.hover\:bg-j-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 165, 74, var(--tw-bg-opacity));
}

.hover\:bg-j-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(218, 224, 254, var(--tw-bg-opacity));
}

.hover\:bg-j-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 51, 199, var(--tw-bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: transparent;
}

.focus\:bg-j-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
}

.focus\:bg-j-pink-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 58, 83, var(--tw-bg-opacity));
}

.focus\:bg-j-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 246, 237, var(--tw-bg-opacity));
}

.focus\:bg-j-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(23, 51, 199, var(--tw-bg-opacity));
}

.active\:bg-j-dark-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 37, 99, var(--tw-bg-opacity));
}

.active\:bg-j-gray-200:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
}

.active\:bg-j-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 237, 244, var(--tw-bg-opacity));
}

.active\:bg-j-purple-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 238, 247, var(--tw-bg-opacity));
}

.active\:bg-j-purple-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(70, 56, 151, var(--tw-bg-opacity));
}

.active\:bg-j-green-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 146, 60, var(--tw-bg-opacity));
}

.active\:bg-j-blue-300:active {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 178, 253, var(--tw-bg-opacity));
}

.disabled\:bg-transparent:disabled {
  background-color: transparent;
}

.disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.disabled\:bg-blue-gray-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity));
}

.disabled\:bg-j-dark-100:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.disabled\:bg-j-purple-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 225, 237, var(--tw-bg-opacity));
}

.bg-loader-gradient {
  background-image: linear-gradient(to left, var(--tw-gradient-from) 50%, var(--tw-gradient-to) 50%);
}

.from-white {
  --tw-gradient-from: #FFFFFF;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0));
}

.from-juni-pink-400 {
  --tw-gradient-from: #EE808C;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 128, 140, 0));
}

.from-j-dark-600 {
  --tw-gradient-from: #292563;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(41, 37, 99, 0));
}

.to-juni-pink-50 {
  --tw-gradient-to: #FDEFF1;
}

.to-juni-pink-500 {
  --tw-gradient-to: #EA606F;
}

.to-j-dark-200 {
  --tw-gradient-to: #CAC9D8;
}

.hover\:from-juni-pink-700:hover {
  --tw-gradient-from: #C13A53;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(193, 58, 83, 0));
}

.hover\:to-juni-pink-700:hover {
  --tw-gradient-to: #C13A53;
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.hover\:bg-opacity-0:hover {
  --tw-bg-opacity: 0;
}

.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-loader {
  background-size: 200% 100%;
}

.border-collapse {
  border-collapse: collapse;
}

.border-transparent {
  border-color: transparent;
}

.border-current {
  border-color: currentColor;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 245, 233, var(--tw-border-opacity));
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(200, 230, 201, var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 214, 167, var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 199, 132, var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(102, 187, 106, var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 160, 71, var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(46, 125, 50, var(--tw-border-opacity));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(27, 94, 32, var(--tw-border-opacity));
}

.border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 235, 238, var(--tw-border-opacity));
}

.border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 205, 210, var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 154, 154, var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 115, 115, var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 83, 80, var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 57, 53, var(--tw-border-opacity));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity));
}

.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(198, 40, 40, var(--tw-border-opacity));
}

.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(183, 28, 28, var(--tw-border-opacity));
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 248, 225, var(--tw-border-opacity));
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 236, 179, var(--tw-border-opacity));
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 224, 130, var(--tw-border-opacity));
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 213, 79, var(--tw-border-opacity));
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 202, 40, var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity));
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 179, 0, var(--tw-border-opacity));
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity));
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 143, 0, var(--tw-border-opacity));
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 111, 0, var(--tw-border-opacity));
}

.border-brown-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 235, 233, var(--tw-border-opacity));
}

.border-brown-100 {
  --tw-border-opacity: 1;
  border-color: rgba(215, 204, 200, var(--tw-border-opacity));
}

.border-brown-200 {
  --tw-border-opacity: 1;
  border-color: rgba(188, 170, 164, var(--tw-border-opacity));
}

.border-brown-300 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 136, 127, var(--tw-border-opacity));
}

.border-brown-400 {
  --tw-border-opacity: 1;
  border-color: rgba(141, 110, 99, var(--tw-border-opacity));
}

.border-brown-500 {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity));
}

.border-brown-600 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 76, 65, var(--tw-border-opacity));
}

.border-brown-700 {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity));
}

.border-brown-800 {
  --tw-border-opacity: 1;
  border-color: rgba(78, 52, 46, var(--tw-border-opacity));
}

.border-brown-900 {
  --tw-border-opacity: 1;
  border-color: rgba(62, 39, 35, var(--tw-border-opacity));
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgba(132, 255, 255, var(--tw-border-opacity));
}

.border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgba(24, 255, 255, var(--tw-border-opacity));
}

.border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 229, 255, var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 184, 212, var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(140, 158, 255, var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(83, 109, 254, var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(61, 90, 254, var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(48, 79, 254, var(--tw-border-opacity));
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 128, 252, var(--tw-border-opacity));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 64, 251, var(--tw-border-opacity));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(213, 0, 249, var(--tw-border-opacity));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(170, 0, 255, var(--tw-border-opacity));
}

.border-juni-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(233, 247, 255, var(--tw-border-opacity));
}

.border-juni-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 238, 255, var(--tw-border-opacity));
}

.border-juni-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 220, 255, var(--tw-border-opacity));
}

.border-juni-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(106, 202, 255, var(--tw-border-opacity));
}

.border-juni-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 185, 255, var(--tw-border-opacity));
}

.border-juni-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 165, 255, var(--tw-border-opacity));
}

.border-juni-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 144, 227, var(--tw-border-opacity));
}

.border-juni-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 123, 198, var(--tw-border-opacity));
}

.border-juni-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 101, 169, var(--tw-border-opacity));
}

.border-juni-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(1, 79, 140, var(--tw-border-opacity));
}

.border-juni-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 221, 239, var(--tw-border-opacity));
}

.border-juni-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.border-juni-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.border-juni-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.border-juni-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.border-juni-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(208, 237, 219, var(--tw-border-opacity));
}

.border-juni-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(162, 219, 183, var(--tw-border-opacity));
}

.border-juni-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(114, 201, 147, var(--tw-border-opacity));
}

.border-juni-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(68, 183, 111, var(--tw-border-opacity));
}

.border-juni-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(20, 165, 74, var(--tw-border-opacity));
}

.border-juni-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 239, 241, var(--tw-border-opacity));
}

.border-juni-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 223, 226, var(--tw-border-opacity));
}

.border-juni-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 192, 198, var(--tw-border-opacity));
}

.border-juni-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.border-juni-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 128, 140, var(--tw-border-opacity));
}

.border-juni-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 96, 111, var(--tw-border-opacity));
}

.border-juni-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(214, 77, 97, var(--tw-border-opacity));
}

.border-juni-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 58, 83, var(--tw-border-opacity));
}

.border-juni-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(173, 39, 69, var(--tw-border-opacity));
}

.border-juni-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(152, 20, 54, var(--tw-border-opacity));
}

.border-juni-pink-gradient {
  border-color: linear-gradient(180deg, #EB7480 0%, #E75564 100%);
}

.border-juni-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 214, var(--tw-border-opacity));
}

.border-juni-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 211, 173, var(--tw-border-opacity));
}

.border-juni-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 189, 132, var(--tw-border-opacity));
}

.border-juni-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 167, 91, var(--tw-border-opacity));
}

.border-juni-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 145, 50, var(--tw-border-opacity));
}

.border-juni-dark-100 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 228, 236, var(--tw-border-opacity));
}

.border-juni-dark-200 {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.border-juni-dark-300 {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.border-juni-dark-500 {
  --tw-border-opacity: 1;
  border-color: rgba(95, 92, 138, var(--tw-border-opacity));
}

.border-juni-dark-700 {
  --tw-border-opacity: 1;
  border-color: rgba(65, 62, 117, var(--tw-border-opacity));
}

.border-juni-dark-900 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 99, var(--tw-border-opacity));
}

.border-juni-light-100 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 244, 251, var(--tw-border-opacity));
}

.border-blue-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity));
}

.border-blue-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(207, 216, 220, var(--tw-border-opacity));
}

.border-blue-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(176, 190, 197, var(--tw-border-opacity));
}

.border-blue-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 164, 174, var(--tw-border-opacity));
}

.border-blue-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity));
}

.border-blue-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity));
}

.border-blue-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(84, 110, 122, var(--tw-border-opacity));
}

.border-blue-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity));
}

.border-blue-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 71, 79, var(--tw-border-opacity));
}

.border-blue-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(38, 50, 56, var(--tw-border-opacity));
}

.border-light-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(204, 255, 144, var(--tw-border-opacity));
}

.border-light-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(178, 255, 89, var(--tw-border-opacity));
}

.border-light-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(118, 255, 3, var(--tw-border-opacity));
}

.border-light-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 221, 23, var(--tw-border-opacity));
}

.border-deep-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 196, 233, var(--tw-border-opacity));
}

.border-deep-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(126, 87, 194, var(--tw-border-opacity));
}

.border-deep-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity));
}

.border-eggshell-blue {
  --tw-border-opacity: 1;
  border-color: rgba(248, 253, 255, var(--tw-border-opacity));
}

.border-j-dark-100 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.border-j-dark-200 {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.border-j-dark-300 {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.border-j-dark-400 {
  --tw-border-opacity: 1;
  border-color: rgba(95, 92, 138, var(--tw-border-opacity));
}

.border-j-dark-500 {
  --tw-border-opacity: 1;
  border-color: rgba(65, 62, 117, var(--tw-border-opacity));
}

.border-j-dark-600 {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 99, var(--tw-border-opacity));
}

.border-j-dark-700 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 35, 80, var(--tw-border-opacity));
}

.border-j-dark-800 {
  --tw-border-opacity: 1;
  border-color: rgba(26, 24, 62, var(--tw-border-opacity));
}

.border-j-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 250, 252, var(--tw-border-opacity));
}

.border-j-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(240, 245, 250, var(--tw-border-opacity));
}

.border-j-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 237, 244, var(--tw-border-opacity));
}

.border-j-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(218, 229, 238, var(--tw-border-opacity));
}

.border-j-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 218, 231, var(--tw-border-opacity));
}

.border-j-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 238, 247, var(--tw-border-opacity));
}

.border-j-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.border-j-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.border-j-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.border-j-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.border-j-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.border-j-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(85, 72, 167, var(--tw-border-opacity));
}

.border-j-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(70, 56, 151, var(--tw-border-opacity));
}

.border-j-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 233, 214, var(--tw-border-opacity));
}

.border-j-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 211, 173, var(--tw-border-opacity));
}

.border-j-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 189, 132, var(--tw-border-opacity));
}

.border-j-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 167, 91, var(--tw-border-opacity));
}

.border-j-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 145, 50, var(--tw-border-opacity));
}

.border-j-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 130, 26, var(--tw-border-opacity));
}

.border-j-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 115, 5, var(--tw-border-opacity));
}

.border-j-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 240, 241, var(--tw-border-opacity));
}

.border-j-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 223, 226, var(--tw-border-opacity));
}

.border-j-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(247, 192, 198, var(--tw-border-opacity));
}

.border-j-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.border-j-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 128, 140, var(--tw-border-opacity));
}

.border-j-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(234, 96, 111, var(--tw-border-opacity));
}

.border-j-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(193, 58, 83, var(--tw-border-opacity));
}

.border-j-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(231, 246, 237, var(--tw-border-opacity));
}

.border-j-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(208, 237, 219, var(--tw-border-opacity));
}

.border-j-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(162, 219, 183, var(--tw-border-opacity));
}

.border-j-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 192, 128, var(--tw-border-opacity));
}

.border-j-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(20, 165, 74, var(--tw-border-opacity));
}

.border-j-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(9, 146, 60, var(--tw-border-opacity));
}

.border-j-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 120, 47, var(--tw-border-opacity));
}

.border-j-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 240, 255, var(--tw-border-opacity));
}

.border-j-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(218, 224, 254, var(--tw-border-opacity));
}

.border-j-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(164, 178, 253, var(--tw-border-opacity));
}

.border-j-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(72, 101, 252, var(--tw-border-opacity));
}

.border-j-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(45, 76, 238, var(--tw-border-opacity));
}

.border-j-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(23, 51, 199, var(--tw-border-opacity));
}

.border-j-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 237, var(--tw-border-opacity));
}

.border-j-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 244, 207, var(--tw-border-opacity));
}

.border-j-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 233, 160, var(--tw-border-opacity));
}

.border-j-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 213, 66, var(--tw-border-opacity));
}

.border-j-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(144, 112, 0, var(--tw-border-opacity));
}

.border-hickory-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 9, 9, var(--tw-border-opacity));
}

.border-hickory-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 12, 12, var(--tw-border-opacity));
}

.border-hickory-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 45, 45, var(--tw-border-opacity));
}

.border-gingerbread-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 35, 9, var(--tw-border-opacity));
}

.border-gingerbread-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 44, 12, var(--tw-border-opacity));
}

.border-gingerbread-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 72, 45, var(--tw-border-opacity));
}

.border-olive-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 72, 9, var(--tw-border-opacity));
}

.border-olive-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 89, 12, var(--tw-border-opacity));
}

.border-olive-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 110, 45, var(--tw-border-opacity));
}

.border-cucumber-dark {
  --tw-border-opacity: 1;
  border-color: rgba(33, 78, 12, var(--tw-border-opacity));
}

.border-cucumber-medium {
  --tw-border-opacity: 1;
  border-color: rgba(63, 117, 38, var(--tw-border-opacity));
}

.border-cucumber-light {
  --tw-border-opacity: 1;
  border-color: rgba(85, 143, 58, var(--tw-border-opacity));
}

.border-ocean-dark {
  --tw-border-opacity: 1;
  border-color: rgba(12, 78, 63, var(--tw-border-opacity));
}

.border-ocean-medium {
  --tw-border-opacity: 1;
  border-color: rgba(38, 117, 99, var(--tw-border-opacity));
}

.border-ocean-light {
  --tw-border-opacity: 1;
  border-color: rgba(58, 143, 123, var(--tw-border-opacity));
}

.border-sapphire-dark {
  --tw-border-opacity: 1;
  border-color: rgba(12, 58, 78, var(--tw-border-opacity));
}

.border-sapphire-medium {
  --tw-border-opacity: 1;
  border-color: rgba(38, 93, 117, var(--tw-border-opacity));
}

.border-sapphire-light {
  --tw-border-opacity: 1;
  border-color: rgba(58, 117, 143, var(--tw-border-opacity));
}

.border-navy-dark {
  --tw-border-opacity: 1;
  border-color: rgba(9, 26, 72, var(--tw-border-opacity));
}

.border-navy-medium {
  --tw-border-opacity: 1;
  border-color: rgba(12, 29, 89, var(--tw-border-opacity));
}

.border-navy-light {
  --tw-border-opacity: 1;
  border-color: rgba(45, 59, 110, var(--tw-border-opacity));
}

.border-eggplant-dark {
  --tw-border-opacity: 1;
  border-color: rgba(21, 12, 78, var(--tw-border-opacity));
}

.border-eggplant-medium {
  --tw-border-opacity: 1;
  border-color: rgba(49, 38, 117, var(--tw-border-opacity));
}

.border-eggplant-light {
  --tw-border-opacity: 1;
  border-color: rgba(70, 58, 143, var(--tw-border-opacity));
}

.border-grape-dark {
  --tw-border-opacity: 1;
  border-color: rgba(72, 9, 72, var(--tw-border-opacity));
}

.border-grape-medium {
  --tw-border-opacity: 1;
  border-color: rgba(89, 12, 89, var(--tw-border-opacity));
}

.border-grape-light {
  --tw-border-opacity: 1;
  border-color: rgba(110, 45, 110, var(--tw-border-opacity));
}

.focus-within\:border-juni-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 165, 255, var(--tw-border-opacity));
}

.focus-within\:border-j-dark-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.hover\:border-juni-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(157, 220, 255, var(--tw-border-opacity));
}

.hover\:border-juni-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.hover\:border-j-dark-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(202, 201, 216, var(--tw-border-opacity));
}

.hover\:border-j-dark-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.hover\:border-j-dark-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(95, 92, 138, var(--tw-border-opacity));
}

.hover\:border-j-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.hover\:border-j-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.hover\:border-j-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.hover\:border-j-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.hover\:border-j-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(98, 85, 176, var(--tw-border-opacity));
}

.hover\:border-j-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.hover\:border-j-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(91, 192, 128, var(--tw-border-opacity));
}

.focus\:border-j-dark-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(148, 146, 177, var(--tw-border-opacity));
}

.focus\:border-j-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(193, 188, 224, var(--tw-border-opacity));
}

.focus\:border-j-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.focus\:border-j-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.focus\:border-j-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(247, 192, 198, var(--tw-border-opacity));
}

.focus\:border-j-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(208, 237, 219, var(--tw-border-opacity));
}

.focus\:border-j-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(162, 219, 183, var(--tw-border-opacity));
}

.focus\:border-j-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(164, 178, 253, var(--tw-border-opacity));
}

.active\:border-j-dark-600:active {
  --tw-border-opacity: 1;
  border-color: rgba(41, 37, 99, var(--tw-border-opacity));
}

.active\:border-j-purple-400:active {
  --tw-border-opacity: 1;
  border-color: rgba(161, 153, 208, var(--tw-border-opacity));
}

.active\:border-j-purple-500:active {
  --tw-border-opacity: 1;
  border-color: rgba(130, 119, 192, var(--tw-border-opacity));
}

.active\:border-j-pink-400:active {
  --tw-border-opacity: 1;
  border-color: rgba(243, 160, 169, var(--tw-border-opacity));
}

.active\:border-j-green-400:active {
  --tw-border-opacity: 1;
  border-color: rgba(91, 192, 128, var(--tw-border-opacity));
}

.disabled\:border-transparent:disabled {
  border-color: transparent;
}

.disabled\:border-white:disabled {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.disabled\:border-blue-gray-400:disabled {
  --tw-border-opacity: 1;
  border-color: rgba(120, 144, 156, var(--tw-border-opacity));
}

.disabled\:border-j-dark-100:disabled {
  --tw-border-opacity: 1;
  border-color: rgba(226, 225, 237, var(--tw-border-opacity));
}

.border-opacity-20 {
  --tw-border-opacity: 0.2;
}

.border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.border-opacity-50 {
  --tw-border-opacity: 0.5;
}

.disabled\:border-opacity-10:disabled {
  --tw-border-opacity: 0.1;
}

.disabled\:border-opacity-25:disabled {
  --tw-border-opacity: 0.25;
}

.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.disabled\:border-solid:disabled {
  border-style: solid;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.disabled\:border-2:disabled {
  border-width: 2px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.disabled\:box-border:disabled {
  box-sizing: border-box;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-items-center {
  justify-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-end {
  justify-self: end;
}

.flex-1 {
  flex: 1 1;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.order-last {
  order: 9999;
}

.float-right {
  float: right;
}

.font-sans {
  font-family: "Open Sans", sans-serif;
}

.font-graphik {
  font-family: "Graphik", sans-serif;
}

.font-serif {
  font-family: Source Serif Pro, serif;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.h-0 {
  height: 0px;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-18 {
  height: 4.5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-1\.5 {
  height: 0.375rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.333333%;
}

.h-2\/3 {
  height: 66.666667%;
}

.h-1\/4 {
  height: 25%;
}

.h-2\/4 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-1\/5 {
  height: 20%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-1\/6 {
  height: 16.666667%;
}

.h-2\/6 {
  height: 33.333333%;
}

.h-3\/6 {
  height: 50%;
}

.h-4\/6 {
  height: 66.666667%;
}

.h-5\/6 {
  height: 83.333333%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.text-2xs {
  font-size: 0.625rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-heading-900 {
  font-size: 2.5rem;
  line-height: 3.5rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.m-0 {
  margin: 0px;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-7 {
  margin: 1.75rem;
}

.m-8 {
  margin: 2rem;
}

.m-9 {
  margin: 2.25rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-11 {
  margin: 2.75rem;
}

.m-12 {
  margin: 3rem;
}

.m-14 {
  margin: 3.5rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-28 {
  margin: 7rem;
}

.m-32 {
  margin: 8rem;
}

.m-36 {
  margin: 9rem;
}

.m-40 {
  margin: 10rem;
}

.m-44 {
  margin: 11rem;
}

.m-48 {
  margin: 12rem;
}

.m-52 {
  margin: 13rem;
}

.m-56 {
  margin: 14rem;
}

.m-60 {
  margin: 15rem;
}

.m-64 {
  margin: 16rem;
}

.m-72 {
  margin: 18rem;
}

.m-80 {
  margin: 20rem;
}

.m-96 {
  margin: 24rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.m-0\.5 {
  margin: 0.125rem;
}

.m-1\.5 {
  margin: 0.375rem;
}

.m-2\.5 {
  margin: 0.625rem;
}

.m-3\.5 {
  margin: 0.875rem;
}

.-m-0 {
  margin: 0px;
}

.-m-1 {
  margin: -0.25rem;
}

.-m-2 {
  margin: -0.5rem;
}

.-m-3 {
  margin: -0.75rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-7 {
  margin: -1.75rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-9 {
  margin: -2.25rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-11 {
  margin: -2.75rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-14 {
  margin: -3.5rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-28 {
  margin: -7rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-36 {
  margin: -9rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-44 {
  margin: -11rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-52 {
  margin: -13rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-60 {
  margin: -15rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-72 {
  margin: -18rem;
}

.-m-80 {
  margin: -20rem;
}

.-m-96 {
  margin: -24rem;
}

.-m-px {
  margin: -1px;
}

.-m-0\.5 {
  margin: -0.125rem;
}

.-m-1\.5 {
  margin: -0.375rem;
}

.-m-2\.5 {
  margin: -0.625rem;
}

.-m-3\.5 {
  margin: -0.875rem;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}

.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}

.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.my-1\.5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}

.-my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.-mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}

.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}

.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}

.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}

.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}

.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}

.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}

.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}

.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}

.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-0\.5 {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}

.-mx-0\.5 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

.-mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}

.-my-2\.5 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.-mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.-my-3\.5 {
  margin-top: -0.875rem;
  margin-bottom: -0.875rem;
}

.-mx-3\.5 {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-16 {
  margin-left: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.ml-24 {
  margin-left: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.ml-28 {
  margin-left: 7rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.ml-32 {
  margin-left: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mr-36 {
  margin-right: 9rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.ml-36 {
  margin-left: 9rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.ml-40 {
  margin-left: 10rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mr-44 {
  margin-right: 11rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.ml-44 {
  margin-left: 11rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.ml-48 {
  margin-left: 12rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mr-52 {
  margin-right: 13rem;
}

.mb-52 {
  margin-bottom: 13rem;
}

.ml-52 {
  margin-left: 13rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.ml-56 {
  margin-left: 14rem;
}

.mt-60 {
  margin-top: 15rem;
}

.mr-60 {
  margin-right: 15rem;
}

.mb-60 {
  margin-bottom: 15rem;
}

.ml-60 {
  margin-left: 15rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.ml-64 {
  margin-left: 16rem;
}

.mt-72 {
  margin-top: 18rem;
}

.mr-72 {
  margin-right: 18rem;
}

.mb-72 {
  margin-bottom: 18rem;
}

.ml-72 {
  margin-left: 18rem;
}

.mt-80 {
  margin-top: 20rem;
}

.mr-80 {
  margin-right: 20rem;
}

.mb-80 {
  margin-bottom: 20rem;
}

.ml-80 {
  margin-left: 20rem;
}

.mt-96 {
  margin-top: 24rem;
}

.mr-96 {
  margin-right: 24rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.ml-96 {
  margin-left: 24rem;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.mr-0\.5 {
  margin-right: 0.125rem;
}

.mb-0\.5 {
  margin-bottom: 0.125rem;
}

.ml-0\.5 {
  margin-left: 0.125rem;
}

.mt-1\.5 {
  margin-top: 0.375rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.ml-1\.5 {
  margin-left: 0.375rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.mr-2\.5 {
  margin-right: 0.625rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.ml-2\.5 {
  margin-left: 0.625rem;
}

.mt-3\.5 {
  margin-top: 0.875rem;
}

.mr-3\.5 {
  margin-right: 0.875rem;
}

.mb-3\.5 {
  margin-bottom: 0.875rem;
}

.ml-3\.5 {
  margin-left: 0.875rem;
}

.-mt-0 {
  margin-top: 0px;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-mb-3 {
  margin-bottom: -0.75rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-44 {
  margin-top: -11rem;
}

.-mt-0\.5 {
  margin-top: -0.125rem;
}

.-ml-1\.5 {
  margin-left: -0.375rem;
}

.first\:mt-0:first-child {
  margin-top: 0px;
}

.last\:mb-0:last-child {
  margin-bottom: 0px;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-50vh {
  max-height: 50vh;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-xs {
  max-width: 475px;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.min-h-0 {
  min-height: 0px;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-18 {
  min-width: 4.5rem;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.object-top {
  object-position: top;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.hover\:opacity-60:hover {
  opacity: 0.6;
}

.hover\:opacity-70:hover {
  opacity: 0.7;
}

.hover\:opacity-80:hover {
  opacity: 0.8;
}

.disabled\:opacity-70:disabled {
  opacity: 0.7;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-72 {
  padding: 18rem;
}

.p-80 {
  padding: 20rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.p-0\.5 {
  padding: 0.125rem;
}

.p-1\.5 {
  padding: 0.375rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.p-3\.5 {
  padding: 0.875rem;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pt-18 {
  padding-top: 4.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pl-28 {
  padding-left: 7rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pr-36 {
  padding-right: 9rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pr-44 {
  padding-right: 11rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pl-44 {
  padding-left: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pt-52 {
  padding-top: 13rem;
}

.pr-52 {
  padding-right: 13rem;
}

.pb-52 {
  padding-bottom: 13rem;
}

.pl-52 {
  padding-left: 13rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pt-60 {
  padding-top: 15rem;
}

.pr-60 {
  padding-right: 15rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pl-60 {
  padding-left: 15rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pr-72 {
  padding-right: 18rem;
}

.pb-72 {
  padding-bottom: 18rem;
}

.pl-72 {
  padding-left: 18rem;
}

.pt-80 {
  padding-top: 20rem;
}

.pr-80 {
  padding-right: 20rem;
}

.pb-80 {
  padding-bottom: 20rem;
}

.pl-80 {
  padding-left: 20rem;
}

.pt-96 {
  padding-top: 24rem;
}

.pr-96 {
  padding-right: 24rem;
}

.pb-96 {
  padding-bottom: 24rem;
}

.pl-96 {
  padding-left: 24rem;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.pt-0\.5 {
  padding-top: 0.125rem;
}

.pr-0\.5 {
  padding-right: 0.125rem;
}

.pb-0\.5 {
  padding-bottom: 0.125rem;
}

.pl-0\.5 {
  padding-left: 0.125rem;
}

.pt-1\.5 {
  padding-top: 0.375rem;
}

.pr-1\.5 {
  padding-right: 0.375rem;
}

.pb-1\.5 {
  padding-bottom: 0.375rem;
}

.pl-1\.5 {
  padding-left: 0.375rem;
}

.pt-2\.5 {
  padding-top: 0.625rem;
}

.pr-2\.5 {
  padding-right: 0.625rem;
}

.pb-2\.5 {
  padding-bottom: 0.625rem;
}

.pl-2\.5 {
  padding-left: 0.625rem;
}

.pt-3\.5 {
  padding-top: 0.875rem;
}

.pr-3\.5 {
  padding-right: 0.875rem;
}

.pb-3\.5 {
  padding-bottom: 0.875rem;
}

.pl-3\.5 {
  padding-left: 0.875rem;
}

.first\:pt-0:first-child {
  padding-top: 0px;
}

.first\:pt-4:first-child {
  padding-top: 1rem;
}

.last\:pb-0:last-child {
  padding-bottom: 0px;
}

.last\:pb-4:last-child {
  padding-bottom: 1rem;
}

.placeholder-blue-gray-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity));
}

.placeholder-blue-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-200::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-placeholder-opacity));
}

.placeholder-j-dark-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-placeholder-opacity));
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.inset-4 {
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
}

.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

.inset-x-0 {
  right: 0px;
  left: 0px;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.top-1 {
  top: 0.25rem;
}

.right-1 {
  right: 0.25rem;
}

.bottom-1 {
  bottom: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.left-2 {
  left: 0.5rem;
}

.top-3 {
  top: 0.75rem;
}

.right-3 {
  right: 0.75rem;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-20 {
  bottom: 5rem;
}

.top-0\.5 {
  top: 0.125rem;
}

.left-0\.5 {
  left: 0.125rem;
}

.top-1\.5 {
  top: 0.375rem;
}

.right-1\.5 {
  right: 0.375rem;
}

.top-2\.5 {
  top: 0.625rem;
}

.-top-1 {
  top: -0.25rem;
}

.-right-1 {
  right: -0.25rem;
}

.-left-1 {
  left: -0.25rem;
}

.-top-2 {
  top: -0.5rem;
}

.-left-2 {
  left: -0.5rem;
}

.-right-3 {
  right: -0.75rem;
}

.-bottom-14 {
  bottom: -3.5rem;
}

.-right-16 {
  right: -4rem;
}

.-top-28 {
  top: -7rem;
}

.-right-28 {
  right: -7rem;
}

.-right-32 {
  right: -8rem;
}

.-bottom-36 {
  bottom: -9rem;
}

.-top-2\.5 {
  top: -0.625rem;
}

.-right-3\.5 {
  right: -0.875rem;
}

.top-1\/2 {
  top: 50%;
}

.left-2\/4 {
  left: 50%;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize {
  resize: both;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow-1 {
  --tw-shadow: 0px 2px 4px rgba(98, 85, 176, 0.08);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2 {
  --tw-shadow: 0px 4px 8px rgba(98, 85, 176, 0.08);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-3 {
  --tw-shadow: 0px 8px 24px rgba(98, 85, 176, 0.12);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-4 {
  --tw-shadow: 0px 16px 24px rgba(98, 85, 176, 0.12);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0px 16px 24px #E7EEF9;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-button-j-purple-600 {
  --tw-shadow: 0 0 0 2px #6255B0;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-1:hover {
  --tw-shadow: 0px 2px 4px rgba(98, 85, 176, 0.08);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-2:hover {
  --tw-shadow: 0px 4px 8px rgba(98, 85, 176, 0.08);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-button-j-purple-300:focus {
  --tw-shadow: 0 0 0 2px #C1BCE0;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-j-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(240, 245, 250, var(--tw-ring-opacity));
}

.ring-j-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(98, 85, 176, var(--tw-ring-opacity));
}

.focus\:ring-j-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(208, 237, 219, var(--tw-ring-opacity));
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.table-auto {
  table-layout: auto;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-transparent {
  color: transparent;
}

.text-current {
  color: currentColor;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(232, 245, 233, var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(200, 230, 201, var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(165, 214, 167, var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(129, 199, 132, var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(102, 187, 106, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(67, 160, 71, var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(27, 94, 32, var(--tw-text-opacity));
}

.text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 235, 238, var(--tw-text-opacity));
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 205, 210, var(--tw-text-opacity));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(239, 154, 154, var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(229, 115, 115, var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(239, 83, 80, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(183, 28, 28, var(--tw-text-opacity));
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 248, 225, var(--tw-text-opacity));
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 236, 179, var(--tw-text-opacity));
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgba(255, 224, 130, var(--tw-text-opacity));
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgba(255, 213, 79, var(--tw-text-opacity));
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgba(255, 202, 40, var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(255, 179, 0, var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity));
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgba(255, 111, 0, var(--tw-text-opacity));
}

.text-brown-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 235, 233, var(--tw-text-opacity));
}

.text-brown-100 {
  --tw-text-opacity: 1;
  color: rgba(215, 204, 200, var(--tw-text-opacity));
}

.text-brown-200 {
  --tw-text-opacity: 1;
  color: rgba(188, 170, 164, var(--tw-text-opacity));
}

.text-brown-300 {
  --tw-text-opacity: 1;
  color: rgba(161, 136, 127, var(--tw-text-opacity));
}

.text-brown-400 {
  --tw-text-opacity: 1;
  color: rgba(141, 110, 99, var(--tw-text-opacity));
}

.text-brown-500 {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity));
}

.text-brown-600 {
  --tw-text-opacity: 1;
  color: rgba(109, 76, 65, var(--tw-text-opacity));
}

.text-brown-700 {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity));
}

.text-brown-800 {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity));
}

.text-brown-900 {
  --tw-text-opacity: 1;
  color: rgba(62, 39, 35, var(--tw-text-opacity));
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgba(132, 255, 255, var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgba(24, 255, 255, var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgba(0, 229, 255, var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 184, 212, var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(140, 158, 255, var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(83, 109, 254, var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(61, 90, 254, var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(48, 79, 254, var(--tw-text-opacity));
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(234, 128, 252, var(--tw-text-opacity));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(224, 64, 251, var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(213, 0, 249, var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(170, 0, 255, var(--tw-text-opacity));
}

.text-juni-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(233, 247, 255, var(--tw-text-opacity));
}

.text-juni-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(207, 238, 255, var(--tw-text-opacity));
}

.text-juni-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(157, 220, 255, var(--tw-text-opacity));
}

.text-juni-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(106, 202, 255, var(--tw-text-opacity));
}

.text-juni-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(55, 185, 255, var(--tw-text-opacity));
}

.text-juni-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 165, 255, var(--tw-text-opacity));
}

.text-juni-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(1, 144, 227, var(--tw-text-opacity));
}

.text-juni-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(1, 123, 198, var(--tw-text-opacity));
}

.text-juni-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(1, 101, 169, var(--tw-text-opacity));
}

.text-juni-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(1, 79, 140, var(--tw-text-opacity));
}

.text-juni-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 221, 239, var(--tw-text-opacity));
}

.text-juni-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(193, 188, 224, var(--tw-text-opacity));
}

.text-juni-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.text-juni-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 192, var(--tw-text-opacity));
}

.text-juni-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(98, 85, 176, var(--tw-text-opacity));
}

.text-juni-green-100 {
  --tw-text-opacity: 1;
  color: rgba(208, 237, 219, var(--tw-text-opacity));
}

.text-juni-green-200 {
  --tw-text-opacity: 1;
  color: rgba(162, 219, 183, var(--tw-text-opacity));
}

.text-juni-green-300 {
  --tw-text-opacity: 1;
  color: rgba(114, 201, 147, var(--tw-text-opacity));
}

.text-juni-green-400 {
  --tw-text-opacity: 1;
  color: rgba(68, 183, 111, var(--tw-text-opacity));
}

.text-juni-green-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 165, 74, var(--tw-text-opacity));
}

.text-juni-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 239, 241, var(--tw-text-opacity));
}

.text-juni-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(251, 223, 226, var(--tw-text-opacity));
}

.text-juni-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(247, 192, 198, var(--tw-text-opacity));
}

.text-juni-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(243, 160, 169, var(--tw-text-opacity));
}

.text-juni-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(238, 128, 140, var(--tw-text-opacity));
}

.text-juni-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(234, 96, 111, var(--tw-text-opacity));
}

.text-juni-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(214, 77, 97, var(--tw-text-opacity));
}

.text-juni-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(193, 58, 83, var(--tw-text-opacity));
}

.text-juni-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(173, 39, 69, var(--tw-text-opacity));
}

.text-juni-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(152, 20, 54, var(--tw-text-opacity));
}

.text-juni-pink-gradient {
  color: linear-gradient(180deg, #EB7480 0%, #E75564 100%);
}

.text-juni-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(253, 233, 214, var(--tw-text-opacity));
}

.text-juni-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 211, 173, var(--tw-text-opacity));
}

.text-juni-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 189, 132, var(--tw-text-opacity));
}

.text-juni-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(247, 167, 91, var(--tw-text-opacity));
}

.text-juni-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 145, 50, var(--tw-text-opacity));
}

.text-juni-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(229, 228, 236, var(--tw-text-opacity));
}

.text-juni-dark-200 {
  --tw-text-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-text-opacity));
}

.text-juni-dark-300 {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.text-juni-dark-500 {
  --tw-text-opacity: 1;
  color: rgba(95, 92, 138, var(--tw-text-opacity));
}

.text-juni-dark-700 {
  --tw-text-opacity: 1;
  color: rgba(65, 62, 117, var(--tw-text-opacity));
}

.text-juni-dark-900 {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.text-juni-light-100 {
  --tw-text-opacity: 1;
  color: rgba(239, 244, 251, var(--tw-text-opacity));
}

.text-blue-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity));
}

.text-blue-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(207, 216, 220, var(--tw-text-opacity));
}

.text-blue-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity));
}

.text-blue-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(144, 164, 174, var(--tw-text-opacity));
}

.text-blue-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(120, 144, 156, var(--tw-text-opacity));
}

.text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity));
}

.text-blue-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(84, 110, 122, var(--tw-text-opacity));
}

.text-blue-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity));
}

.text-blue-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity));
}

.text-blue-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity));
}

.text-light-green-100 {
  --tw-text-opacity: 1;
  color: rgba(204, 255, 144, var(--tw-text-opacity));
}

.text-light-green-200 {
  --tw-text-opacity: 1;
  color: rgba(178, 255, 89, var(--tw-text-opacity));
}

.text-light-green-400 {
  --tw-text-opacity: 1;
  color: rgba(118, 255, 3, var(--tw-text-opacity));
}

.text-light-green-700 {
  --tw-text-opacity: 1;
  color: rgba(100, 221, 23, var(--tw-text-opacity));
}

.text-deep-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 196, 233, var(--tw-text-opacity));
}

.text-deep-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(126, 87, 194, var(--tw-text-opacity));
}

.text-deep-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity));
}

.text-eggshell-blue {
  --tw-text-opacity: 1;
  color: rgba(248, 253, 255, var(--tw-text-opacity));
}

.text-j-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.text-j-dark-200 {
  --tw-text-opacity: 1;
  color: rgba(202, 201, 216, var(--tw-text-opacity));
}

.text-j-dark-300 {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.text-j-dark-400 {
  --tw-text-opacity: 1;
  color: rgba(95, 92, 138, var(--tw-text-opacity));
}

.text-j-dark-500 {
  --tw-text-opacity: 1;
  color: rgba(65, 62, 117, var(--tw-text-opacity));
}

.text-j-dark-600 {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.text-j-dark-700 {
  --tw-text-opacity: 1;
  color: rgba(37, 35, 80, var(--tw-text-opacity));
}

.text-j-dark-800 {
  --tw-text-opacity: 1;
  color: rgba(26, 24, 62, var(--tw-text-opacity));
}

.text-j-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(247, 250, 252, var(--tw-text-opacity));
}

.text-j-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(240, 245, 250, var(--tw-text-opacity));
}

.text-j-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(229, 237, 244, var(--tw-text-opacity));
}

.text-j-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(218, 229, 238, var(--tw-text-opacity));
}

.text-j-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(203, 218, 231, var(--tw-text-opacity));
}

.text-j-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(239, 238, 247, var(--tw-text-opacity));
}

.text-j-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.text-j-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(193, 188, 224, var(--tw-text-opacity));
}

.text-j-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.text-j-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 192, var(--tw-text-opacity));
}

.text-j-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(98, 85, 176, var(--tw-text-opacity));
}

.text-j-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(85, 72, 167, var(--tw-text-opacity));
}

.text-j-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(70, 56, 151, var(--tw-text-opacity));
}

.text-j-orange-100 {
  --tw-text-opacity: 1;
  color: rgba(253, 233, 214, var(--tw-text-opacity));
}

.text-j-orange-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 211, 173, var(--tw-text-opacity));
}

.text-j-orange-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 189, 132, var(--tw-text-opacity));
}

.text-j-orange-400 {
  --tw-text-opacity: 1;
  color: rgba(247, 167, 91, var(--tw-text-opacity));
}

.text-j-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 145, 50, var(--tw-text-opacity));
}

.text-j-orange-600 {
  --tw-text-opacity: 1;
  color: rgba(239, 130, 26, var(--tw-text-opacity));
}

.text-j-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(232, 115, 5, var(--tw-text-opacity));
}

.text-j-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(253, 240, 241, var(--tw-text-opacity));
}

.text-j-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 223, 226, var(--tw-text-opacity));
}

.text-j-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(247, 192, 198, var(--tw-text-opacity));
}

.text-j-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(243, 160, 169, var(--tw-text-opacity));
}

.text-j-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(238, 128, 140, var(--tw-text-opacity));
}

.text-j-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(234, 96, 111, var(--tw-text-opacity));
}

.text-j-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(193, 58, 83, var(--tw-text-opacity));
}

.text-j-green-100 {
  --tw-text-opacity: 1;
  color: rgba(231, 246, 237, var(--tw-text-opacity));
}

.text-j-green-200 {
  --tw-text-opacity: 1;
  color: rgba(208, 237, 219, var(--tw-text-opacity));
}

.text-j-green-300 {
  --tw-text-opacity: 1;
  color: rgba(162, 219, 183, var(--tw-text-opacity));
}

.text-j-green-400 {
  --tw-text-opacity: 1;
  color: rgba(91, 192, 128, var(--tw-text-opacity));
}

.text-j-green-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 165, 74, var(--tw-text-opacity));
}

.text-j-green-600 {
  --tw-text-opacity: 1;
  color: rgba(9, 146, 60, var(--tw-text-opacity));
}

.text-j-green-700 {
  --tw-text-opacity: 1;
  color: rgba(3, 120, 47, var(--tw-text-opacity));
}

.text-j-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 240, 255, var(--tw-text-opacity));
}

.text-j-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(218, 224, 254, var(--tw-text-opacity));
}

.text-j-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(164, 178, 253, var(--tw-text-opacity));
}

.text-j-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(72, 101, 252, var(--tw-text-opacity));
}

.text-j-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(45, 76, 238, var(--tw-text-opacity));
}

.text-j-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(23, 51, 199, var(--tw-text-opacity));
}

.text-j-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 237, var(--tw-text-opacity));
}

.text-j-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 244, 207, var(--tw-text-opacity));
}

.text-j-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(254, 233, 160, var(--tw-text-opacity));
}

.text-j-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(254, 213, 66, var(--tw-text-opacity));
}

.text-j-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(144, 112, 0, var(--tw-text-opacity));
}

.text-hickory-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 9, 9, var(--tw-text-opacity));
}

.text-hickory-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 12, 12, var(--tw-text-opacity));
}

.text-hickory-light {
  --tw-text-opacity: 1;
  color: rgba(110, 45, 45, var(--tw-text-opacity));
}

.text-gingerbread-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 35, 9, var(--tw-text-opacity));
}

.text-gingerbread-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 44, 12, var(--tw-text-opacity));
}

.text-gingerbread-light {
  --tw-text-opacity: 1;
  color: rgba(110, 72, 45, var(--tw-text-opacity));
}

.text-olive-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 72, 9, var(--tw-text-opacity));
}

.text-olive-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 89, 12, var(--tw-text-opacity));
}

.text-olive-light {
  --tw-text-opacity: 1;
  color: rgba(110, 110, 45, var(--tw-text-opacity));
}

.text-cucumber-dark {
  --tw-text-opacity: 1;
  color: rgba(33, 78, 12, var(--tw-text-opacity));
}

.text-cucumber-medium {
  --tw-text-opacity: 1;
  color: rgba(63, 117, 38, var(--tw-text-opacity));
}

.text-cucumber-light {
  --tw-text-opacity: 1;
  color: rgba(85, 143, 58, var(--tw-text-opacity));
}

.text-ocean-dark {
  --tw-text-opacity: 1;
  color: rgba(12, 78, 63, var(--tw-text-opacity));
}

.text-ocean-medium {
  --tw-text-opacity: 1;
  color: rgba(38, 117, 99, var(--tw-text-opacity));
}

.text-ocean-light {
  --tw-text-opacity: 1;
  color: rgba(58, 143, 123, var(--tw-text-opacity));
}

.text-sapphire-dark {
  --tw-text-opacity: 1;
  color: rgba(12, 58, 78, var(--tw-text-opacity));
}

.text-sapphire-medium {
  --tw-text-opacity: 1;
  color: rgba(38, 93, 117, var(--tw-text-opacity));
}

.text-sapphire-light {
  --tw-text-opacity: 1;
  color: rgba(58, 117, 143, var(--tw-text-opacity));
}

.text-navy-dark {
  --tw-text-opacity: 1;
  color: rgba(9, 26, 72, var(--tw-text-opacity));
}

.text-navy-medium {
  --tw-text-opacity: 1;
  color: rgba(12, 29, 89, var(--tw-text-opacity));
}

.text-navy-light {
  --tw-text-opacity: 1;
  color: rgba(45, 59, 110, var(--tw-text-opacity));
}

.text-eggplant-dark {
  --tw-text-opacity: 1;
  color: rgba(21, 12, 78, var(--tw-text-opacity));
}

.text-eggplant-medium {
  --tw-text-opacity: 1;
  color: rgba(49, 38, 117, var(--tw-text-opacity));
}

.text-eggplant-light {
  --tw-text-opacity: 1;
  color: rgba(70, 58, 143, var(--tw-text-opacity));
}

.text-grape-dark {
  --tw-text-opacity: 1;
  color: rgba(72, 9, 72, var(--tw-text-opacity));
}

.text-grape-medium {
  --tw-text-opacity: 1;
  color: rgba(89, 12, 89, var(--tw-text-opacity));
}

.text-grape-light {
  --tw-text-opacity: 1;
  color: rgba(110, 45, 110, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-juni-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(1, 144, 227, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-j-dark-100 {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.group:hover .group-hover\:text-j-dark-300 {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.hover\:text-juni-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(1, 144, 227, var(--tw-text-opacity));
}

.hover\:text-juni-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.hover\:text-j-dark-100:hover {
  --tw-text-opacity: 1;
  color: rgba(226, 225, 237, var(--tw-text-opacity));
}

.hover\:text-j-dark-600:hover {
  --tw-text-opacity: 1;
  color: rgba(41, 37, 99, var(--tw-text-opacity));
}

.hover\:text-j-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(161, 153, 208, var(--tw-text-opacity));
}

.hover\:text-j-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(98, 85, 176, var(--tw-text-opacity));
}

.hover\:text-j-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(45, 76, 238, var(--tw-text-opacity));
}

.active\:text-j-dark-700:active {
  --tw-text-opacity: 1;
  color: rgba(37, 35, 80, var(--tw-text-opacity));
}

.active\:text-j-purple-500:active {
  --tw-text-opacity: 1;
  color: rgba(130, 119, 192, var(--tw-text-opacity));
}

.active\:text-j-green-700:active {
  --tw-text-opacity: 1;
  color: rgba(3, 120, 47, var(--tw-text-opacity));
}

.disabled\:text-white:disabled {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.disabled\:text-blue-gray-200:disabled {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity));
}

.disabled\:text-j-dark-300:disabled {
  --tw-text-opacity: 1;
  color: rgba(148, 146, 177, var(--tw-text-opacity));
}

.text-opacity-70 {
  --tw-text-opacity: 0.7;
}

.disabled\:text-opacity-70:disabled {
  --tw-text-opacity: 0.7;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ordinal {
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/);
  -webkit-font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
          font-feature-settings: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.ordinal {
  --tw-ordinal: ordinal;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.tracking-normal {
  letter-spacing: 0em;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.select-none {
  -webkit-user-select: none;
          user-select: none;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.group:hover .group-hover\:visible {
  visibility: visible;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.w-0 {
  width: 0px;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-18 {
  width: 4.5rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1\.5 {
  width: 0.375rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: -webkit-min-content;
  width: min-content;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-1003 {
  z-index: 1003;
}

.z-1004 {
  z-index: 1004;
}

.gap-0 {
  grid-gap: 0px;
  gap: 0px;
}

.gap-1 {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.gap-2 {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.gap-3 {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}

.gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.gap-12 {
  grid-gap: 3rem;
  gap: 3rem;
}

.gap-14 {
  grid-gap: 3.5rem;
  gap: 3.5rem;
}

.gap-20 {
  grid-gap: 5rem;
  gap: 5rem;
}

.gap-0\.5 {
  grid-gap: 0.125rem;
  gap: 0.125rem;
}

.gap-1\.5 {
  grid-gap: 0.375rem;
  gap: 0.375rem;
}

.gap-x-2 {
  -webkit-column-gap: 0.5rem;
          grid-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.gap-x-4 {
  -webkit-column-gap: 1rem;
          grid-column-gap: 1rem;
          column-gap: 1rem;
}

.gap-x-6 {
  -webkit-column-gap: 1.5rem;
          grid-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.gap-x-8 {
  -webkit-column-gap: 2rem;
          grid-column-gap: 2rem;
          column-gap: 2rem;
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.gap-y-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.gap-y-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.gap-y-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-500 {
  --tw-scale-x: 5;
  --tw-scale-y: 5;
}

.scale-700 {
  --tw-scale-x: 7;
  --tw-scale-y: 7;
}

.rotate-180 {
  --tw-rotate: 180deg;
}

.-rotate-12 {
  --tw-rotate: -12deg;
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.translate-y-0 {
  --tw-translate-y: 0px;
}

.translate-y-2 {
  --tw-translate-y: 0.5rem;
}

.translate-y-0\.5 {
  --tw-translate-y: 0.125rem;
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
}

.translate-y-3\/4 {
  --tw-translate-y: 75%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@-webkit-keyframes loader {
  0% {
    background-position: 0 0;
    opacity: 1;
  }

  100% {
    background-position: -80% 0;
    opacity: 1;
  }
}

@keyframes loader {
  0% {
    background-position: 0 0;
    opacity: 1;
  }

  100% {
    background-position: -80% 0;
    opacity: 1;
  }
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

.animate-loader {
  -webkit-animation: loader 5s ease normal forwards;
          animation: loader 5s ease normal forwards;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

@media (min-width: 350px) {
}

@media (min-width: 475px) {
  .xs\:flex-row {
    flex-direction: row;
  }

  .xs\:items-center {
    align-items: center;
  }

  .xs\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xs\:mt-0 {
    margin-top: 0px;
  }

  .xs\:ml-auto {
    margin-left: auto;
  }

  .xs\:w-96 {
    width: 24rem;
  }
}

@media (min-width: 640px) {
  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .sm\:bg-j-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 237, 244, var(--tw-bg-opacity));
  }

  .sm\:bg-j-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(161, 153, 208, var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-j-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(240, 245, 250, var(--tw-bg-opacity));
  }

  .sm\:border-j-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(203, 218, 231, var(--tw-border-opacity));
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:font-medium {
    font-weight: 500;
  }

  .sm\:h-28 {
    height: 7rem;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:mb-0 {
    margin-bottom: 0px;
  }

  .sm\:ml-0 {
    margin-left: 0px;
  }

  .sm\:mt-1 {
    margin-top: 0.25rem;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:ml-2 {
    margin-left: 0.5rem;
  }

  .sm\:mt-3 {
    margin-top: 0.75rem;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:-mt-36 {
    margin-top: -9rem;
  }

  .sm\:-mt-64 {
    margin-top: -16rem;
  }

  .sm\:max-w-screen-xs {
    max-width: 475px;
  }

  .sm\:max-w-screen-sm {
    max-width: 640px;
  }

  .sm\:p-0 {
    padding: 0px;
  }

  .sm\:p-1 {
    padding: 0.25rem;
  }

  .sm\:p-2 {
    padding: 0.5rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0px;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:pb-28 {
    padding-bottom: 7rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .sm\:text-j-dark-400 {
    --tw-text-opacity: 1;
    color: rgba(95, 92, 138, var(--tw-text-opacity));
  }

  .sm\:text-j-dark-600 {
    --tw-text-opacity: 1;
    color: rgba(41, 37, 99, var(--tw-text-opacity));
  }

  .sm\:text-j-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(239, 238, 247, var(--tw-text-opacity));
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }

  .sm\:w-44 {
    width: 11rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-1\/3 {
    width: 33.333333%;
  }

  .sm\:w-2\/3 {
    width: 66.666667%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-2\/6 {
    width: 33.333333%;
  }

  .sm\:w-5\/6 {
    width: 83.333333%;
  }

  .sm\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
}

@media (min-width: 768px) {
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:rounded-lg {
    border-radius: 0.5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-baseline {
    align-items: baseline;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .md\:leading-10 {
    line-height: 2.5rem;
  }

  .md\:leading-normal {
    line-height: 1.5;
  }

  .md\:m-0 {
    margin: 0px;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:-mt-40 {
    margin-top: -10rem;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:p-3 {
    padding: 0.75rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pb-0 {
    padding-bottom: 0px;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pl-20 {
    padding-left: 5rem;
  }

  .md\:static {
    position: static;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:top-0 {
    top: 0px;
  }

  .md\:left-0 {
    left: 0px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  .md\:w-2\/3 {
    width: 66.666667%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .md\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .md\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }
}

@media (min-width: 1024px) {
  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:bg-top {
    background-position: top;
  }

  .lg\:border-r-0 {
    border-right-width: 0px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:flex-1 {
    flex: 1 1;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:m-0 {
    margin: 0px;
  }

  .lg\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:mr-0 {
    margin-right: 0px;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:ml-3 {
    margin-left: 0.75rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:max-h-80 {
    max-height: 20rem;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:max-w-min {
    max-width: -webkit-min-content;
    max-width: min-content;
  }

  .lg\:p-0 {
    padding: 0px;
  }

  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pl-40 {
    padding-left: 10rem;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:static {
    position: static;
  }

  .lg\:left-0 {
    left: 0px;
  }

  .lg\:-top-20 {
    top: -5rem;
  }

  .lg\:-top-40 {
    top: -10rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.333333%;
  }

  .lg\:w-2\/3 {
    width: 66.666667%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:gap-3 {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0px;
  }
}

@media (min-width: 1280px) {
  .xl\:flex {
    display: flex;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .xl\:mx-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .xl\:px-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:gap-x-8 {
    -webkit-column-gap: 2rem;
            grid-column-gap: 2rem;
            column-gap: 2rem;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
}

@media (min-width: 1536px) {
  .\32xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

