@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:600");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,400i,600,600i,700,800,900&display=swap");
@font-face {
  font-family: "Gotham";
  src: url(../assets/Gotham-Black.otf);
  font-weight: bolder;
}
@font-face {
  font-family: "Gotham";
  src: url(../assets/Gotham-Bold.otf);
  font-weight: bold;
}
@font-face {
  font-family: "Gotham";
  src: url(../assets/Gotham-BookItalic.otf);
  font-weight: light;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url(../assets/Gotham-Light.otf);
  font-weight: light;
}
@font-face {
  font-family: "Gotham";
  src: url(../assets/Gotham-Thin.otf);
  font-weight: lighter;
}
@font-face {
  font-family: "Gotham";
  src: url(../assets/Gotham-ThinItalic.otf);
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url(../assets/Gotham-Medium.otf);
  font-weight: normal;
}
@font-face {
  font-family: "Gotham";
  src: url(../assets/GothamMediumItalic.ttf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-SuperItalic.otf);
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Super.otf);
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-BlackItalic.otf);
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font//Graphik-Black.otf);
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-BoldItalic.otf);
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Bold.otf);
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-SemiboldItalic.otf);
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Semibold.otf);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-MediumItalic.otf);
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Medium.otf);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-RegularItalic.otf);
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Regular.otf);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-LightItalic.otf);
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Light.otf);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-ExtralightItalic.otf);
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Extralight.otf);
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-ThinItalic.otf);
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik";
  src: url(../assets/graphik-font/Graphik-Thin.otf);
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-Black.ttf);
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-Italic.ttf);
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-Light.ttf);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-ExtraLight.ttf);
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Source Serif Pro";
  src: url(../assets/source-serif-pro-font/SourceSerifPro-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  color: hsl(208, 14%, 24%);
  text-rendering: optimizeLegibility;
  background-color: #eff4fb;
  position: relative;
}

#root {
  min-height: 100%;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#root > div:first-child {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#root > div.juni-spinner-wrapper:first-child {
  align-items: center;
}

a {
  color: #333;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}

th,
td {
  padding: 5px 15px;
  vertical-align: top;
}

.clear {
  clear: both;
}

.header-tag-v2 {
  font-size: 10px;
  border-radius: 24px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border: 2px solid;
  box-sizing: border-box;
  min-height: 30px;
}

.header-tag-v2 > span {
  display: none;
}

.header-tag-v2.with-right-icon .icon {
  width: 10px;
  height: 10px;
}

.header-tag-v2.incomplete {
  background: hsla(0, 72%, 49%, 0.1);
  color: hsl(0, 72%, 49%);
  border: 2px solid hsl(0, 72%, 49%);
}

.header-tag-v2.complete {
  background: rgba(31, 191, 94, 0.1);
  color: rgb(31, 191, 94);
  border: 2px solid rgb(31, 191, 94);
}

.header-tag-v2.missed,
.header-tag-v2.upcoming {
  background: hsla(208, 100%, 53%, 0.1);
  color: hsl(208, 100%, 53%);
  border: 2px solid hsl(208, 100%, 53%);
}

.header-tag {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 10px;
  background: rgb(46, 203, 93);
  color: #fff;
  border-radius: 2px;
  padding: 0px 8px;
  font-weight: 300;
  line-height: 22px;
}

.header-tag-red {
  background: hsl(0, 72%, 49%);
}

.header-tag-blue {
  background-color: hsl(208, 100%, 53%);
}

.page-error {
  margin: 30px auto;
  text-align: center;
  font-size: 30px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  display: none;
  width: 250px;
  padding: 10px;
  border: 1px solid #333;
  background: #fff;
  font-size: 12px;
  position: absolute;
  z-index: 100;
  top: 25px;
  left: -10px;
}

.tooltip-container:hover .tooltip {
  display: inline-block;
}

.badge {
  font-weight: 400;
  white-space: nowrap;
  color: #fff;
  padding: 10px 12px;
  border: 0px;
  font-size: 12px;
  margin: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Gotham", sans-serif;
}

button,
.button {
  color: #fff;
  background-color: hsl(208, 100%, 53%);
  padding: 5px 8px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  margin: 0px;
  text-transform: uppercase;
  transition: 0.2s;
}

button:hover:not(.ignore-juni-globals),
.button:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.button {
  margin-top: 20px;
  padding: 10px 20px;
  display: inline-block;
  font-size: 12px;
}

button:disabled:not(.ignore-juni-globals),
.button-disabled {
  background-color: #efefef;
  cursor: default;
  border: 1px solid #efefef;
  color: #ccc;
  box-shadow: none;
}

.button-disabled .icon {
  background: #ccc;
}

.button-disabled:hover {
  box-shadow: none;
}

.card {
  background: #fff;
  margin: 25px;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  font-size: 14px;
  font-weight: 300;
}

.card-v2 {
  background: #fff;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  border: 1px solid hsl(208, 36%, 87%);
  border-radius: 4px 0px 4px 4px;
  -webkit-box-shadow: 0 0 20px hsl(208, 46%, 78%);
  box-shadow: 0 20px 80px -10px hsl(208, 46%, 78%);
  padding: 20px;
}

.card-v3 {
  background: #fff;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  border-radius: var(--radius, 8px);
  box-shadow: 0px 10px 60px -10px #deebf2;
}
.card-v3.padded {
  padding: 1.5rem 1.25rem;
}
@media only screen and (min-width: 480px) {
  .card-v3.padded {
    padding: 1.75rem 1.5rem;
  }
}

.card-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.card-error {
  font-size: 14px;
  color: hsl(0, 72%, 49%);
}

.form-container {
  width: 500px;
  margin: 100px auto;
  text-align: center;
  background: #fff;
  padding: 25px;
  border: 1px solid #ccc;
}

.form-container-wide {
  width: 900px;
}

.form-container-xwide {
  width: 1100px;
}

.form-container-light table {
  font-weight: 300;
  font-size: 13px;
}

.form-container-full-width {
  display: inline-block;
  width: auto !important;
  margin: 100px 50px;
}

.form-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 300;
  text-align: center;
}

.form-subtitle {
  font-size: 18px;
  text-align: left;
  margin: 20px 0px 10px 0px;
}

.form-subtitle-2 {
  font-size: 18px;
  margin: -10px 0px 20px 0px;
  font-weight: 300;
}

.form-description {
  font-size: 12px;
  margin-bottom: 20px;
}

.form-field-description {
  text-align: left;
  font-size: 12px;
  margin: 25px 0px 0px 2px;
}

.form-reset-link {
  font-size: 12px;
  color: #666;
}

.form-secure-payment {
  text-align: left;
  font-size: 12px;
  color: #333;
}

.form-secure-payment img {
  width: 10px;
  margin-right: 8px;
  vertical-align: middle;
  margin-top: -3px;
}

.form-secure-payment-cards {
  float: right;
  display: inline-block;
}

.form-secure-payment-cards img {
  width: 22px;
  margin-right: 0px;
  margin-left: 5px;
}

.form-container input[type="text"],
.form-container input[type="password"],
.form-container textarea,
.stripe-field {
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(46, 60, 73, 0.05);
}

.form-container input.input-narrow {
  width: 78%;
  float: left;
}

.form-select {
  text-align: left;
}

.form-select-label {
  display: inline-block;
  font-size: 12px;
  width: 100px;
  margin-left: 2px;
}

.form-container table {
  font-size: 14px;
  text-align: left;
  margin: auto;
}

.form-container button:not(.react-datepicker__navigation):not(.ui-button) {
  width: 200px;
  padding: 10px 0px;
  font-size: 12px;
  margin-top: 25px;
}

.form-container button.table-link:not(.ui-button) {
  margin: 0px !important;
  width: auto;
  padding: 8px 15px;
  font-size: 10px;
  font-weight: 300;
}

.form-container button.notes-button:not(.ui-button) {
  width: auto;
  background-color: #fff;
  border: 1px solid #333;
  color: #333;
  padding: 2px 5px;
  margin-top: 0px;
  margin-right: 5px;
  font-size: 10px;
}

.form-container button.verify-coupon:not(.ui-button) {
  float: right;
  margin: 5px 0px 0px 0px !important;
  width: auto;
  padding: 5px 8px;
  font-size: 10px;
  border: 1px solid hsl(208, 100%, 53%);
  background-color: transparent;
  color: #333;
}

.form-container .tr-strikethrough {
  text-decoration: line-through;
}

.form-error {
  color: hsl(0, 72%, 49%);
  font-size: 11px;
  text-align: left;
  margin: 10px 0px;
}

.form-coupon-link {
  float: left;
  text-decoration: underline;
  margin-top: 10px;
  font-size: 11px;
  cursor: pointer;
  margin-left: 2px;
  display: inline-block;
}

.form-coupon-link:hover {
  text-decoration: none;
}

.form-tos {
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}

.form-tos input[type="checkbox"] {
  margin-right: 5px;
  vertical-align: middle;
}

.react-datepicker-wrapper {
  float: none !important; /* to override DatePicker styles */
}

@media only screen and (min-width: 720px) {
  .card-v2 {
    padding: 30px;
  }
}

@media only screen and (min-width: 560px) {
  .header-tag-v2 {
    padding: 0 16px;
  }
  .header-tag-v2 > span {
    display: initial;
  }

  .header-tag-v2.with-right-icon {
    padding: 0 8px 0 16px;
  }
}
