#jide-nb .jide-nav {
  display: inline-block;
  height: 100%;
  position: relative;
}
.jide-nav {
  cursor: pointer;
  z-index: 100;
}

.jide-nav-header {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #292563;
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  align-items: stretch;
}

.jide-nav-left {
  height: 100%;
  flex-direction: column;
  padding: 12px 0;
  padding-right: 20px;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
}
.jide-nav-left-upper {
  align-self: flex-start;
  align-items: center;
  color: #c1bce0;
  letter-spacing: 0.8px;
  font-family: "Graphik", "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 10px;
}
#jide-nb div.jide-nav-left-lower {
  display: block;
  color: #fff;
  font-family: "Graphik", "Open Sans", sans-serif;
  font-weight: normal;
  letter-spacing: 0.8px;
  font-size: 15px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.jide-nav-right {
  flex-direction: column;
  justify-content: center;
}
.jide-nav-right button {
  padding: 0px;
  background: #413e75;
  text-align: center;
  border-radius: 4px;
}

.jide-nav-right button .icon {
  height: 8px;
  width: 8px;
  padding: 12px;
}

.jide-nav-right button .icon path {
  fill: #292563;
}

#jide-nb .jide-nav-dd {
  display: none;
  box-shadow: 0px 0px 8px "#A199D0"; /* jPurple 400 */
}
#jide-nb .jide-nav-dd.jide-nav-expanded {
  display: inline-block;
  position: absolute;
  background: #fff;
  width: 100%;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.jide-nav-dd-option {
  flex-direction: row;
  align-items: center;
  border-left: 1px solid #cac9d8; /* jDark 200 */
  border-right: 1px solid #cac9d8; /* jDark 200 */
  border-bottom: 1px solid #e2e1ed; /* jDark 100 */
  width: 100%;
  padding: 15px;
  background: #fff;
  font-family: "Graphik", "Open Sans", sans-serif;
  letter-spacing: 0.6px;
  font-size: 13px;
  font-weight: normal;
}
.jide-nav-dd-option:last-child {
  border-bottom: none;
}
.jide-nav-dd-option.subheader {
  background: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-family: "Graphik", "Open Sans", sans-serif;
  cursor: default;
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover {
  background: #f0f5fa; /* jGray 200 */
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon {
  background: #a199d0; /* jPurple 400 */
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon
  path,
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon
  rect {
  fill: #fff;
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon.math-icon
  path {
  stroke: #fff;
}
.jide-nav-dd-option:not(.disabled):not(.subheader):not(.selected):hover
  .jide-nav-dd-option-left
  .icon
  polyline {
  stroke: #fff;
}
.jide-nav-dd-option:first-child {
  border-top: 1px solid #cac9d8;
}
.jide-nav-dd-option:last-child {
  border-bottom: 1px solid #cac9d8;
}
.jide-nav-dd-option.disabled {
  cursor: not-allowed;
}
.jide-nav-dd-option.selected {
  background: #6255b0; /* jPurple 600 */
  color: #fff;
  font-weight: 600;
  cursor: default;
}
.jide-nav-dd-option.selected .icon {
  background: #463897; /* jPurple 800 */
}
.jide-nav-dd-option.selected .icon path,
.jide-nav-dd-option.selected .icon rect {
  fill: #fff;
}
.jide-nav-dd-option.selected .icon.math-icon path {
  stroke: #fff;
}
.jide-nav-dd-option.selected .icon polyline {
  stroke: #fff;
}
.jide-nav-dd-option-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
}
.jide-nav-dd-option-left .icon {
  background: #efeef7;
  padding: 10px;
}
.jide-nav-dd-option-left .icon path,
.jide-nav-dd-option-left .icon rect {
  fill: #6255b0; /* jPurple 600 */
}
.jide-nav-dd-option-left .icon.math-icon path {
  stroke: #6255b0; /* jPurple 600 */
}
.jide-nav-dd-option-left .icon polyline {
  stroke: "#6255B0"; /* jPurple 600 */
}
.jide-nav-dd-option-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  margin-left: auto;
}
.jide-nav-dd-option .jide-nav-dd-option-right .icon {
  background: none;
  height: 16px;
  width: 16px;
  padding: 0;
  margin-left: 0.75rem;
}
.jide-nav-dd-option .jide-nav-dd-option-right .icon polyline {
  stroke: #e2e1ed;
}
.jide-nav-dd-option .jide-nav-dd-option-right .icon path {
  fill: #e2e1ed;
}
.jide-nav-dd-option.selected .jide-nav-dd-option-right .icon polyline,
.jide-nav-dd-option:hover .jide-nav-dd-option-right .icon polyline {
  stroke: #c1bce0;
}
.jide-nav-dd-option.selected .jide-nav-dd-option-right .icon path,
.jide-nav-dd-option:hover .jide-nav-dd-option-right .icon path {
  fill: #c1bce0;
}
.jide-nav-dd-option .jide-nav-dd-option-right a .icon {
  transition: transform 0.1s linear;
}
.jide-nav-dd-option .jide-nav-dd-option-right a:hover .icon {
  transform: scale(1.08);
}
.jide-nav-dd-option-upper {
  align-self: flex-start;
}
.jide-nav-dd-option-lower {
  align-self: flex-start;
  font-family: "Graphik", "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}
.jide-nav-dd-option-middle {
  display: flex;
  align-items: center;
  flex: 1;
}
.jide-nav-dd-option-middle span {
  margin-right: 0.75rem;
}
.jide-nav-dd-option-middle .icon {
  margin-left: auto;
}
#jide-nav-dd-search {
  flex-direction: row;
  height: 50px;
  padding: 5px 10px 5px 10px;
  background-color: white;
  align-items: center;
  color: rgba(15, 58, 82, 0.5);
}
#jide-nav-dd-search-input {
  padding-left: 10px;
  border: 0px;
  color: #0f3a52;
  background-color: white;
  width: 100%;
}
#jide-nav-dd-search-input[type="text"] {
  font-family: "Graphik", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
