.learnerlogin input,
.learnerlogin textarea {
  z-index: 123;
  border: 1px solid #dee5e9;
  background: white;
  font-family: "Graphik", "Nunito Sans", "Open Sans", sans-serif;
  font-size: 12px;
  padding: 10px;
  height: initial;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #e8e8f1;
  background: white;
  z-index: 1;
  padding: 1rem;
  color: #320e78;
}

.learnerlogin input::placeholder,
.learnerlogin textarea::placeholder {
  color: #648194;
  font-size: 14px;
  opacity: 0.5;
  font-style: italic;
}

.learnerlogin form.inline input {
  background: rgba(255, 255, 255, 0.0392157);
  border: 2px solid #4a149c;
  border-right: 0;
  padding: 0 1rem;
}

.learnerlogin form.inline input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
}

.learnerlogin #auth #auth-content-wrap #auth-content form > .form-error-wrap {
  margin-bottom: -1.5rem;
}

.learnerlogin #auth #auth-content-wrap #auth-content form .form-field {
  position: relative;
  width: 100%;
  height: 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  border-radius: 3px;
  overflow: hidden;
}

.learnerlogin
  #auth
  #auth-content-wrap
  #auth-content
  form
  .form-field.error
  input {
  background: #ffe4e4;
  border: 2px solid #ff3333;
  font-weight: 800;
  color: #e01a1a;
}

.learnerlogin #auth #auth-content-wrap #auth-content form .form-field input {
  border: 0;
  width: 100%;
  background: #f0f7fb;
}
